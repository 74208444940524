import { http, tm } from './httpng';
import { md5 } from './md5';
import { ilock } from './lock';
import { BehaviorSubject, Subject } from 'rxjs';


const urlcache: {[key: string]: any} = {};

export interface NameValue {
    [key: string]: string | number | null | string[] | Date;
}

export interface IData {
    [key: string]: string | number | boolean | number[] | null | IData;
}

export interface IFilters {
    [key: string]: string | number;
}

export interface IUser {
    name: string;
}

export interface ICartItem {
    item_name: string;
    item_desc: string;
    product_id: string;
    policy_id: string;
}
export interface ICart {
    cart_id: string;
    sub_id: string;
    status: number;
    items: ICartItem[];
    saved: ICartItem[];
}

export interface IProduct {
    product_id: string;
    product_name: string;
    display_name: string;
    product_code: string;
    product_group_id: string;
    premium_value: string;
    nstp_enabled: string;
    nstp_flag: string;
    nstp_value: string;
    master_policy_product_id: string;
    wf_id?: string;                     // product workflow id
    product_type?: string;              // policy workflow id
    catalog: {[key: string]: {data_id: string|number, file_name: string, field_name: string, module_id: string, version: number}};
    data: {[key: string]: any};
    dictionary: {desc: string, inputs: {[key: string]: any}, outputs: {[key: string]: any}};
    premium_calc_validations: any;
    premium_calc_output_validations: any;
    proposal_form_validations: any;
    proposal_form_output_validations: any;
    installments?: {
        enable_installments: number;
        max_emi_count: number;
        minimum_premium_value: number;
        emi_setup_fee: number;
    };
    [key: string]: any;
}

export interface IEndProduct {
    prd_endorsement_id: string;
    product_id: string;
    product_group_id: string;
    endorsement_name: string;
    endorsement_type: string;
    endorsement_limit: number;
    start_min_days: number;
    description: string;
    premium_cell: string;
    insp_flag: string;
    nstp_flag: string;
    premium_type: string;
    fee_type: string;
    fee_value: string;
    field_list: string;
    end_field_list: {allowed: any, rex: any};
    wf_id: number;
    status: number;
    premium_calc_validations: any;
    premium_calc_output_validations: any;
    proposal_form_validations: any; // dummy
    proposal_form_output_validations: any;// dummy
    data: {[key: string]: any};
    charges: any[];
}

export interface IProfile {
    email: string;                      // user-id/email
    mobile_no: string;                  // mobile number (optional)
    is_admin: number;                   // 1 if adminstrator
    is_underwriter: number | boolean;             // 1 if underwriter
    first_name: string;
    last_name: string;
    name: string;                       // display name
    broker_code: string;                // broker code, only if part of broker entity
    agent_code: string;                 // agent code, only if part of broker entity
    user_code: string;                  //
    broker_id: string;                  // broker entity id, only if part of broker entity
    user_type: string;
    last_login: string;
    deposit_balance: number;            // deposit balance at the time of login
    privileges: string[];               // allowed list of privileges
    products: IProduct[];               // allowed list of products
    excluded: string[];                 // excluded list of product ids
    group_map: {[key: string]: string}; // group name to id map
    deposits: any[];
    [key: string]: any;                 // other custom properties
}
export interface IWorkflowFormField {
    field_name: string;
    type: string;
    label?: string;
    tip?: string;
    validate?: string;
    source?: {url: string, name?: string, value?: string};
    options?: string[];
}
export interface IWorkflowFormGroup {
    name: string;
    desc?: string;
    fields: IWorkflowFormField[];
}
export interface IWorkflowStage {
    name: string;
    desc?: string;
    icon?: string;
    module: {name: string, url?: string, id?: string};
    form: {groups: IWorkflowFormGroup[]}
}
export interface IWorkflow {
    wf_id: string;
    name: string;
    type: string;
    stages: IWorkflowStage[][];
    wf_script: any;
    module?: any;
    triggers?: any;
    layout?: {[key: string]: any};
}

export enum InsStatus {
    ERR_OK = 0,
    ERR_AUTH_FAILED = -101,
    ERR_PARAM = -102,
    ERR_INTERNAL = -103,
    ERR_PRIVILEGE = -104,
    ERR_AUTH_NEEDED = -106,
    ERR_PAYMENT = -109,
    ERR_NSTP = -111,
    ERR_XNGIN = -112,
    ERR_WORKFLOW = -113,
    ERR_AUTH_EXPIRED = -114,
    ERR_OTP_FAILED = -115,
    ERR_CUST_EXISTS = -116,
    ERR_NO_DEPOSIT = -117,
    ERR_OTP_EXPIRED = -119,
    ERR_AUTH_DISABLED = -120,
    ERR_2FA_FAILED = -121,
    ERR_PWD_EXPIRED = -122,
    ERR_TWOFA_NEEDED = -123
};

class InsAPI {
    profile: IProfile | null = null;
    profileSubject = new BehaviorSubject<IProfile|null>(null);
    cartSubject = new BehaviorSubject<ICart|null>(null);
    server = ''; //'http://127.0.0.1:8080';
    timeout = 0;
    guestMode = false;
    b2cMode = false;
    isCustomer = false;
    customerId: string | null = null;
    authFunc: Function | null = null;
    changeFunc: Function | null = null;
    messageFunc: Function | null = null;
    spinnerFunc: Function | null = null;

    users: {[key: string]: IUser} = {};
    _name_priv: boolean = true;

    _privileges: any[] = [];
    _forms: {[key: string]: any} = {};
    _wf: {[key: string]: IWorkflow} = {};
    _end_prds: {[prdid: string]: {[eprdid: string]: IEndProduct}} = {};

    carts: ICart[] = [];
    cartItemCount: number = 0;
    cartSavedCount: number = 0;

    constructor(){
    }

    /**
     * Show message display UI (if call-back not configured, prints to the console). Set messageFunc
     * to a function with signature (message: string, type: number) before calling this.
     * @param message Text message to show
     * @param type 1 - Error, 0 - Information
     */
    async showMessage(message: string, type: number) {
        if (this.messageFunc) {
            return await this.messageFunc(message, type);
        } else {
            console.log(type, message);
        }
    }

    /**
     * Show busy spinner. Set spinnerFunc to a function with signature (show: boolean) before calling this.
     * @param show true - show spiiner, false - hide spinner
     */
    showSpinner = (show: boolean) => this.spinnerFunc ? this.spinnerFunc(show) : false;

    getToken(): string {
        return tm.token;
    }

    a2hex(arr: Uint8Array) {
        var s = '', h = '0123456789ABCDEF';
        for(var x=0; x<arr.length; x++)s += h[ arr[x] >> 4] + h[arr[x] & 15];
        return s;
    }

    async pbkdf2(password: string, salt: string, iterations: number, keylen: number) {
        const textEncoder = new TextEncoder();
        const importedKey = await crypto.subtle.importKey("raw", textEncoder.encode(password), "PBKDF2", false, ["deriveBits"]);
        const params = {name: "PBKDF2", hash: 'sha-512', salt: textEncoder.encode(salt), iterations: iterations};
        const key = await crypto.subtle.deriveBits(params, importedKey, keylen*8);
        return this.a2hex(new Uint8Array(key));
    }

    async __clear_and_load() {
        if (ilock.is_locked('profile')) return true; // already in profile load mode
        this.profile = null;
        this.carts = [];
        await this.loadUser();
        return true;
    }

    async names(emails: string[]) {
        let parts = emails.map(x => x.trim()).filter(Boolean);
        let nmap: {[key: string]: string} = {};
        let na = [];
        for (let part of parts) {
            if (this.users[part]) nmap[part] = this.users[part].name;
            else na.push(part);
        }

        try {
            if (na.length > 0 && this._name_priv) {
                let ret = await this.xget('/api/v1/profile/name/' + encodeURIComponent(na.join(',')));
                for (let key in ret) {
                    this.users[key] = ret[key];
                    nmap[key] = this.users[ret].name;
                }
            }
        } catch (e) {
            this._name_priv = false;
        }
        console.log(this.users)
        return nmap;
    }

    async user(email: string) {
        if (this.users[email]) return this.users[email];
        if (!this._name_priv) return {name: email};
        try {
            let ret = await this.xget('/api/v1/profile/name/' + encodeURIComponent(email));
            for (let key in ret) {
                this.users[ret] = ret[key];
            }
        } catch (e) {
            this._name_priv = false;
        }
        return null;
    }

    /**
     * Method to send otp on login page
     * @since 26-08-2022
     * @param email  
     */
    async login_otp(email?: string, mobile?: string){
        if (!email && !mobile) return;
        const ret = await http.xreq(this.server + '/api/v1/auth/otp', 'post', { email : email });
        return ret;
    }

    /**
     * Method to verify otp
     * @since 26-08-2022
     * @param email  
     */
    async verify_otp(otp:any,email:any): Promise<void>{
        const result = await http.xreq(this.server + '/api/v1/auth', 'post', { otp:otp ,email : email });
        if (result.status == 0) {
            tm.clear();
            tm.setToken(result.data.token, "");
            await this.__clear_and_load();
        }
        else {
            let error = result?.data?.txt || result?.sub || null;
            if (error) throw new Error(error);
        }
    }

    /**
     * Authenticate user with Insillion
     * @param email user id
     * @param pwd [] password (plain text)
     * @param otp [] OTP
     * @param alias [] alias to be used instead of email
     */
    async auth(email: string, pwd?: string, otp?: string, alias?: string, totp?: string): Promise<void> {
		
        if (!email && !alias) throw new Error('Either email or alias must be provided');
        if (!pwd && !otp) throw new Error('Either password or OTP must be provided');
        let error = null;
        try{
            this.profile = null;
            this.carts = [];
            this.profileSubject.next(null);

            let passkey = pwd ? md5(pwd) : '';
	         const pret = await http.xreq(this.server + '/api/v1/auth/pepper?x=1&email='+encodeURIComponent(email), 'get');
            if (pwd && pret.status==0 && +pret.data.pepper != 0 && pret.data.keylength > 0) {
                passkey = await this.pbkdf2(pwd, pret.data.salt_public, +pret.data.pepper, +pret.data.keylength);
            }
	        const ret = await http.xreq(this.server + '/api/v1/auth', 'post', { email, mpwd: passkey, otp: otp || '', alias: alias || '', totp: totp || '' }, undefined, {"Content-Type": "application/json"});
            if (+ret.status === 0) {
                tm.clear();
	            tm.setToken(ret.data.token, "");
                await this.__clear_and_load();  // we may have to remove this
            } else if (+ret.status == -122) {
                throw new Error('Auth expired: ' + ret.data.key);
            } else if (+ret.status == -124) {
                throw new Error('T&C Needed: ' + ret.data.key);
            } else if ((ret.txt||'').indexOf('xpired') > 0) {
                error = 'OTP Expired';
            } else {
                error = ret.status+' Auth failed';
            }
        } catch (e: any) {
            error = e.message || e;
        }
        if (error) throw new Error(error);
    }

    async tandc(key: string) {
        let pret = await http.xreq(this.server + '/api/v1/auth/url?key='+encodeURIComponent(key)+'&idx=0', 'get');
        if (pret.status == -104) return await this.showMessage('Missing t&c privilege', 0);
        return pret?.data ? Object.values(pret.data) : [];
    }

    async acceptTandC(key: string, tandcId: string, otp?: string) {
        return await http.xreq(this.server + '/api/v1/auth/url', 'post', {key, idx: 0, tandc_id: tandcId, otp: otp});
    }

    async tandcOtp(key: string, tandcId: string) {
        return await http.xreq(this.server + '/api/v1/auth/url', 'post', {key, idx: 1, tandc_id: tandcId});
    }

    /**
     * Authenticate user with Insillion
     * @param email user id
     * @param pwd [] password (plain text)
     * @param otp [] OTP
     * @param alias [] alias to be used instead of email
     */
    async ad_auth(email: string, pwd?: string, success_callback?: string): Promise<void> {
        if (!email) throw new Error('Either email must be provided');
        if (!pwd) throw new Error('Either password must be provided');
        let error = null;
        try{
            this.profile = null;
            this.carts = [];
            this.profileSubject.next(null);

            let passkey = pwd;
            const pret = await http.xreq(this.server + '/api/v1/auth/pepper?email='+encodeURIComponent(email), 'get');
            if (pwd && pret.status==0 && +pret.data.pepper != 0 && pret.data.keylength > 0) {
                passkey = await this.pbkdf2(pwd, pret.data.salt_public, +pret.data.pepper, +pret.data.keylength);
            }

            const ret = await http.xreq(this.server + '/auth/ad', 'post', { email:email, pwd: passkey, _src: success_callback});
            
            tm.__read_tokens();
            if (tm._cur_token) {
                tm.updateToken(decodeURIComponent(tm._cur_token));
            }
            else {
                error = ret.status+' Auth failed';
            }
        } catch (e: any) {
            error = e.message || e;
            console.log('insapi',e);
        }
        if (error) throw new Error(error);
    }

    /**
     * Register a new B2C customer
     * @param email customer's email ID (optional if mobileNo is provided)
     * @param mobileNo customer's mobile no (optional if email is provided)
     * @returns {true|false|Error} - true when the user has been registered and allowed to continue
     *          - false when a OTP has been sent to the customer (mobile or email)
     *          - Throws error on all other errors
     */
     async register(email?: string, mobileNo?: string, verified: boolean=false): Promise<boolean> {
        let data: any = {email, mobile_no: mobileNo};
        if (verified) data['verified'] = 1;
        let ret = await http.xreq(this.server + '/api/v1/customer/register', 'post', data);
        if (ret.status == InsStatus.ERR_CUST_EXISTS) {
            this.isCustomer = true;
            ret = await http.xreq(this.server + '/api/v1/customer/otp', 'post', {to: email || mobileNo});
            if (ret.status == 0) return false; // otp has been sent
        } else if (ret.status == 0) {
            this.isCustomer = true;
            this.customerId = ret.customer_id;  // as part of root itself (data would only have a string)
            if (!ret.data.token) return false; // otp has been sent
            tm.setToken(ret.data.token, "2");
            return await this.__clear_and_load();
        }
        throw new Error(ret.status+' Auth failed '+(ret.txt||''));
    }

    /**
     * Customer (B2C) login through OTP
     * @param otp OTP sent to the customer id (as returned by the register API call)
     */
     async verifyOTP(otp: string, email?: string, mobileNo?: string): Promise<boolean> {
        if (!email && !mobileNo) return false;
        try{
            let ret = await http.xreq(this.server + '/api/v1/customer/verify', 'post', {to: email || mobileNo || '', otp: otp});
            if (ret.status == 0) {
                tm.setToken(ret.data.token, "2");
                await this.__clear_and_load()
                return true;
            }
            this.showMessage("Invalid OTP", 0);
            return false;
        } catch (e) {
            this.showMessage("Invalid OTP", 0);
            console.log('verify OTP failed', e);
            return false;
        }
    }

    get b2c_mode() {
        return tm._b2c_mode;
    }

    guest_mode(on: boolean = false, b2c: boolean = false) {
		insapi.guestMode = on;
        insapi.b2cMode = b2c;
        // if currently stored token is not b2c token, clear it
        if (b2c) {
            if (tm.token && tm.b2c_mode == '') tm.clear();
		    if (!tm.token) tm.setToken('', '1');    // switch to guest mode if not in customer mode
            insapi.authFunc = async () => {await insapi._guest_auth()};
        } else {
			if (tm.token && tm.b2c_mode != '') tm.clear();
            if (!tm.token) tm.setToken('', '');
        }
    }

    async _guest_auth() {
        try {
            let url = this.server + '/api/v1/customer/guest?key=' + encodeURIComponent(tm.guest_key||'');
            let ret = await this.xget(url);
            if (!ret.token) return false; // could not login as guest
            tm.guest_key = ret.key||'';
            tm.setToken(ret.token, "1");
            await this.__clear_and_load();
            return true;
        } catch(e) {
            console.log('guest:', e);
            return false;
        }
    }

    async xreq(url: string, method?: string, data?: IData | FormData, params?: IData, full: number=0): Promise<any> {
        // if we still don;t have the token, its ok as some APIs may not need the token
        // at all
        //
			
        try {
            const resp = await http.xreq(this.server + url, method, data, params);
		    if (+resp.status === 0) {
                if (full) return resp;
                return resp.data ?? resp;
            } else if (+resp.status === InsStatus.ERR_AUTH_EXPIRED || +resp.status === InsStatus.ERR_AUTH_NEEDED) {
                tm.clear();
				
                if (+resp.status === InsStatus.ERR_AUTH_EXPIRED && tm.b2c_mode == "1") {
                    return null;
                }

                let prxmode = tm.prxmode;
                
                if (this.authFunc) {
                    await ilock.lock('auth');
                    try {
                        if (!tm.token) await this.authFunc();
                    } catch (e) {
                        console.log('authFunc:', e);
                    } finally {
                        ilock.unlock('auth')
                    }
                }
                else {
                    console.log('auth expired: no auth function set');
                }

                // retry with the new token (only if its not in proxy mode)
                //
                if (!prxmode && tm.token) return await this.xreq(url, method, data, params);
                // if (http.token) return await this.xreq(url, method, data, params);
                return null;
            } else {
                if (full==2) return resp;
                console.log('api failed: ', url, resp);
                throw new Error(resp.txt);
            }

        } catch(e: any) {
            throw new Error(e.message);
        } finally {
        }

    }

    /**
     * Generic Insillion API call with GET method and current user's token. Expects the result to be
     * JSON object with status and data | txt; Throws exception in case errors.
     * @param url valid relative URL from root
     */
    async xget(url: string, full: number=0) {
        return await this.xreq(url, 'GET', undefined, undefined, full);
    }

    /**
     * Generic Insillion API call with POST method and current user's token. Expects the result to be
     * JSON object with status and data | txt; Throws exception in case errors.
     * @param url valid relative URL from root
     * @param data name/value pair of data to be passed to the POST method
     */
    async xpost(url: string, data: IData | FormData) {
        return await this.xreq(url, 'POST', data);
    }

    /**
     * Generic Insillion API call with DELETE method and current user's token. Expects the result to be
     * JSON object with status and data | txt; Throws exception in case errors.
     * @param url valid relative URL from root
     */
    async xdel(url: string) {
        return await this.xreq(url, 'DELETE');
    }

    /**
     * Get product details given its name
     * @param productName name of product
     * @returns {IProduct} if user is allowed access to the product
     * @returns {null} when not allowed or when product does not exist
     */
    async product(productName: string): Promise<IProduct | null> {
        await this.loadUser();
        if (!this.profile) return null;

        productName = (productName||'').toLowerCase().trim();
        for (const prod of this.profile.products) {
            if (prod.product_name.toLowerCase() === productName) {
                return prod as IProduct;
            }
        }
        return null;
    }

    async _load_dictionary(product: IProduct) {
        if (product.dictionary) return;
        try {
            let dictionary = await this.xget('/api/v1/product/dictionary/'+product.product_id);
            product.dictionary = {desc: '', inputs: {}, outputs: {}, ...(dictionary?dictionary:{})};
        } catch (e: any) {
            console.log('_load_dictionary: ', e.message || e);
        }
    }

    async _load_master_policies(product: IProduct, force: boolean = false) {
        if (!product.master_policy_product_id) return;
        if (product.master_policies && !force) return; // already loaded
        try {
            let prod = await this.xget('/api/v1/product/' + product.product_id);
            if (prod && prod.length > 0) product.master_policies = prod[0].master_policies;
        } catch (e) {
            console.log('_load_master_policies', product.product_id, e);
        }
        return;
    }

    /**
     * Get product details given its ID or name
     * @param productId ID of product
     * @returns {IProduct} if user is allowed access to the product
     * @returns {null} when not allowed or when product does not exist
     */
    async productFromId(productId: string): Promise<IProduct | null> {
        await this.loadUser();
        if (!this.profile) return null;
        for (const prod of this.profile.products) {
            if (prod.product_id === productId || prod.product_name == productId.toLowerCase()) {
                await this._load_dictionary(prod);
                await this._load_master_policies(prod);
                return prod as IProduct;
            }
        }
        return null;
    }

    async endProducts(productId: string): Promise<any[]> {
        if (!productId) return [];
        if (!this._end_prds[productId]) {
            this._end_prds[productId] = {};
            let endPrds = await this.xget('/api/v1/product/endorsement/' + productId+'?status=0') || [];
            for (let ep of endPrds) this._end_prds[productId][ep.prd_endorsement_id] = ep;
        }
        return Object.values(this._end_prds[productId]);
    }

    async endProductFromId(endProdId: string, productId: string): Promise<IEndProduct | null> {
        if (!endProdId || !productId) return null;
        if (!this._end_prds[productId]) await this.endProducts(productId);
        return this._end_prds[productId]?.[endProdId] || null;

        // if (!this._end_prds[productId]) {
        //     this._end_prds[productId] = {};
        //     let endPrds = await this.xget('/api/v1/product/endorsement/' + productId) || [];
        //     for (let ep of endPrds) this._end_prds[productId][ep.prd_endorsement_id] = ep;
        // }
        // if (this._end_prds[productId][endProdId]) return this._end_prds[productId][endProdId];
        // return null;
    }

    async acquireEndorsement(endorsementId: string) {
        return await this.__api_wrapper( async () => await insapi.xpost('/api/v2/endorsement/claim', {endorsement_id: endorsementId}));
    }

    async switchUser(email: string | null) {
        if (!this.profile) return;
        let proxyOf = this.profile.proxyOf || this.profile.email;

        if (email) {
            if (!await tm.switch_user(email, proxyOf)) {
                this.showMessage("failed to switch to user " + email, 0);
                return;
            }
        } else {
            tm.switch_back();
        }
        await this.__clear_and_load();
        if (this.profile) {
            if (this.profile.email != proxyOf)
                this.profile.proxyOf = proxyOf;
            else
                this.profile.proxyOf = '';
        }
    }

    async proxyUserList() {
        if (!this.profile) return;
        let ret = await http.xreq('/api/v1/group/proxy/users', 'get', null, null, null, this.profile.proxyOf ? true : false);
        if (ret.status == 0) return ret.data;
        return [];
    }

    /**
     * Remove the current token from local storage or cookie
     */
    logout() {
        http.xreq('/api/v1/auth/logout', 'get');
        tm.clear();
        for (let key in urlcache) delete urlcache[key];
        this.profile = null;
        this.carts = [];
        this.profileSubject.next(null);
    }

    async __load_customer() {
        if (this.guestMode) return; // guest mode is allowed, no customer login is needed
        if (this.profile && this.profile.name != 'Guest') {
            console.log('load cust: already a cust', this.profile.name);
            return; // user is not guest
        }
        tm.setToken("", "2");
        await this.__clear_and_load();
    }

    async __update_profile() {
		console.log("__update_profile")
        // let privNeeded = ['Impersonate', 'Reload Cache', 'Upgrade Proposal', 'Revise Quotation', 'Remove Quotes', 
        //     'Nstp Approve', 'Qnstp Approve', 'Pnstp Approve', 
        //     'Nstp Reject', 'Qnstp Reject', 'Pnstp Reject',
        //     'Nstp Accept', 'Qnstp Accept', 'Pnstp Accept',
        //     'Nstp Review', 'Qnstp Review', 'Pnstp Review'];
        if (this.b2cMode) this.profile = await this.xget('/api/v1/customer/profile?pages=1&priv=1&x=1');
        else this.profile = await this.xget('/api/v1/profile?pages=1&priv=1&menu=1&x=1');
        
        if (this.profile) {
            if (this.profile.privileges.indexOf('Impersonate') >= 0) this.profile.can_proxy = true;
            if (tm._prx_email) this.profile.proxyOf = tm._prx_email;
            for (let pid in this.profile.pages || {}) {
                if (!this.profile.pages[pid]) continue;
                for (let prod of this.profile.products) {
                    if (pid == prod.product_id) {
                        prod.pages = this.profile.pages[pid].pages;
                        prod.premium_calc_validations = this.profile.pages[pid].premium_calc_validations;
                        prod.proposal_form_validations = this.profile.pages[pid].proposal_form_validations;
                        prod.premium_calc_output_validations = this.profile.pages[pid].premium_calc_output_validations;
                        prod.proposal_form_output_validations = this.profile.pages[pid].proposal_form_output_validations;
                        if (!prod.data.installment) prod.data.installment = {enable_installments: 0};
                        break;
                    }
                }
            }
        }
        if (this.changeFunc) this.changeFunc('profile', this.profile);
        this.reload_carts();
        this.profileSubject.next(this.profile);
        return this.profile;
    }

    /**
     * Fetch current user's profile information, authentication must have been
     * completed and should not have expired. Returns from cache if already exists
    * @returns {IProfile} returns user profile object or null
     */
    async loadUser(reload: boolean = false): Promise<IProfile | null> {
        if (this.profile) return this.profile;
        await ilock.lock('profile');

        try {
            // console.log('loadUser check profile:')
            if (this.profile) return this.profile; // in case its made availabe during lock
            return await this.__update_profile();
            // if (this.b2cMode) {
            //     this.profile = await this.xget('/api/v1/customer/profile');
            // }
            // else
            //     this.profile = await this.xget('/api/v1/profile');
            
            // if (this.profile) {
            //     console.log('excluded:', this.profile.excluded);
            //     if (this.profile.privileges.indexOf('Impersonate') >= 0) this.profile.can_proxy = true;
            //     if (tm._prx_email) this.profile.proxyOf = tm._prx_email;
            // }
            // if (this.changeFunc) this.changeFunc('profile', this.profile);
            // this.reload_carts();
            // this.profileSubject.next(this.profile);
            // return this.profile;
        } catch (e: any) {
            console.log('loaduser ex:', e)
            this.showMessage(e.message || e, 1);
            return null;
        } finally {
            ilock.unlock('profile');
        }
    }

    /**
     * Currently logged in user's fullname
     */
    name = () => this.profile ? this.profile.name : 'Not logged in';

    async __api_wrapper(cb: () => any){
        insapi.showSpinner(true);
        try{
            return await cb();
        } catch (e: any) {
            insapi.showMessage(e.message || e, 1);
            return false;
        } finally {
            insapi.showSpinner(false);
        }
    }

    async __xget(url: string, full: number=0) {
        return await this.__api_wrapper( async () => await insapi.xget(url, full));
    }

    async __xpost(url: any, data: any) {
        return await this.__api_wrapper( async () => await insapi.xpost(url, data));
    }
    
    async __xdel(url: any) {
        return await this.__api_wrapper( async () => await insapi.xdel(url));
    }

    async _xget_cache(url: string) {
        if (!urlcache[url]) urlcache[url] = await insapi.xget(url);
        return urlcache[url];
    }

    /**
     * List of recent quotations. Use filters to get sub set
     * Ex: status=0 - Quotations in-progress
     *     status=2 - All quotations converted to policies
     *     status=0,2 - All quotations and policies
     * @param prodName [''] Product name filter
     * @param fields [quote_id,quote_no,first_name,email,u_ts] Comma separated field names (JSON paths)
     * @param limit [32] Resulst set size 
     * @param filters [{}] Name value pair object
     * @param group [false] Restrict the list to quotes currently assigned to (current user's) group
     * @param fmt [json] Output format('json', 'CSV')
     */
    async quotes(prodName?: string, fields?: string, limit?: number, filters?: IFilters, group?: boolean, fmt?: string, order?: string) {
        fields = fields || 'quote_id,quote_no,first_name,email,u_ts';
        const params: Record<string, string> = { ...(filters||{}), order: 'u_ts desc', schema: '1', fields};
        if (group) params['filter'] = 'assigned_to_group';
        if (limit && +limit > 0) params['count'] = ''+limit;
        if (prodName)  params.product_name = prodName;
        if (fmt) params.fmt = fmt;
        if (order) params.order = order;
        if (filters?.download == 1) {
            params.token = this.getToken();
            window.location.href = await http.__encrypt_url('/api/v1/policy/list2?' + new URLSearchParams(params).toString(), true);
            return [];
        } else {
            return await this.__api_wrapper( async () => await insapi.xpost('/api/v1/policy/list2', params));
        }
    }

    async list2(mod: string, fields: string, filters?: IFilters, group?: boolean, order?: string, limit?: number) {
        const params: Record<string, string> = {...(filters||{}), order: order || 'u_ts desc', schema: '1', fields}
        if (group) params['filter'] = 'assigned_to_group';
        if (limit && +limit > 0) params['count'] = ''+limit;
        if (filters?.download == 1) params['token'] = this.getToken();
        let url = mod == 'endorsement' ? '/api/v2/endorsement/list2?' : '/api/v1/policy/list2?';
        if (filters?.download == 1) {
            window.location.href = await http.__encrypt_url(url + new URLSearchParams(params).toString(), true);
        } else {
            return await this.__api_wrapper( async () => await insapi.xpost(url, params));
        }
    }

    async list3(mod: string, fields: string, filters?: IFilters, group?: boolean, order?: string, limit?: number) {
        const params: Record<string, string> = {...(filters||{}), order: order || 'u_ts desc', schema: '1', fields}
        if (group) params['filter'] = 'assigned_to_group';
        if (limit && +limit > 0) params['count'] = ''+limit;
        if (filters?.download == 1) params['token'] = this.getToken();

        params.fmt = (filters?.fmt as string) || '';

        let url = mod == 'endorsement' ? '/api/v2/endorsement/list2?' : '/api/v1/policy/list3?';
        if (filters?.download == 1) {
            window.location.href = await http.__encrypt_url(url + new URLSearchParams(params).toString(), true);
        } else {
            return await this.__api_wrapper( async () => await insapi.xpost(url, params));
        }
    }


    /**
     * List all the policy objects pending at NSTP stage
     * @param fields [quote_id,quote_no,first_name,email] Comma separated field names (JSON paths)
     * @param limit [32] Resulst set size 
     * @param filters [{}] Name value pair object
     * @param group [false] Restrict the list to quotes currently assigned to (current user's) group
     * @param fmt [json] Output format('json', 'CSV')
     */
    async nstps(fields?: string, limit?: number, filters?: {[key: string]: string}, group?: boolean, fmt?: string) {
        fields = fields || 'quote_id,quote_no,first_name,email';
        const params: Record<string, string> = { ...(filters||{}), order: 'u_ts desc', schema: '1',
            fields, limit : ''+(limit||100), "in_nstp.nstp_enabled": 'Yes'};
        if (group) params['filter'] = 'assigned_to_group';
        if (fmt) params.fmt = fmt;
        let url = '/api/v1/nstp/list?' + new URLSearchParams(params).toString();
        return await this.__api_wrapper( async () => await insapi.xget(url));
    }

    /**
     * Make copy of an existing quote.
     * @param quoteId Quotation ID
     * @returns New (cloned) policy data
     */
    async cloneQuote(quoteId: string, withProposal: boolean = false, withDocuments: boolean = false, noversion: string, master_policy_no: string = '') {
        let data: any = {quote_id: quoteId, no_version: noversion, master_policy_no: master_policy_no};
        if (withDocuments) data.with_documents = 1;
        if (withProposal) data.with_proposal = 1;
        return await this.__api_wrapper( async () => await insapi.xpost('/api/v1/quote/clone', data));
    }

    /**
     * Acuire ownership of a quote assigned to one of your groups
     * @param quoteId Quotation ID
     */
    async acquireQuote(quoteId: string) {
        return await this.__api_wrapper( async () => await insapi.xpost('/api/v1/quote/claim', {quote_id: quoteId}));
    }

    async markDeleted(quoteId: string) {
        return await this.__api_wrapper( async () => await insapi.xdel('/api/v1/quote/' + encodeURIComponent(quoteId)));
    }

    async assignTo(quoteId: string, entity: string, reason: string, changeOwner: boolean = false) {
        return await this.__api_wrapper( async () => await insapi.xpost('/api/v1/quote/reassign', {quote_id: quoteId, subject: 'Reassigned', message: reason, email: entity, replace: changeOwner}));
    }

    /**
     * Auto-complete options for a given prefix (rater plugin must have been configured)
     * @param table Name of the table-API in rater config
     * @param prefix Prefix to search for
     * @param limit [32] Result set size
     * @returns Array of strings that can complete the given prefix
     */
    async options(table: string, prefix: string, limit?: number): Promise<string[]> {
        limit = limit ? (+limit || 32) : 32;
        try{
            const url = '/api/v1/rater/options/' + encodeURIComponent(table) +
                '?prefix=' + encodeURIComponent(prefix) +
                '&limit=' + limit;
            return await insapi.xget(url);
        } catch (e: any) {
            this.showMessage(e.message, 1);
            return [];
        }
    }

    /**
     * Look-up for value(s) for a given name (rater plugin must have been configured)
     * @param name - The property being lookup (must be configured in rater plugin)
     * @param params - Value(s) for looked up properties
     * @returns - Array of name+value pair of results matching given lookup parameter
     */
    async lookup(name: string, params: any): Promise<string[] | {[key: string]: string}[]> {
        try{
            let url = '/api/v1/rater/lookup/' + encodeURIComponent(name) + '?1=1';
            for(let name in params||{}) url += "&" + name + "=" + encodeURIComponent(params[name]);
            return await insapi.xget(url);
        } catch (e: any) {
            this.showMessage(e.message, 1);
            return [];
        }
    }

    /**
     * Master records
     * @param groupName 
     * @param subGroup 
     * @param subSubGroup 
     */
    async master(groupName?: string, subGroup?: string, subSubGroup?: string) {
        try{
            let url = '/api/v1/master';
            if (groupName) url += '/' + encodeURIComponent(groupName);
            if (subGroup) url += '/' + encodeURIComponent(subGroup);
            if (subSubGroup) url += '/' + encodeURIComponent(subSubGroup);
            return await insapi.xget(url);
        } catch (e: any) {
            this.showMessage(e.message, 1);
            return [];
        }
    }

    /**
     * Master options for group/sub-group/sub-sub-group combinations
     * @param groupName 
     * @param subGroup 
     * @param subSubGroup 
     * @returns Array of strings as distinct group names when group-name and sub-group and sub-sub-group is empty
     *          Array of strings as distinct sub-group names when group-name and sub-group is empty
     *          Array of strings as distinct sub-sub-group names when group-name is empty
     *          otherwise returns array of objects
     */
    async masterOptions(groupName?: string, subGroup?: string, subSubGroup?: string) {
        try{
            let url = '/api/v1/master/group';
            if (groupName) url += '/' + encodeURIComponent(groupName);
            if (subGroup) url += '/' + encodeURIComponent(subGroup);
            if (subSubGroup) url += '/' + encodeURIComponent(subSubGroup);
            return await insapi.xget(url);
        } catch (e: any) {
            this.showMessage(e.message, 1);
            return [];
        }
    }

    /**
     * Returns list of groups this user belongs to (or all if admin)
     * @param filters [group_name=value | %group_name=value%] absolute or prefix based filter
     * @returns Array of strings
     */
    async groups(filters?: any) {
        let url = '/api/v1/group';
        if (filters) {
            let params = [];
            for (let key in filters)
                params.push(encodeURIComponent(key)+"="+encodeURIComponent(filters[key]));
            url += '?' + params.join('&');
        }
        return await insapi.xget(url);
    }

    async privileges() {
        if (!this._privileges || this._privileges.length==0)
            this._privileges = await this.__xget('/api/v1/master/privileges') || [];
        return this._privileges;
    }

    async form(name: string) {
        if (!this._forms[name])
            this._forms[name] = await this.__xget('/api/v1/master/form?name='+encodeURIComponent(name));
        return this._forms[name];
    }

    // group stages with same module name and same set of dependants into
    // one stage
    //
    _group_wf_stages(wfstages: any[]) {
        let stages: {[key: string]: any[]} = {};
        let idx = 0;
        for (let stage of wfstages) {
            stage.idx = idx ++;
            if (!stage.dependants) stage.dependants = [stage.module.name];
            let key: string = stage.dependants.sort().join('~');
            if (!stages[key]) stages[key] = [];
            stages[key].push(stage);
        }
        return Object.values(stages).sort((a: any, b: any) => a.idx - b.idx);
    }

    /**
     * Get workflow
     * @param name workflow name
     * @returns 
     */
    async workflow(name: string): Promise<any> {
        if (!name) return null;
        if (!this._wf[name]) {
            let wf = (await this.__xget('/api/v1/wf/'+encodeURIComponent(name)))[0];
            if (wf) {
                wf.layout = {grids: 8, ...(wf.wf_script.layout||{})};
                wf.stages = this._group_wf_stages(wf.wf_script.stages);
            }
            this._wf[name] = wf;
        }
        return this._wf[name];
    }

    async loadDeposits(reload: boolean = false) {
        if (!this.profile || (!reload && this.profile.deposits)) return;
        let url = '/api/v1/deposit/others/' + (this.profile.broker_id ? '/' + encodeURIComponent(this.profile.broker_id) : '');
        this.profile.deposits = await this.__xget(url);
        this.profile.deposits.unshift({entity_id: 'default', amount: this.profile.deposit_balance});
    }

    /**
     * Transaction ledger
     * @param brokerId (optional) broker id
     * @returns 
     */
    async ledger(brokerId?: string) {
        let url = '/api/v1/deposit/ledger' + (brokerId ? '/' + encodeURIComponent(brokerId) : '') +
            '?order=' + encodeURIComponent('u_ts desc');
        return await this.__xget(url);
    }

    groupName(groupId: string): string {
        
        if (this.profile?.all_groups[groupId]) return this.profile?.all_groups[groupId].group_name;
        
        for (let name in this.profile?.group_map||{})
            if (this.profile?.group_map[name] == groupId) return name;
        return groupId;
    }

    async setPreferences(key: string, value: any) {
        if (!this.profile) return;
        let preferences = this.profile.data?.preferences || {};
        preferences[key] = value;
        await this.xpost('/api/v1/profile', {email: this.profile.email, preferences});
    }

    __update_cart_count() {
        this.cartItemCount = 0;
        this.cartSavedCount = 0;
        this.carts.forEach(x => {this.cartItemCount += x.items.length; this.cartSavedCount += x.saved?.length});
    }

    async reload_carts() {
        try {
            let carts = await this.xget('/api/v1/payment/carts');
            this.carts = [];
            for (let cart of carts) {
                let mcart = this.carts.find((x) => x.cart_id == cart.cart_id && x.sub_id == cart.sub_id);
                if (!mcart) {
                    cart.items = [];
                    cart.saved = [];
                    this.carts.push(cart);
                    await this._cart(cart.sub_id);
                }
            }
        } catch (e: any) {
            if (e.message?.indexOf('not enabled') < 0) console.log(e);
            else console.log('cart not enabled')
        }
        this.__update_cart_count();
        this.cartSubject.next(null);
    }

    async _cart(subId?: string) {
        try {
            let cart = await this.xget('/api/v1/payment/cart?sub_id=' + encodeURIComponent(subId||''));
            let mcart = this.carts.find((x) => x.cart_id == cart.cart_id && x.sub_id == cart.sub_id);
            
            if (!mcart) {
                this.carts.push(cart);
                mcart = cart;
            } else {
                mcart.items = cart.items;
                mcart.saved = cart.saved;
            }

            if (mcart) {
                if (!mcart.items) mcart.items = [];
                if (!mcart.saved) mcart.saved = [];
            }

            this.__update_cart_count();

            // this.cartItemCount = 0;
            // this.cartSavedCount = 0;
            // this.carts.forEach(x => {this.cartItemCount += x.items.length; this.cartSavedCount += x.saved?.length});
            return mcart;
        } catch (e) {
            console.log(e);
        }
        return null;
    }
    
    async _cart_checkout(cartId: string) {
        let ret = await this.__api_wrapper( async () => await this.xpost('/api/v1/payment/cart_pay_cash', {cart_id: cartId}));
        await this.__update_profile();
        return ret;
    }
    // async _orders(orderId?: string, from?: string, till?: string, name?: string, start?: number, count?: number) {
    async _orders(filters?: any, start?: number, count?: number) {
        try{
            let url = '/api/v1/payment/order?start=' + +(start||0);
            if (count && count>0) url += '&count=' + count;
            for (let key in filters||{})
                if (filters[key] || filters[key]===0) url += '&' + key + '=' + encodeURIComponent(filters[key]);
            return await insapi.xget(url);
        } catch (e: any) {
            this.showMessage(e.message, 1);
            return [];
        }
    }

    async _cart_process(orderId: string) {
        return await this.__api_wrapper(async () => await insapi.xpost('/api/v1/payment/cart_process', {order_id: orderId}));
    }
    async _cart_to_saved(policyId: string) {
        return await this.__api_wrapper(async () => await this.xpost('/api/v1/payment/cart_remove', {policy_id: policyId}));
    }
    async _cart_from_saved(policyId: string, subId?: string) {
        return await this.__api_wrapper(async () => await this.xpost('/api/v1/payment/cart_move', {policy_id: policyId, sub_id: subId||''}));
    }

    async _cart_add(paymentId: string, subId: string='') {
        const data = {payment_id: paymentId, sub_id: subId||''};
        let ret = await insapi.xpost('/api/v1/payment/cart', data);
        await this._cart(subId);
        return ret;
    }
}

// singleton global instance
export const insapi = new InsAPI();

