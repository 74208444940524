import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { Component, OnInit, ChangeDetectorRef} from '@angular/core';
import { insapi, IProfile, Policy } from 'insapi';
import { Subscription } from 'rxjs';
import { IField } from '../../form.interface';



let pipes: { [key: string]: any } = {
    date: DatePipe,
    currency: CurrencyPipe,
    number: DecimalPipe
};

@Component({
    selector: 'lib-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {

    field!: IField;
    policy!: Policy;
    profile!: IProfile | null;
    layout: any = {
        "cls": "",
        "header_style": {},
        "style": {},
        "format": "",
        "type": ""
    };
    listDef: any = {}
    data: any = {};

    subscription: Subscription | null = null;
    psubscription: Subscription | undefined = undefined;
    
    constructor(private cdr: ChangeDetectorRef) {
        this.subscription = insapi.profileSubject.subscribe((profile: IProfile|null) => {this.profile = profile;});

        // changes to the policy object cannot be detected by Angular as we are not using it directly in the HTML
        // force change detection everytime policy/endorsement changes
        this.psubscription = this.policy?.changeSubject?.subscribe(() => this.cdr.detectChanges());
    }

    ngOnDestroy(): void {
        if (this.subscription) this.subscription.unsubscribe();
        this.subscription = null;
        if (this.psubscription) this.psubscription.unsubscribe();
        this.psubscription = undefined;
    }

    ngOnInit(): void {
        this.listDef = this.field.props;
        if (this.listDef.headers) {
            for (let item of this.listDef.headers) {
                if (!this.listDef.columnlayout[item])
                    this.listDef.columnlayout[item] = this.layout;

                if (this.listDef.columnlayout[item].format && typeof this.listDef.columnlayout[item].format == 'string') {
                    let format = this.listDef.columnlayout[item].format || '';
                    console.log("format", format)
                    let parts = format.split(',');
                    let pname = parts.shift();
                    this.listDef.columnlayout[item].format = [pipes[pname], parts[0]];
                }
            }

            if (this.listDef.fields) {
                for (let item of this.listDef.fields) {
                    if (!item.layout)
                        item.layout = this.layout;

                    if (item.if && !item.ifFunc)
                        item.ifFunc = this._evalFunc(item.if, null);
                    
                    for (let key in item) {
                        if (this.listDef.columnlayout[key] && this.listDef.columnlayout[key].type != 'string' && item[key] && !item[key+'func']) {
                            //console.log("columnlayout",key,this.listDef.columnlayout[key],item[key]);
                            item[key+'func'] = this._evalFunc(item[key], null);
                        }
                    }
                }
                //console.log("this.listDef.fields",this.listDef.fields)
            }
        }
    }

    mergeStyle(columnlayout: any, rowlayout: any) {
        return Object.assign({}, columnlayout, rowlayout);
    }

    _evalFunc(expr: string, field: IField | null) {
        expr = expr.replace(/this\.mod\.data/g, 'data');
        expr = expr.replace(/this\.mod/g, 'policy');
        expr = expr.replace(/this\.data/g, 'data');
        let _expr = "try{/*console.log('if func: ', profile);*/return ("+expr+");}catch(e){/*console.log('if func: " + (field?field.field_name:'') + "', e.message);*/ return false;}";
        // console.log('expr: ', _expr);
        return new Function('data', 'profile', 'policy', "with(data){" + _expr +"}");
    }
}
