import { Component, OnInit } from '@angular/core';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Router, Route } from "@angular/router";
import { insapi, InsapiService, insreport, IProfile } from 'insapi';
import { environment } from './../../../environments/environment';
import moment from 'moment';
import { TimeElapsedPipe } from 'ins-form';
import { pastels_bright, pastels_gradient, prodIcons } from '../../theme/colors';
import { animShowStaggered } from '../../theme/animations';
import { Subscription } from 'rxjs';


@Component({
    selector: 'app-home',
    templateUrl: './home.component' + environment.brand + '.html',
    styleUrls: ['./home.component' + environment.brand + '.scss'],
    animations: [animShowStaggered]
})
export class HomeComponent implements OnInit {

    profile?: IProfile | null;
    profileAsOf: string = '';
    canProxy: boolean = false;

    showProxy: boolean = false;
    proxy_users: any[] = [];
    proxySelected: string = '';
    proxy_users_filtered:any[] = [];

    stats: any[] = [];
    weekName: string = '';
    topProducts: any[] = [];
    

    quotes: any[] = [];
    quotesFields: any[] = [
        {"field_name": "quote.data.product_name","label": "product"},
        {"field_name": "quote.email","label": "email"},
        {"field_name": "u_ts","label": "time"},
        {"field_name": "quote.premium_value","label": "premium"}
    ]    //['product', /*'quote',*/ 'email', 'time', 'premium'];
    referrals: any[] = [];
    referralFields: any[] = [
        {"field_name": "quote.premium_value","label": "premium"},
        {"field_name": "quote.data.broker_name","label": "producer"},
        {"field_name": "quote.data.product_name","label": "product"},
        {"field_name": "u_ts","label": "time"}        
    ];
    //['premium', 'producer', 'product', 'time'];
    more: boolean = false;

    pastels: any[] = pastels_bright; //pastels_gradient;

    types: any = {
        premium: {pipe: CurrencyPipe, args: [], color: '#473E99'},
        time: {pipe: TimeElapsedPipe, args: []},
    }

    flags: {[key: string]: boolean} = {pendingWith: false,approved: false,rejected: false, topProducts: true};

    subscription: Subscription | null = null;
    constructor(private router: Router, private currencyPipe: CurrencyPipe, private insapiService: InsapiService) { }

    ngOnInit(): void {
        if (environment.vendor?.home?.recent_quotes_fields)
            this.quotesFields = environment.vendor.home.recent_quotes_fields;
        if (environment.vendor?.home?.refferal_fields)
            this.referralFields = environment.vendor.home.refferal_fields;

        this.subscription = insapi.profileSubject.subscribe((profile: IProfile|null) => {
            this.profile = profile; 
            if (this.profile) {
                this.profileAsOf = moment().format('MMM DD HH:mm');
                this.weekStats();
                if (this.profile.is_underwriter)
                    this.recentReferrals();
                else
                    this.recentQuotes();

                this.flags.pendingWith = this._is_module_allowed('pending');
                this.flags.approved = this._is_module_allowed('approved');
                this.flags.rejected = this._is_module_allowed('rejected');
                this.flags.query_by_uw = this._is_module_allowed('query_by_uw');
                this.flags.topProducts = this._is_module_allowed('top_products');
                this.flags.recentQuotes = this._is_module_allowed('quotes');
            }
        });
    }

    ngOnDestroy(): void {
        if (this.subscription) this.subscription.unsubscribe();
        this.subscription = null;
    }

    _is_module_allowed(modname: string) {
        let roles = environment.vendor?.home?.modules?.[modname];
        if (!roles || roles.length == 0) return true;
        for (let role of roles) if (this.profile?.role_map[role]) return true;
        return false;
    }

    async weekStats() {
        if (!this.profile) return;
        const from = moment().startOf('week');
        const to = moment().endOf('week');
        const curday = moment().format('ddd');
        this.weekName = from.format('MMMM') +  ' ' + from.format('D') + ' - ' + (from.format('MMM')!=to.format('MMM') ? to.format('MMMM') : '') + ' ' + to.format('D');
        
        let stats = (await insreport.query('daily_sum', {from, to})) || [];
        if (stats.length==0) {
            console.log('stats: no stats found..., stuffing default');
            while(from.isBefore(to)) {stats.push({time: from.clone(), num: 0, total: 0}); from.add(1, 'day');}
        }

        this.stats = [];
        for (let stat of stats) {
            let day = moment(stat.time);
            this.stats.push({day: day.format('ddd'), current: curday==day.format('ddd'), count: stat.num || 0, total: stat.total || 0});
        }
        

        if (this.flags.topProducts) {
            const lastmon = moment().add(-1, 'month');
            stats = (await insreport.query('product_sum', {from: lastmon, to: moment()})) || [];
    
            let pnames = this.profile.products.map(x => x.product_name);
            stats = stats.filter((x: any) => pnames.indexOf(x)>=0);
            this.topProducts = stats.sort((a:any, b:any) => +a.num < +b.num);  // sort the result by the count of policies
    
            let favs = this.profile?.data?.preferences?.fav_prods || {};
            for(let prod of this.profile.products) {
                if (prod.status) continue;
                if (!prod.icon) prod.icon = prod.product_icon || prodIcons[Math.floor(Math.random()*(prodIcons.length-1))];
                if (prod.icon && prod.icon.startsWith('http')) prod.iconUrl = prod.icon;
    
                if (prod.icon && prod.icon.startsWith('fa-') ) prod.icon = prod.icon.substring(3);
                let match = this.topProducts.filter((x: any) => x.product_name == prod.product_name);
                if (match.length==0){
                    this.topProducts.push({product_name: prod.product_name, display_name: prod.display_name, product_id: prod.product_id,
                        icon: prod.icon || 'track_changes', iconUrl: prod.iconUrl, favorite: favs[prod.product_name]?true:false});
                }
                else{
                    match[0].display_name = this.currencyPipe.transform(+match[0].total||0, undefined, undefined, '2.0-0') + ' / ' + (match[0].num||0);
                    match[0].icon = prod.icon || 'track_changes';
                    match[0].iconUrl = prod.iconUrl;
                    match[0].favorite = favs[prod.product_name]?true:false;
                    match[0].product_id = prod.product_id;
                }
            }
            // rearrange favorites to the top
            this.topProducts = stats.sort((a:any, b:any) =>  (a.favorite === b.favorite)? 0 : a.favorite? -1 : 1);
        }

    }

    // toggleMoreProducts() {
    //     this.more = !this.more;
    //     this.products = this.more ? this.topProducts.slice(0) : this.topProducts.slice(0, 3);
    // }

    async _quotes(fields: string[], filters: any) {
        let quotes = await insapi.quotes('', fields.join(','), 3, filters, undefined, 'array', 'u_ts desc');
        if (quotes.length > 1) {
            let ids = fields.map(x => quotes[0].indexOf( x.split(' ')[1] || x ));
            return quotes.map((q: any[]) => ids.map(x => q[x]?.startsWith('G00') ? insapi.groupName(q[x]) : q[x]));
        }
        return [];
    }

    async recentQuotes() {
        if (!this.flags.recentQuotes) return;
        let fields = this.quotesFields.map(x=> x.field_name + ' ' + x.label);
        fields.push('policy_id','quote.quote_no quote');
        for (let col of this.quotesFields) {
            col.pipe_args = null;
            if (!col.pipe && col.type == 'currency') col.pipe = CurrencyPipe;
            if (!col.pipe && col.type == 'decimal') {col.pipe = DecimalPipe; col.pipe_args = '1.0-2'};
            if (!col.pipe && col.type == 'time') {col.pipe = TimeElapsedPipe; col.pipe_args = []};
        }
    
        this.quotes = await this._quotes(fields, {"quote.status": 0});
    }

    async recentReferrals() {
        //let fields = ['quote.premium_value premium','quote.data.broker_name producer','quote.data.product_name product','u_ts time', 'policy_id'];
        let fields = this.referralFields.map(x=> x.field_name + ' ' + x.label);
        fields.push('policy_id');

        for (let col of this.referralFields) {
            col.pipe_args = null;
            if (!col.pipe && col.type == 'currency') col.pipe = CurrencyPipe;
            if (!col.pipe && col.type == 'decimal') {col.pipe = DecimalPipe; col.pipe_args = '1.0-2'};
            if (!col.pipe && col.type == 'time') {col.pipe = TimeElapsedPipe; col.pipe_args = []};
        }

        this.referrals = await this._quotes(fields, {"quote.status": 0, referral: 1});
    }
    
    // async toggleFavorite(ev: any, prod: any) {
    //     if (ev) ev.stopPropagation();
    //     let fav = this.profile?.data?.preferences?.fav_prods || {};
    //     if (prod.favorite) delete fav[prod.product_name];
    //     else fav[prod.product_name] = true;
    //     prod.favorite = !prod.favorite;
    //     await insapi.setPreferences('fav_prods', fav);
    // }


    newPolicy(prodName: string) {
        if (!this.profile?.products) {
            insapi.showMessage('You do not have any product mapped yet', 0);
            return;
        }
        let prod = this.profile.products.filter(x => x.product_name == prodName);
        if (prod.length == 0) {
            insapi.showMessage('You are not authorized to use this product', 0);
            return; // we are not elligible (may be some of our reportees are, weird?)
        }
        this.router.navigate(['/policy'], { state: {asd:1}, queryParams: {product_id: prod[0].product_id}});
    }
    loadPolicy(quote: any) {
        this.router.navigate(['/policy'], { queryParams: {policy_id: quote[this.quotesFields.length]}});
    }
    loadReferral(quote: any) {
        this.router.navigate(['/policy'], { queryParams: {policy_id: quote[this.referralFields.length]}});
    }
    gotoPolicies(ev: Event) {
        ev?.stopPropagation();
        this.router.navigate(['/policy-list'], { queryParams: {}});
    }

    async filterProxyUsers(ev: any) {
        let flt = ev?.target?.value;
        if (!flt) this.proxy_users_filtered = this.proxy_users.slice(0, 10);
        else {
            flt = flt.toLowerCase();
            this.proxy_users_filtered = this.proxy_users.filter((x: any) => x.disp.toLowerCase().indexOf(flt) >= 0);
            console.log('pu:', this.proxy_users);
        }
    }

    async loadProxyUsers() {
        if (!this.profile) return;
        if (this.proxy_users.length == 0) {
            let users = await this.insapiService.getProxyUsers(this.profile.proxyOf || this.profile.email);
            for (let user of users) {
                user.disp = (user.broker_code ? user.broker_code + ' - ' : '') + user.email;
            }
            this.proxy_users = users;
            console.log('proxy:', this.proxy_users)
        }
        this.filterProxyUsers(null);
        this.showProxy = true;
    }
    async proxy(selected: any) {
        for (let user of this.proxy_users) {
            if (user.disp == selected) {
                await insapi.switchUser(user.email);
                this.proxySelected = '';
                this.showProxy = false;
                return;
            }
        }
        console.log('proxy: could not find selected in proxy list', selected)
        // if (!selected) return;
        // await insapi.switchUser(selected.email);
        // this.proxySelected = '';
        // this.showProxy = false;
    }

    async switchBack() {
        await insapi.switchUser(null);
    }

}
