<div *ngIf="type==1">
    <div class="product-header">
        <div>Top products</div>
        <div style="font-size: 0.7em;"><input class="prod-filter" [(ngModel)]="prefix" (input)="filter()" placeholder="Search" data-iid="search"></div>
        <div style="font-size: 0.7em; cursor: pointer;" *ngIf="more" (click)="toggleMoreProducts()">Less ...</div>
        <div style="font-size: 0.7em; cursor: pointer;" *ngIf="!more"  (click)="toggleMoreProducts()">More ...</div>
    </div>
    
    <div [class]="'prod-list-h grid-'+prd_columns" [@showStaggered]="filtered.length" [style.grid-template-columns]="'repeat('+prd_columns+', 1fr)'">
        <div class="top-product" *ngFor="let prod of filtered; let index=index">
            <div class="icon" [ngStyle]="pastels[index%pastels.length].style" [class.prod-disabled]="excluded[prod.product_id]" title="New Quote" (click)="_select_product(filtered[index].product_name, prod.product_id)">
                <mat-icon class="maticon" *ngIf="!prod.iconUrl">{{prod.icon}}</mat-icon>
                <img *ngIf="prod.iconUrl" class="prod-icon-img" [src]="prod.iconUrl" [class.prod-disabled]="excluded[prod.product_id]">
            </div>
            <div class="detail" [class.prod-disabled]="excluded[prod.product_id]">
                <div (click)="_select_product(filtered[index].product_name, prod.product_id)">{{prod.product_name}}</div>
                <div (click)="quoteList(index)" class="desc" title="Quote list" [style.color]="pastels[index%6].dark" style="font-size: 0.8em;">{{prod.display_name}}</div>
            </div>
            <div class="favprod" [style.color]="prod.favorite ? '#F25767' : '#CACACA'" (click)="toggleFavorite($event, prod)" 
                [class.favprod-selected]="prod.favorite">
                <mat-icon *ngIf="prod.favorite" class="maticon">favorite</mat-icon>
                <mat-icon *ngIf="!prod.favorite" class="maticon">favorite_border</mat-icon>
            </div>
        </div>
        <div *ngIf="filtered.length==0"> None found </div>
    </div>
</div>

<div class="prod-list-v"  *ngIf="type==2 || type==3">
    <div class="product" [class.prod-rot]="type==3" *ngFor="let prod of filtered; let index=index" (click)="showDesc(index)" >
        <div class="short" *ngIf="!prod.show">
            <div class="icon">
                <mat-icon class="maticon" *ngIf="!prod.iconUrl" [style.color]="pastels[index%6].dark">{{prod.icon}}</mat-icon>
                <img *ngIf="prod.iconUrl" class="prod-icon-img" [src]="prod.iconUrl">
            </div>
            <div class="detail"  >
                <div>{{prod.product_name}}</div>
            </div>
            <!--  -->

        </div>
        <div class="desc" *ngIf="prod.show" [@animScaleX]="prod.show">
            <div style="display: grid; grid-template-columns: 1fr; grid-template-rows: 1fr auto 1fr 1fr;">
                <div style="color: #AAAAAA;"><mat-icon class="maticon" *ngIf="!prod.iconUrl" [style.color]="pastels[index%6].dark">{{prod.icon}}</mat-icon> {{prod.product_id}}</div>
                <div style="font-size: 2em; padding-top: .4em; padding-bottom: .5em;">{{prod.product_name}}</div>
                <div style="color: #AAAAAA;">{{prod.display_name}}</div>
                <div>
                    <div class="action" (click)="_select_product(filtered[index].product_name, prod.product_id)">New Quote</div>
                    <div class="action" style="margin-left: 1em;" (click)="quoteList(index)">View Quotes</div>
                </div>
            </div>
            <div class="favprod" [style.color]="prod.favorite ? '#F25767' : '#CACACA'" (click)="toggleFavorite($event, prod)" 
                [class.favprod-selected]="prod.favorite">
                <mat-icon *ngIf="prod.favorite" class="maticon">favorite</mat-icon>
                <mat-icon *ngIf="!prod.favorite" class="maticon">favorite_border</mat-icon>
            </div>
        </div>

    </div>
</div>


<div class="prod-list-f grid-3"  *ngIf="type==4">
    <div class="product" [class.prod-rot]="type==3" *ngFor="let prod of filtered; let index=index">
        <div class="short" *ngIf="!prod.show">
            <div class="icon" (click)="quoteList(index)" title="View Quotes">
                <mat-icon class="maticon" *ngIf="!prod.iconUrl" [style.color]="pastels[index%6].dark">{{prod.icon}}</mat-icon>
                <img *ngIf="prod.iconUrl" class="prod-icon-img" [src]="prod.iconUrl">
            </div>
            <div class="detail" title="New Quote" (click)="_select_product(filtered[index].product_name, prod.product_id)">
                <div>{{prod.product_name}}</div>
            </div>
        </div>
    </div>
</div>
