import { Component, Input, OnInit, SimpleChange } from '@angular/core';
import { Policy, IProfile, insapi, _capitalize, intersects } from 'insapi';
import { MatDialog } from '@angular/material/dialog';
import { GenericMessageComponent, PreferencesService } from 'ins-form';
import { Subscription } from 'rxjs';
import { environment } from './../../../environments/environment';
import { IField } from 'ins-form';
// import { inserror } from './../';
// import{inserror} from 'insapi/src/lib/inspolicy'  //projects\insapi\src\lib

@Component({
    selector: 'referral-message',
    templateUrl: './referral-message.component.html',
    styleUrls: ['./referral-message.component.scss']
})
export class ReferralMessageComponent implements OnInit {

    @Input() messages: any[] = [];
    @Input() policy!: Policy;
    @Input() type: string = '';

    notes: any[] = [];
    profile: IProfile | null = null;
    flags: any = {canApprove: false, canReject: false, 
        canAdd: true, canTransfer: true, canAaccept: false, 
        canReturn: false, canReview: false};
    sendTo: string = "";
    uw: string = '';
    transferLists :  any[] = []; //[{"name":"UW Low", "value" : "G000000000037"},{"name":"UW Mid", "value" : "G000000000038"}];
    reason = "";
    codes: any[] = [];
    codesFiltered: any[] = [];
    rejectCode: string = '';
    rejectMode: boolean = false;
    subscription: Subscription | null = null;
    doctypes: any[] = [];
    docType: string = '';
    statuses: any[] = [];

    // value: inserror | null = null;

    useZulip: boolean = environment.vendor.zulip?.enabled || false;
    constructor(public dialog: MatDialog,
        public preferences: PreferencesService) {
        
    }

    ngOnInit(): void {
        this.subscription = insapi.profileSubject.subscribe((profile: IProfile|null) => {this.profile = profile; this._init();});
        console.log('this.subscription',this.subscription)
    }

    ngOnDestroy(): void {
        if (this.subscription) this.subscription.unsubscribe();
        this.subscription = null;
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        this._init();
    }

    async loadNotes() {
        let notes = [];
        try {
            if (this.policy) {
                notes = await this.policy.messages();
                for (let note of notes) note.avatar = note.author.split('@').join('.')+'.jpg';
            }
        } catch (e) {
            console.log(e);
        }
        if (!this.messages || !(this.messages instanceof Array)) this.messages = [];

        let messages = this.messages.map((x: any) => ({message: x.reason, 
            author: x.author, u_ts: x.u_ts, nstp_status: x.nstp_status, avatar: (x.author||'').split('@').join('.')+'.jpg'}));
        
        messages = messages.filter((x: any) => x.message != '');
        this.notes = [...notes, ...messages].sort((a: any, b: any) => a.u_ts < b.u_ts ? 1 : -1);
        for (let i=0; i<this.notes.length; i++) {
            this.notes[i].self = this.notes[i].author == this.profile?.email;
        }
        this.preferences.fix_names(this.notes, ['author'])
    }

    async _init() {
        if (this.policy?.endorsement) this._init_endorsement();
        else this._init_policy();
    }
    
    async _init_endorsement() {
        if (!this.policy?.endorsement || !this.profile) return;
        // auto detect type from data if not provided as input
        if (this.type == '') {
            if (this.policy.endorsement?.pnstp) this.type = 'pnstp';
            else this.type = 'nstp';
        }

        if (!this.policy.endorsement.endorsement_no) {
            if (this.policy.endorsement?.[this.type]) {  //nstp/qnstp/pnstp/inspect
                this.flags.canApprove = this.profile.privileges.indexOf(_capitalize(this.type) + ' Approve') >= 0;
                this.flags.canReject = this.profile.privileges.indexOf(_capitalize(this.type) + ' Reject') >= 0;
                this.flags.canAaccept = this.profile.privileges.indexOf(_capitalize(this.type) + ' Accept') >= 0;
                this.flags.canReview = this.profile.privileges.indexOf(_capitalize(this.type) + ' Review') >= 0;
                console.log('can approve:', this.type, this.flags.canApprove, this.policy.endorsement)
            }
        }

        this.sendTo = "";
        if (this.policy.endorsement?.assigned_to != this.profile.email) { // not owned by you, cant do anything
            this.flags.canApprove = false;
            this.flags.canReject = false;
            this.flags.canReview = false;
        } else if (this.policy.endorsement?.assigned_to != this.policy.endorsement?.created_by && this.policy.endorsement.data.product_name !='Open Policy' && this.policy.endorsement.data.product_name !='Sales Turnover') { // assigned to referral team/user/others
            if (this.profile.is_underwriter) this.sendTo = "Send to " + this.policy.endorsement?.created_by;
        } else {
            // dont send to self 
            if (!this.flags.canApprove && !this.policy.endorsement.policy_no && this.policy.endorsement?.assigned_by != this.profile.email && this.policy?.endorsement?.qnstp?.nstp_status!="approved")
                this.sendTo = "Send for approval";    // assigned to owner, request approval
        }

        if (this.policy.endorsement?.assigned_to == this.profile.email && this.profile.is_underwriter) {
            this.flags.canReturn = true;
        }

        if (this.sendTo.length > 20) this.sendTo = this.sendTo.substring(0, 20) + '...';
        if (this.profile.privileges.indexOf('Modify ' + _capitalize(this.type) + 's') >= 0) this.flags.canAdd = true;

        let status = this.policy.endorsement?.[this.type]?.nstp_status;
        if (status == 'approved' || status == 'rejected' || status == 'accepted') {
            this.flags.canApprove = false;
            this.flags.canReject = false;
            this.flags.canTransfer = false;
            this.flags.canReturn = false;
            this.sendTo = '';
        }

        if (!this.profile.is_underwriter && !environment.vendor.policy.referral?.allowTransfer) this.flags.canTransfer = false;

        this.statuses = [];
        let statuses = environment.vendor.policy?.referral?.status;
        
        if (!statuses) statuses = [{"field_name": "status_approve","label": "Approve", "type" : "button", "value": "approved"}, {"field_name": "status_reject","label": "Reject", "type" : "button", "value": "rejected"}];

        let data = this.policy?.endorsement?.eproposal.data || {};
        
        for (let s of statuses) {
            if (s.if && !s.ifFunc) s.ifFunc = this._evalFunc(s.if, s);
            if (s.value == 'approved') {
                if (this.flags.canApprove && (!s.ifFunc || s.ifFunc(data, this.profile, this.policy))) this.statuses.push(s);
            } else if (s.value == 'rejected') {
                if (this.flags.canReject && (!s.ifFunc || s.ifFunc(data, this.profile, this.policy))) this.statuses.push(s);
            } else if (s.value == 'reviewed') {
                if (this.flags.canReview && (!s.ifFunc || s.ifFunc(data, this.profile, this.policy))) this.statuses.push(s);
            } else if (!s.ifFunc || s.ifFunc(data, this.profile, this.policy)) {
                this.statuses.push(s);
            }
        }

        this.loadTransfers();
        this.loadNotes();

        if (this.profile?.is_underwriter) {
            try {this.codes = await insapi._xget_cache('/api/v1/rater/lookup/status_code') || [];}catch (e) {}
        }
    }

    async _init_policy() {
        if (!this.policy?.policy || !this.profile) return;

        let docs = await this.policy.documents(this.type);
        this.doctypes = [];
        for (let doc of docs) {
            this.doctypes.push({document_type: doc.document_type, desc: doc.document_desc||doc.document_description});
        }

        // auto detect type from data if not provided as input
        if (this.type == '') {
            if (this.policy.policy?.qnstp && this.policy.policy?.qnstp.nstp_status != 'approved') this.type = 'qnstp';
            else if (this.policy.policy?.pnstp && this.policy.policy?.pnstp.nstp_status != 'approved') this.type = 'pnstp';
            else this.type = 'nstp';
        }

        if (!this.policy.policy.policy_no) {
            if (this.policy.policy?.[this.type]) {  //nstp/qnstp/pnstp/inspect
                this.flags.canApprove = this.profile.privileges.indexOf(_capitalize(this.type) + ' Approve') >= 0;
                this.flags.canReject = this.profile.privileges.indexOf(_capitalize(this.type) + ' Reject') >= 0;
                this.flags.canAaccept = this.profile.privileges.indexOf(_capitalize(this.type) + ' Accept') >= 0;
                this.flags.canReview = this.profile.privileges.indexOf(_capitalize(this.type) + ' Review') >= 0;
                console.log('can approve:', this.type, this.flags.canApprove, this.policy.policy)
            }
        }

        this.sendTo = "";
        if (this.policy.policy?.assigned_to != this.profile.email) { // not owned by you, cant do anything
            this.flags.canApprove = false;
            this.flags.canReject = false;
            this.flags.canReview = false;
        } else if (this.policy.policy?.assigned_to != this.policy.policy?.created_by && this.policy.policy.quote.data.product_name !='Open Policy' && this.policy.policy.quote.data.product_name !='Sales Turnover') { // assigned to referral team/user/others
            if (this.profile.is_underwriter) this.sendTo = "Send to " + this.policy.policy?.created_by;
        } else {
            if (!this.flags.canApprove && !this.policy.policy.policy_no && this.policy.policy?.assigned_by != this.profile.email && this.policy?.policy?.qnstp?.nstp_status!="approved")
                this.sendTo = "Send for approval";    // assigned to owner, request approval
        }

        if (this.policy.policy?.assigned_to == this.profile.email && this.profile.is_underwriter) {
            this.flags.canReturn = true;
        }


        if (this.sendTo.length > 20) this.sendTo = this.sendTo.substring(0, 20) + '...';
        if (this.profile.privileges.indexOf('Modify ' + _capitalize(this.type) + 's') >= 0) this.flags.canAdd = true;

        let status = this.type == 'inspect' ? this.policy.policy?.[this.type]?.inspect_status : this.policy.policy?.[this.type]?.nstp_status;
        if (status == 'approved' || status == 'rejected' || status == 'accepted') {
            this.flags.canApprove = false;
            this.flags.canReject = false;
            this.flags.canTransfer = false;
            this.flags.canReturn = false;
            this.flags.canReview = false;
            this.sendTo = '';
        }
        if (!this.profile.is_underwriter && !environment.vendor.policy.referral?.allowTransfer) this.flags.canTransfer = false;

        this.statuses = [];
        let statuses = environment.vendor.policy?.referral?.status;
        if (this.type == 'inspect') statuses = environment.vendor.policy?.inspect?.status;
        
        if (!statuses) statuses = [{"field_name": "status_approve","label": "Approve", "type" : "button", "value": "approved"}, {"field_name": "status_reject","label": "Reject", "type" : "button", "value": "rejected"}];

        let data = this.policy?.policy?.proposal?.proposal_id ? this.policy?.policy?.proposal?.data : this.policy?.policy?.quote?.data || {};
        if (this.type == 'inspect') data = {...data, ...(this.policy?.policy?.inspect?.data||{})};

        for (let s of statuses) {
            if (s.if && !s.ifFunc) s.ifFunc = this._evalFunc(s.if, s);
            let allowed = false;

            if (s.value === 'approved') allowed = this.flags.canApprove;
            else if (s.value === 'rejected') allowed = this.flags.canReject;
            else if (s.value === 'reviewed') allowed = this.flags.canReview;
            else if (s.privileges) {
                if (typeof s.privileges === 'string') s.privileges = s.privileges.split(',');
                if (intersects(s.privileges, this.profile.privileges)) allowed = true;
            }else if (!s.ifFunc) allowed = true;
            else if (s.ifFunc(data, this.profile, this.policy)) allowed = true;

            if (allowed && (!s.ifFunc || s.ifFunc(data, this.profile, this.policy))) {
                this.statuses.push(s);
            }

            // if (s.value == 'approved') {
            //     if (this.flags.canApprove && (!s.ifFunc || s.ifFunc(data, this.profile, this.policy))) this.statuses.push(s);
            // } else if (s.value == 'rejected') {
            //     if (this.flags.canReject && (!s.ifFunc || s.ifFunc(data, this.profile, this.policy))) this.statuses.push(s);
            // } else if (s.value == 'reviewed') {
            //     if (this.flags.canReview && (!s.ifFunc || s.ifFunc(data, this.profile, this.policy))) this.statuses.push(s);
            // } else if (s.privileges) {
            //     if (typeof s.privileges === 'string') s.privileges = s.privileges.split(',');
            //     if (s.privileges instanceof Array) {
            //         let match = true;
            //         for (let priv of s.privileges)
            //             if (this.profile.privileges.indexOf(priv) < 0) match = false;
            //         if (match) this.statuses.push(s);
            //     }
            // } else if (!s.ifFunc || s.ifFunc(data, this.profile, this.policy)) {
            //     this.statuses.push(s);
            // }
        }

        this.loadTransfers();
        this.loadNotes();

        if (this.profile?.is_underwriter) {
            try {this.codes = await insapi._xget_cache('/api/v1/rater/lookup/status_code') || [];}catch (e) {}
        }

    }

    _evalFunc(expr: string, field: IField | null) {
        expr = expr.replace(/this\.mod\.data/g, 'data');
        expr = expr.replace(/this\.mod/g, 'policy');
        expr = expr.replace(/this\.data/g, 'data');
        let _expr = "try{return ("+expr+");}catch(e){/*console.log('if func: " + (field?.field_name||'') + "', e.message);*/ return false;}";
        return new Function('data', 'profile', 'policy', "with(data){" + _expr +"}");
    }


    async loadTransfers() {
        this.transferLists = [];
        if(this.flags.canTransfer) {
            if (this.policy?.product?.data[this.type+'_group_levels'] && (this.profile?.is_underwriter || environment.vendor.policy.referral?.allowTransfer)) {
                var arr = this.policy?.product?.data[this.type+'_group_levels']?.split(',');
                for (let i=0; i<arr.length; i++) {
                    let gname = insapi.groupName(arr[i]);
                    this.transferLists.push({"name":gname , "value" : arr[i]});                
                }
            }
        }
        
    }

    async sendNote() {
        await this.policy.__assignTo('', '', this.reason);
        await this.loadNotes();
        this.reason = "";
    }

    async onStatusAction(status: any) {
        if (status.value == 'approved') this.approve();
        else if (status.value == 'rejected') this.reject();
        else if (status.assignTo) {
            let ifFunc = this._evalFunc(status.assignTo, null);
            var assignTo = ifFunc(this.policy, this.profile, this.policy);
            await this.policy.__assignTo(assignTo, status.value, this.reason||status.reason, status.notifyTmpl);
            await this.loadNotes();
        }
        else this.policy.sendMessage(status.value, this.reason||status.reason, this.type, '', '');
        this.reason = '';
    }

    approve() {
		 if (this.policy.errors?.length > 0) {
			insapi.showMessage(this.policy.errors[0]['msg'], 0);
		 }else{
			this.policy.__approve(this.reason, this.type); 
		 }
		console.log(this.policy.policy.quote.data);
		//this.policy.saveQuote(this.policy.policy.quote.data);
       /*  if (this.policy.errors?.length > 0) {
            // confirm if user still wants to proceed
            let desc = environment.vendor.policy?.referral?.approveOnError || 'Missing config';
            for (let err of this.policy.errors) {
                let e: any = err;
                if (typeof e === 'string') desc += '<p>' + e + '</p>';
                else desc += '<p>' + e.msg + '</p>';
            }
            let data = {
                caption: 'Errors foound', 
                description: desc,
                actions: [{name: 'approve', disp: 'Approve'}, {name: 'close', disp: 'Cancel'}]
            };
            let ref = this.dialog.open(GenericMessageComponent, {data});
            ref.afterClosed().subscribe((action) => {
                if (action.name == 'approve') this.policy.__approve(this.reason, this.type);
            });
        } else {
            this.policy.__approve(this.reason, this.type);
        } */
    }
    reject() {
        this.policy.__reject(this.reason, this.type, this.rejectCode);
    }

    async returnToGroup() {
        await this.policy.__assignTo('group', '', this.reason || 'Returned by ' + this.profile?.email);
        await this.loadNotes();
    }

    
    async assignTo() {
        await this.policy.saveQuote(this.policy.policy.quote.data);
        console.log("this.policy.errors>>>>>>>>>>", this.policy.errors);
        var entity = this.sendTo.startsWith('Send to ') ? this.policy.policy?.created_by : (this.sendTo.startsWith('Send for ') ? this.policy.policy?.assigned_by : '');
            
        if (this.policy.errors && this.policy.errors?.length > 0) {
            insapi.showMessage(this.policy.errors[0]['msg'], 0);
        } 
        else {
            var ret = await this.policy.__assignTo(entity, '', this.reason);
            if (ret) {
                if (this.sendTo.startsWith('Send to')) {
                    if (this.preferences.vendor?.policy.referral?.send_to_broker_status)
                        this.policy.sendMessage(this.preferences.vendor?.policy.referral?.send_to_broker_status, '', this.type, '', '');
                }
                await insapi.showMessage("Assigned to " + entity, 1);
            }
            await this.loadNotes();
            this.reason = "";
        }        
        
    }


    
    async transferTo() {
        if (!this.uw) return;
        var ret = await this.policy.__assignTo(this.uw, '', this.reason || 'Transferred by ' + this.profile?.email);
        if (ret) await insapi.showMessage("Assigned to " + this.profile?.all_groups[this.uw]?.group_name || this.uw, 1);
        await this.loadNotes();
        this.reason = "";
    }


    async onOptionInput(ev: any) {
        this.codesFiltered = this.codes.filter((x => !ev?.target?.value || x.indexOf(ev.target.value)>=0));
    }
    codeSelected(ev: any) {
        let opt = this.codes.filter(x => x.status_code == ev.option.value);
        if (opt.length > 0) this.reason = opt[0].reason;
    }

    async onAttach(ev: any) {
        await this.policy.__assignTo('', '', "Attached document: " + ev.target.files[0].name + " ["+this.docType+"]");
        if (await this.policy.uploadDocument(this.docType, "", ev.target.files[0])) {
            insapi.showMessage('Document uploaded', 0);
            await this.loadNotes();
        }
    }
}
