import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import { MatSliderChange } from '@angular/material/slider';
import { DateAdapter } from '@angular/material/core';
import { FormGroup } from "@angular/forms";
import { IField } from '../../form.interface';
import moment from 'moment';


export interface RangeType {
    min: number;
    max: number;
  }
  
@Component({
    selector: 'period',
    templateUrl: './period.component.html',
    styleUrls: ['./period.component.scss'],
    providers: [
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PeriodComponent), multi: true}
    ],
    encapsulation: ViewEncapsulation.None
})
export class PeriodComponent implements OnInit {

    @Input() data!: any;
    @Input() field!: IField;
    @Input() group!: FormGroup;


    @Input() minAllowed: moment.Moment = moment().add(-365, 'days');
    @Input() maxAllowed: moment.Moment = moment();
    @Output() onChange = new EventEmitter();

    minDate: moment.Moment = moment().add(-7, 'days');
    maxDate: moment.Moment = moment();
    min = 0;
    max = 365;

    labels: any = {};
    ruler: string[] = [];

    selected: string = '';
    perioddisp: string[] = [];
    calstart: Date = new Date();
    calend: Date = new Date();
    intervals: string[] = ['1d', '2d', '7d'];

    constructor(private dateAdapter: DateAdapter<Date>) { }

    registerOnChange(fn: any) {}
    registerOnTouched(fn: any){};
    writeValue(value: string | moment.Moment[]) {
        // console.log('period: write value', value);
        if (!value) return;
        let parts = (typeof value == 'string') ? value.split(',').map(x => moment(x, 'YYYY-MM-DD')) : value;
        this.minDate = parts[0] || this.minDate;
        this.maxDate = parts[1] || this.maxDate;
        
        if (this.maxDate.isAfter(this.maxAllowed)) this.maxDate = this.maxAllowed.clone();
        if (this.minDate.isBefore(this.minAllowed)) this.minDate = this.minAllowed.clone();

        if (this.field?.mode != 'slider' && typeof value === 'string') this.__parse_period(value);

        // console.log('period: write value', this.minDate.format('YYYY-MM-DD'), this.maxDate.format('YYYY-MM-DD'));
        this._update_min_max();
        this._set_lables();
    }

    get value() {
        return this.minDate.format('YYYY-MM-DD') + ',' + this.maxDate.format('YYYY-MM-DD');
    }


    isMinValueInit = true;
    isMaxValueInit = true;
    thumbLabel = true;
    minValue: number = 0;
    maxValue: number = 0;
    minValueDt: Date = new Date();
    maxValueDt: Date = new Date();
    showRuler = false;
  
    formatLabel = (v: number) => this.labels[v] || v;
  
    get rulerArray(): string[] {
        return this.ruler;
    }
  
    _set_lables() {
        let range = this.maxAllowed.diff(this.minAllowed, 'days');
        let day = this.minAllowed.clone();
        this.labels = [];
        this.ruler = [];
        for (let i=0; i<range+1; i++) {
            this.labels.push(day.format('YYYY-MM-DD'));
            if ((i%7) == 0 && (i%14) != 0) this.ruler.push(day.format('MMM'));
            day.add(1, 'day');
        }
    }

    _update_min_max() {
        this.minValue = this.minDate.diff(this.minAllowed, 'days');
        this.maxValue = this.maxDate.diff(this.minAllowed, 'days');
        this.min = 0;
        this.max = this.maxAllowed.diff(this.minAllowed, 'days');
        this.isMinValueInit = (this.minValue === this.min);
        this.isMaxValueInit = (this.maxValue === this.max);
        this.minValueDt = this.minDate.toDate();
        this.maxValueDt = this.maxDate.toDate();
    }

    ngOnInit(): void {
        if (this.field && this.field.range) {
            let parts = this.field.range.split(',').map((x: any) => moment(x, 'YYYY-MM-DD'));
            if (parts.length > 1) {
                this.minAllowed = parts[0];
                this.maxAllowed = parts[1];
            }
        }

        if (this.field && this.data[this.field.field_name]) this.writeValue(this.data[this.field.field_name]);
        else if (this.field?.mode != 'slider') this.__parse_period('1d');
        this._set_lables();
        this._update_min_max();
    }

    changed() {
        this.onChange.emit({min: this.minDate.format('YYYY-MM-DD'), max: this.maxDate.format('YYYY-MM-DD')});
    }
    
    valueChange(): void {
        this.minDate = this.minAllowed.clone().add(this.minValue, 'days');
        this.maxDate = this.minAllowed.clone().add(this.maxValue, 'days');
        this.minValueDt = this.minDate.toDate();
        this.maxValueDt = this.maxDate.toDate();
        this.changed();
    }

    minValuePicked(ev: any) {
        this.minDate = moment(ev.value);
        if (this.minDate.isSameOrAfter(this.maxDate)) this.minDate = this.maxDate.clone().add(-1, 'days');
        this._update_min_max();
        this.changed();
    }

    maxValuePicked(ev: any) {
        this.maxDate = moment(ev.value);
        if (this.maxDate.isSameOrBefore(this.minDate)) this.maxDate = this.minDate.clone().add(1, 'days');
        this._update_min_max();
        this.changed();
    }

    minValueInput(a: MatSliderChange): void {
        if (a.value == null) return;
        this.isMinValueInit = (a.value === this.min);
        if (a.value >= this.maxValue) {
            a.source.value = this.maxValue - 1;
        }
        
    }
  
    maxValueInput(a: MatSliderChange): void {
        if (a.value == null) return;
        this.isMaxValueInit = (a.value === this.max);
        if (a.value <= this.minValue) {
            a.source.value = this.minValue + 1;
        }
        
    }

    
    __parse_period(period: string) {
        if (!period) period = '1h';
        if (period == 'start') {
            this.minDate = moment(this.calstart);
        } else if (period == 'end') {
            this.maxDate = moment(this.calend);
        } else if (period.endsWith('h')) {
            this.minDate = moment().add(-1*(+period.substring(0, period.length-1)), 'hour');
            this.maxDate = moment();
            this.selected = period;
        } else if (period.endsWith('d')) {
            this.minDate = moment().add(-1*(+period.substring(0, period.length-1)), 'day');
            this.maxDate = moment();
            this.selected = period;
        } else {
            let parts = period.split(',');
            if (parts.length == 2) {
                this.minDate = moment(parts[0]);
                this.maxDate = moment(parts[1]);
                let diff = this.maxDate.diff(this.minDate, 'days');
                if (this.maxDate.isSameOrAfter(moment().startOf('day'))) {
                    this.selected = diff+'d';
                }
            }
        }
        this.perioddisp = [this.minDate.format('YYYY-MM-DD'), this.maxDate.format('YYYY-MM-DD')];
    }

    changePeriod(period: string) {
        this.__parse_period(period);
        this.changed();
    }
}
