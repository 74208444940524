<div *ngIf="loginfullscreen!='true'">
	<div>
		<div class="login-popup" [ngStyle]="{'background-image': bgImg ? 'url(assets/img/' + bgImg+ ')' : ''}" >
			<div class="forgot-password" *ngIf="frgtPass">
				<div class="forgot-password-caption" style="padding: 1.1em 2.2em 1.1em 0em; text-align: left; font-size: 1.4em;letter-spacing: 0em;">
					FORGOT PASSWORD
				</div>
				<span class="forgot-password-desc">Confirmation mail has been sent to the email address {{email}}. Please follow the instructions to reset your password.</span>
			</div>
			<div class="login" *ngIf="!showOTP && !frgtPass && !showChangePwd">
				<div class="login-caption" style="padding: 1.1em 1.2em 1.1em 0em; text-align: left; font-size: 1.6em; letter-spacing: .07em;">
					LOGIN
				</div>
				<div class="login-email">
					<mat-form-field style="width: 100%;">
						<mat-label>{{adlogin ? "User ID":"EMAIL"}}</mat-label>
						<input matInput [(ngModel)]="email" id="login_email">
						<!-- <mat-icon matSuffix>email</mat-icon> -->
					</mat-form-field>
				</div>
				<div class="login-password">
					<mat-form-field style="width: 100%;">
						<mat-label>PASSWORD</mat-label>
						<input matInput [(ngModel)]="password" [type]="type" id="login_password">
						<mat-icon matSuffix fontSet="material-icons-outlined" (click)="showPassword()" style="cursor: pointer; color: #aae; vertical-align: middle;">visibility_outline</mat-icon>
					</mat-form-field>
				</div>
				<div class="login-totp" *ngIf="showTOTP">
					<mat-form-field style="width: 100%;">
						<mat-label>2FA OTP</mat-label>
						<input matInput [(ngModel)]="totp" id="login_totp">
					</mat-form-field>
				</div>
				<div *ngIf="!adlogin" style="font-size: 0.9em; color: #666; text-align: center;" (click)="forgetPassword()">Forgot password?</div>
			</div>
			<div class="login-otp" *ngIf="showOTP">
				<div class="login-otp-caption" style="padding: 1.1em 1.2em 1.1em 0em; text-align: left; font-size: 1.2em;letter-spacing: 0em;">
					Provide your OTP to login.
				</div>
				<div class="login-verifyotp">
					<mat-form-field style="width: 100%;">
						<mat-label>OTP</mat-label>
						<input matInput [(ngModel)]="verifyOtp"  id="verifyOtp"  >
					</mat-form-field>
				</div>
				<div class="resend" style="font-size: 0.9em; text-align: center;"  [ngStyle]="{'pointer-events': timerFlag == true ? 'none':'inherit', 'color': (timerFlag == true || timeLeft==-1) ?  '#cccccc':'#1976d2'}" class="resend" (click)="onSendOTP()">Resend
					<span class="resend-time" *ngIf="timerFlag"> {{timeLeft}} Sec</span>
				</div>
			</div>
			<div *ngIf="showChangePwd">
				<div style="padding: 0em 1.2em 0.8em 0em; text-align: left; font-size: 1.2em; letter-spacing: 0em;">
					CHANGE PASSWORD
				</div>
				<div>
					<mat-form-field style="width: 100%;">
						<mat-label>OLD PASSWORD</mat-label>
						<input  matInput [(ngModel)]="old_pwd" [type]="old_type" id="old_password">
						<mat-icon matSuffix fontSet="material-icons-outlined" (click)="showOldPassword()" style="cursor: pointer; color: #aae; vertical-align: middle;">visibility_outline</mat-icon>
					</mat-form-field>
				</div>
				<div>
					<mat-form-field style="width: 100%;">
						<mat-label>NEW PASSWORD</mat-label>
						<input  matInput [(ngModel)]="new_pwd"  [type]="new_type" id="new_password">
						<mat-icon matSuffix fontSet="material-icons-outlined" (click)="showNewPassword()" style="cursor: pointer; color: #aae; vertical-align: middle;">visibility_outline</mat-icon>
					</mat-form-field>
				</div>
				<div>
					<mat-form-field style="width: 100%;">
						<mat-label>CONFIRM PASSWORD</mat-label>
						<input  matInput  [(ngModel)]="cnfrm_pwd"  [type]="type" id="confirm_password">
						<mat-icon matSuffix fontSet="material-icons-outlined" (click)="showPassword()" style="cursor: pointer; color: #aae; vertical-align: middle;">visibility_outline</mat-icon>
					</mat-form-field>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="!showOTP && !frgtPass && !showChangePwd" [ngStyle]="{'grid-template-columns': adlogin ? '1fr' : '1fr 1fr'}" style="display: grid;">
		<div *ngIf="!adlogin" class="sendotp-div" style="cursor: pointer; background-color: #CCCCCC; padding: 1.2em 1.2em;border-bottom-left-radius: 10px;" (click)="onSendOTP()">
			<button class="login-btn sendotp-btn" style="text-align: left;">SEND OTP</button>
		</div>
		<div class="signin-div" style="cursor: pointer; background-color: var(--bg-hilite-700); padding: 1.2em 1.2em; text-align: right; color: #FFF;border-bottom-right-radius: 10px;" (click)="onLogin()">
			<button class="login-btn signin-btn" style="color: #FFF; text-align: right;">SIGN IN</button>
		</div>
	</div>
	<div *ngIf="showOTP" style="display: grid;" [ngStyle]="{'grid-template-columns': timeLeft>0 ? '1fr' : '1fr 1fr'}">
		<div class="verify-div" style="cursor: pointer; background-color: #CCCCCC; padding: 1.2em 1.2em;border-bottom-left-radius: 10px;" (click)="onVerifyOTP()">
			<button class="login-btn verify-btn" style="text-align: left;">Verify</button>
		</div>
		<div *ngIf="timeLeft<=0" class="otpback-div" style="cursor: pointer; background-color: var(--bg-hilite-700); padding: 1.2em 1.2em; text-align: right; color: #FFF;border-bottom-right-radius: 10px;" (click)="navigateToBack()">
			<button class="login-btn otpback-btn" style="color: #FFF; text-align: right;" >Back</button>
		</div>
	</div>
	<div *ngIf="frgtPass">
		<div class="forgotback-div" style="cursor: pointer; background-color: var(--bg-hilite-700); padding: 1.2em 1.2em; text-align: right; color: #FFF;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;" (click)="navigateToBack()">
			<button class="login-btn forgotback--btn" style="color: #FFF; text-align: right;" >Back</button>
		</div>
	</div>
	<div *ngIf="showChangePwd" style="display: grid;grid-template-columns: 1fr 1fr;">
        <div style="cursor: pointer; background-color: #CCCCCC; padding: 1.2em 1.2em; border-bottom-left-radius: 10px;" (click)="save_password()">
            <button  style="text-align: left;background: transparent; border: none; width: 100%; height: 100%; font: inherit; letter-spacing: .15rem;">SAVE PASSWORD</button>
        </div>
        <div style="cursor: pointer; background-color: var(--bg-hilite-700); padding: 1.2em 1.2em; border-bottom-right-radius: 10px; text-align: right; color: #FFF;" (click)="Back()">
            <button  style="color: #FFF; text-align: right;background: transparent; border: none; width: 100%; height: 100%; font: inherit; letter-spacing: .15rem;" (click)="navigateToBack()">BACK</button>
        </div>
	</div>
	</div>

	<div *ngIf="loginfullscreen=='true'">
	<div class="img-div  w-100">
		<div id='left-img' [ngStyle]="{'background-image': leftbgImg ? 'url(assets/img/' + leftbgImg+ ')' : ''}" ></div>
		<div id='logo-img' [ngStyle]="{'background-image': logoImg ? 'url(assets/img/' + logoImg+ ')' : ''}"  ></div>
		<div id='right-img'[ngStyle]="{'background-image': rightbgImg ? 'url(assets/img/' + rightbgImg+ ')' : ''}"  ></div>
	</div>
	
	<div class="login-container">
		<div class="login-popup">
			<div class="forgot-password" *ngIf="frgtPass">
			<div class="container">
			<div class="forgot-password-caption" style="padding: 1.1em 0em 1.1em 0em; text-align: left; font-size: 1.4em;letter-spacing: 0em;">
					FORGOT PASSWORD
				</div>
				<span class="forgot-password-desc">Confirmation mail has been sent to the email address {{email}}. Please follow the instructions to reset your password.</span>
				<div class="w-100 text-center back-btn" (click)="navigateToBack()"><small class="blue-btn">Back</small></div> 
			</div>
			</div>
			<div class="login" *ngIf="!showOTP && !frgtPass && !showChangePwd">
				<div class="container">
                <div class="text-center" style="margin-bottom: 10px;"><span class="logform-head">Sign in your account</span></div>
                <div class="d-flex"><span class="icon-section"><div class="loginicon-img" [ngStyle]="{'background-image': usericonImg ? 'url(assets/img/' + usericonImg+ ')' : ''}"></div></span><input type="text" class="loginInputs" placeholder="Username" name="uname" [(ngModel)]="email" id="login_email"></div>
                <div class="d-flex"><span class="icon-section"><div class="loginicon-img" [ngStyle]="{'background-image': pwdiconImg ? 'url(assets/img/' + pwdiconImg+ ')' : ''}"></div></span><input [(ngModel)]="password" [type]="type" id="login_password" class="loginInputs" placeholder="Password" name="psw">
				<mat-icon class="eye-icon" matSuffix fontSet="material-icons-outlined" (click)="showPassword()" style="cursor: pointer; color: #aae; vertical-align: middle;transform: translateX(-35px);overflow: visible;margin-top: 15px;position: absolute;right:40px;">visibility_outline</mat-icon>
				</div>
                <div class="d-flex">
                <div class="w-50">
                </div>
                <div class="w-50 text-end" *ngIf="!adlogin" (click)="forgetPassword()"><small>Forgot Password?</small></div>
            	</div>
				<div *ngIf="!showOTP && !frgtPass && !showChangePwd">
                <div class="w-100 text-center" style="margin-top: 10px;" (click)="onLogin()"><button class="logn-btn" type="submit">Login</button></div>   
				 
				
				<div *ngIf="!adlogin" class="sendotp-div" (click)="onSendOTP()">
				<div class="w-100 text-center" style="margin-top: 10px;"><small class="blue-btn">SEND OTP</small></div>
				</div>
				</div>   
              </div>
				<div class="login-totp" *ngIf="showTOTP">
					<mat-form-field style="width: 100%;">
						<mat-label>2FA OTP</mat-label>
						<input matInput [(ngModel)]="totp" id="login_totp">
					</mat-form-field>
				</div>
			</div>
			<div class="login-otp" *ngIf="showOTP">
			<div class="container">
			<div class="login-otp-caption" style="padding: 1.1em 1.2em 1.1em 0em; text-align: left; font-size: 1.2em;letter-spacing: 0em;">
					Provide your OTP to login.
				</div>
				<div class="d-flex"><input type="text" class="otpInput" placeholder="OTP" name="otp" [(ngModel)]="verifyOtp"  id="verifyOtp" ></div>
				<div class="resend" style="font-size: 0.9em; text-align: center;"  [ngStyle]="{'pointer-events': timerFlag == true ? 'none':'inherit', 'color': (timerFlag == true || timeLeft==-1) ?  '#cccccc':'#1976d2'}" class="resend" (click)="onSendOTP()">Resend
					<span class="resend-time" *ngIf="timerFlag"> {{timeLeft}} Sec</span>
				</div>
				<div class="w-100 text-center" (click)="onVerifyOTP()"><button class="logn-btn" type="submit">VERIFY</button></div>
				<div class="w-100 text-center" (click)="navigateToBack()"><small class="blue-btn">Back</small></div> 
			</div>
			</div>
			<div *ngIf="showChangePwd">
				<div style="padding: 0em 1.2em 0.8em 0em; text-align: left; font-size: 1.2em; letter-spacing: 0em;">
					CHANGE PASSWORD
				</div>
				<div>
					<mat-form-field style="width: 100%;">
						<mat-label>OLD PASSWORD</mat-label>
						<input  matInput [(ngModel)]="old_pwd" [type]="old_type" id="old_password">
						<mat-icon matSuffix fontSet="material-icons-outlined" (click)="showOldPassword()" style="cursor: pointer; color: #aae; vertical-align: middle;">visibility_outline</mat-icon>
					</mat-form-field>
				</div>
				<div>
					<mat-form-field style="width: 100%;">
						<mat-label>NEW PASSWORD</mat-label>
						<input  matInput [(ngModel)]="new_pwd"  [type]="new_type" id="new_password">
						<mat-icon matSuffix fontSet="material-icons-outlined" (click)="showNewPassword()" style="cursor: pointer; color: #aae; vertical-align: middle;">visibility_outline</mat-icon>
					</mat-form-field>
				</div>
				<div>
					<mat-form-field style="width: 100%;">
						<mat-label>CONFIRM PASSWORD</mat-label>
						<input  matInput  [(ngModel)]="cnfrm_pwd"  [type]="type" id="confirm_password">
						<mat-icon matSuffix fontSet="material-icons-outlined" (click)="showPassword()" style="cursor: pointer; color: #aae; vertical-align: middle;">visibility_outline</mat-icon>
					</mat-form-field>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="showChangePwd" style="display: grid;grid-template-columns: 1fr 1fr;">
        <div style="cursor: pointer; background-color: #CCCCCC; padding: 1.2em 1.2em; border-bottom-left-radius: 10px;" (click)="save_password()">
            <button  style="text-align: left;background: transparent; border: none; width: 100%; height: 100%; font: inherit; letter-spacing: .15rem;">SAVE PASSWORD</button>
        </div>
        <div style="cursor: pointer; background-color: var(--bg-hilite-700); padding: 1.2em 1.2em; border-bottom-right-radius: 10px; text-align: right; color: #FFF;" (click)="Back()">
            <button style="color: #FFF; text-align: right;background: transparent; border: none; width: 100%; height: 100%; font: inherit; letter-spacing: .15rem;" (click)="navigateToBack()">BACK</button>
        </div>
	</div>
	</div>
	<div style="height: 40px;position: fixed;left: 0;bottom: 0;width: 100%;background-color: #ed1c24;color: white;text-align: center;">
		<!-- <p style="padding-top: 12px;"><a style="text-decoration: none;color: white;" href="https://www.kotakgeneral.com/privacy-policy">Privacy Policy</a></p> --><p style="padding-top: 12px;"><a style="text-decoration: none;color: white;" href="https://www.zurichkotak.com/services/privacy-policy">Privacy Policy</a></p>
	  </div>
	  