<!--  <div class="div-curved">
    <div class="chip-container">
        <div style="display: flex; justify-content: center; align-items: center;" *ngIf="!filtered_list">
            <mat-chip-list>
                <mat-chip *ngFor="let t of types" [class]="type==t ? 'chip-selected' : ''" (click)="switchType(t)">{{t | titlecase}}</mat-chip>
            </mat-chip-list>
        </div>
    </div>
    <div class="svg-curved">
        <svg viewBox="0 0 500 150" style="height: 100%; width: 100%;" preserveAspectRatio="none">
          <linearGradient id="gradient"><stop offset="0%" class="curve-stop-0" /><stop offset="100%" class="curve-stop-100"/></linearGradient>
          <path d="M-0.85,137.65 C135.72,178.12 336.06,-110.03 500.84,85.35 L500.00,0.00 L0.00,0.00 Z" style="stroke: none; fill: url(#gradient);"></path>
        </svg>
    </div>
</div>  -->

<div class="page-container-m" *ngIf="listDef">
    <div>
        <field-group #fgs [fieldGroup]="form.groups[0]" [data]="filters" [layout]="form.layout"
            (onAction)="onFilterAction($event)" (onChange)="onFilterChange($event)">
        </field-group>
    </div>

    <div>
        <button mat-button ibutton (click)="onFilterAction()">Apply Filters</button>
        <!-- <button mat-button ibutton (click)="onFilterAction()" *ngIf="!product && !filtered_list">Fetch Policies</button> -->
        <!--  <button mat-button ibutton (click)="changeProduct()" >Change Product</button> -->
        <!--  <button mat-button ibutton (click)="downloadAsCsv()" >Save as CSV</button>-->
         <button mat-button ibutton (click)="downloadAsXlsx()" >Save as Xlsx</button> 
		 <!--<button mat-button ibutton (click)="downloadAsPDF()" >Save as PDF</button> -->
    </div>
</div>
<div class="page-container-m" style="display: grid; grid-template-columns: 1fr 2fr 1fr 2fr;" *ngIf="userInfo">
    <div class="grid-label"> Customer Name: </div>
    <div class="grid-value">{{userInfo.customer_name}}</div>
    <div class="grid-label"> Policy Number:  </div>
    <div class="grid-value">{{userInfo.policy_no}}</div>
    <div class="grid-label"> Policy Start Date: </div>
    <div class="grid-value">{{userInfo.policy_start_date}}</div>
    <div class="grid-label"> Policy End Date: </div>
    <div class="grid-value">{{userInfo.policy_end_date}}</div>
    <div class="grid-label"> Product Name: </div>
    <div class="grid-value">{{userInfo.product_name}}</div>
    <div class="grid-label"> UIN No.: </div>
    <div class="grid-value">{{userInfo.uin_no}}</div>
    <div class="grid-label"> Initial Policy Sum Insured: </div>
    <div class="grid-value">{{userInfo.initial_sum_insured}}</div>
    <div class="grid-label">Customer Address: </div>
    <div class="grid-value"> {{userInfo.cust_address}}</div>
   
</div>
<!-- <div class="list-container" [style.max-width]="form.layout.width" [style.min-width]="form.layout.width" > -->
<div class="list-container" style="max-width: 100%; min-width: 100%;">


    <div class="header">
        <div>
            <h3 *ngIf="product">{{product.product_name}} - SOA Report </h3>
        </div>
        <div>
            <mat-form-field>
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Filter string" #input>
            </mat-form-field>
        </div>
    </div>
    <mat-table [dataSource]="dataSource" matSort>
        <ng-container *ngFor="let col of listDef.columns; let i = index" [matColumnDef]="col.disp">
            <ng-template [ngIf]="col.type==='action' && actions.showAsList">
                <mat-header-cell *matHeaderCellDef mat-sort-header> {{col.disp | capitalize}} </mat-header-cell>
                <mat-cell *matCellDef="let row" [attr.data-label]="col">
                    <mat-icon class="action-icon" *ngFor="let act of row.actions" (click)="menuClicked(act, row)"
                        [style]="act.style||{}" [title]="act.name">{{act.icon}}</mat-icon>
                </mat-cell>
            </ng-template>
            <ng-template [ngIf]="col.type==='action' && !actions.showAsList">
                <mat-header-cell *matHeaderCellDef mat-sort-header style="max-width: 48px;"> {{col.disp | capitalize}}
                </mat-header-cell>
                <mat-cell *matCellDef="let row" [attr.data-label]="col" style="max-width: 48px;">
                    <button mat-icon-button [matMenuTriggerFor]="actionMenu" [matMenuTriggerData]="{row: row}"
                        aria-label="">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                </mat-cell>
            </ng-template>


            <ng-template [ngIf]="col.type!=='action'">
                <mat-header-cell *matHeaderCellDef mat-sort-header [style]="col.style||{}"> {{col.disp | capitalize}}
                </mat-header-cell>
                <mat-cell *matCellDef="let row" [attr.data-label]="col.disp" [style]="col.style||{}">
                    <span class="mobile-label">{{col.disp}}</span>
                    <div style="cursor: pointer; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                        [title]="row[col.disp]">{{row[col.name] | generic2: col.pipe}}</div>
                </mat-cell>
            </ng-template>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columns;"></mat-row>
        <mat-menu #actionMenu>
            <ng-template matMenuContent let-row="row">
                <button mat-menu-item *ngFor="let act of row.actions" (click)="menuClicked(act, row)">
                    <mat-icon>{{act.icon}}</mat-icon>{{act.name}}
                </button>
            </ng-template>
        </mat-menu>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>