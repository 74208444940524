import { HttpClient } from '@angular/common/http';

class tokemgr {
    _cur_token: string = '';
    _prx_token: string = '';
    _prx_email: string = '';
    _guest_key: string = '';
    _b2c_mode: string = '';
    proxy: boolean = false;
    store: string = '';
    constructor() {}

    __read_tokens(): void {
        // guest key always comes from localstore
        this._guest_key = localStorage.getItem('guest_key') || '';
        if (this.store == 'session') {
            this._cur_token = sessionStorage.getItem('instoken') || '';
            this._prx_token = sessionStorage.getItem('prxtoken') || '';
            this._prx_email = sessionStorage.getItem('prxemail') || '';
            this._b2c_mode = sessionStorage.getItem('b2c') || '';
            
        } else if (this.store == 'local') {
            this._cur_token = localStorage.getItem('instoken') || '';
            this._prx_token = localStorage.getItem('prxtoken') || '';
            this._prx_email = localStorage.getItem('prxemail') || '';
            this._b2c_mode = localStorage.getItem('b2c') || '';
        } else {
            const ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                const c = ca[i].trim();
                if (c.startsWith('instoken=')) this._cur_token = c.substring('instoken='.length, c.length).trim();
                if (c.startsWith('prxtoken=')) this._prx_token = c.substring('prxtoken='.length, c.length).trim();
                if (c.startsWith('prxemail=')) this._prx_email = c.substring('prxemail='.length, c.length).trim();
                if (c.startsWith('b2c=')) this._b2c_mode = c.substring('b2c='.length, c.length).trim();
            }
        }
    }

    __clear_cookie_by_name(name: string, newval?: string) {
        document.cookie = name + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/bb1;';
        document.cookie = name + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/adm;';
        document.cookie = name + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/;';
        document.cookie = name + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT;';
        if (newval) document.cookie = name + '=' + newval +'; path=/';
    }

    __store_tokens() {
        if (!this._guest_key) localStorage.removeItem('guest_key');
        else localStorage.setItem('guest_key', this._guest_key);
        if (this.store == 'session') {
	       if (!this._cur_token) sessionStorage.removeItem('instoken');
             sessionStorage.setItem('instoken', this._cur_token);
            if (!this._prx_token) sessionStorage.removeItem('prxtoken');
            else sessionStorage.setItem('prxtoken', this._prx_token);
            if (!this._prx_email) sessionStorage.removeItem('prxemail');
            else sessionStorage.setItem('prxemail', this._prx_email);
            if (!this._b2c_mode) sessionStorage.removeItem('b2c');
            else sessionStorage.setItem('b2c', this._b2c_mode);
        } else if (this.store == 'local') {
            if (!this._cur_token) localStorage.removeItem('instoken');
            else localStorage.setItem('instoken', this._cur_token);
            if (!this._prx_token) localStorage.removeItem('prxtoken');
            else localStorage.setItem('prxtoken', this._prx_token);
            if (!this._prx_email) localStorage.removeItem('prxemail');
            else localStorage.setItem('prxemail', this._prx_email);
            if (!this._b2c_mode) localStorage.removeItem('b2c');
            else localStorage.setItem('b2c', this._b2c_mode);

        } else {
            this.__clear_cookie_by_name('instoken', this._cur_token);
            this.__clear_cookie_by_name('prxtoken', this._prx_token);
            this.__clear_cookie_by_name('prxemail', this._prx_email);
            this.__clear_cookie_by_name('b2c', this._b2c_mode);
        }
        // console.log('store:', this._cur_token, 'prx:', this._prx_token, 'pre:', this._prx_email);
    }

    switch_back() {
        if (this._prx_token) {
            this._cur_token = this._prx_token;
            this._prx_token = '';
            this._prx_email = '';
            this.__store_tokens();
        } else {
            console.log('switch back ?');
            this._prx_token = '';
            this._prx_email = '';
            this.__store_tokens();
        }
    }

    async switch_user(email: string, cur: string) {
        // if we had already proxied somebody else, switch back to original user
        // and store the state
        if (this._prx_token) {
            this._cur_token = this._prx_token;
            this._prx_token = '';
            this._prx_email = '';
            this.__store_tokens();
        }
        let ret: any = await http.xreq('/api/v1/auth/impersonate/' + encodeURIComponent(email), 'POST', {});
        if (ret?.status == 0) {
			console.log("api/v1/auth/impersonate/")
            this._prx_token = this._cur_token;  // put the current (impersonator) token in prx
            this._cur_token = ret.data.token;
            this._prx_email = cur;
            this.__store_tokens();
            return true;
        } else {
            // proxying failed ...
            console.log('switch:', ret);
            return false;
        }
    }

    get prxmode() {
        return (this._prx_token) ? true : false;
    }

    get token() {
	    if (!this._cur_token && !this._prx_token) this.__read_tokens();
        if (this._cur_token) return this._cur_token;
	
        if (this._prx_token) this.switch_back();
        return this._cur_token;
    }

    setToken(token: string, mode: string) {
	    this._cur_token = token;
        this._b2c_mode = mode;
        this.__store_tokens();
    }

    // set token(value: string) {
    //     this._cur_token = value;
    //     this.__store_tokens();
    // }

    get guest_key() {
        return this._guest_key || '';
    }
    set guest_key(val: string) {
        this._guest_key = val;
        this.__store_tokens();
    }

    updateToken(token: string, proxy: boolean = false) {
	    if (proxy) {
            if (this._prx_email) this._prx_token = token;
        } else {
            this._cur_token = token;
        }
        this.__store_tokens();
    }

    clear() {
	    this._cur_token = '';
        this._prx_token = '';
        this._prx_email = '';
        // this._b2c_mode = '';
        this.__store_tokens();
        localStorage.removeItem('public');
        localStorage.removeItem('zqid');
        localStorage.removeItem('zeid');
    }

    // set b2c_mode(mode: string) {
    //     this._b2c_mode = mode;
    // }
    get b2c_mode() {
        return this._b2c_mode;
    }

    // setB2C(mode: string) {
    //     this._b2c_mode = mode;
    //     // this.__store_tokens();
    // }
}

export const tm = new tokemgr();

function str2ab(str: string) {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i);
    }
    return buf;
}

function getSpkiDer(spkiPem: string) {
    const pemHeader = "-----BEGIN PUBLIC KEY-----";
    const pemFooter = "-----END PUBLIC KEY-----";
    var pemContents = spkiPem.substring(pemHeader.length, spkiPem.indexOf(pemFooter));
    return str2ab(window.atob(pemContents));
}

function _base64(buffer: ArrayBuffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    for (var i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa( binary );
}

export class HttpWrapper {
    private http: HttpClient | null = null;
    public token: string = '';

    public encrypt: boolean = false;
    private publicKey: string = '';
    private aesKeyEncrypted: string = '';
    private aesAlgoKey: any = null;

    constructor() {
    }

    async setHttpClient(http: HttpClient, encrypt?: boolean){
        this.http = http;
        this.encrypt = encrypt ? true : false;
		await this.__load_public_key();
    }

    get _http() {return this.http};

    async __encode_aes_key() {
	   if (this.aesKeyEncrypted || !this.publicKey || !this.encrypt) return;

        const key = new Uint8Array(16);
        window.crypto.getRandomValues(key);

        let pubkey = await window.crypto.subtle.importKey("spki", getSpkiDer(this.publicKey), 
            {name: "RSA-OAEP", hash: {name: "SHA-1"}}, false, ["encrypt"]);

        const encrypted = await window.crypto.subtle.encrypt({name: "RSA-OAEP"}, pubkey, key);
        this.aesKeyEncrypted = _base64(encrypted);
        this.aesAlgoKey = await crypto.subtle.importKey('raw', key, 'aes-gcm', false, ['encrypt', 'decrypt']);
    }

    async __load_public_key() {
        if (this.publicKey) return;
        let pub = localStorage.getItem('public');
        if (pub !== null) {
            if (pub !== '') this.publicKey = pub;
            return;
        }

        try {
            let ret = await this.xreq('/api/v1/auth/publickey');
            if (ret.status == 0) {
                this.publicKey = ret.data;
                localStorage.setItem('public', ret.data);
                await this.__encode_aes_key();
            } else {
                localStorage.setItem('public', '');
            }
        } catch (e) {
            console.log('publicKey:', e);
            localStorage.setItem('public', '');
        }
    }

    async __encrypt_url(url: string, withkey: boolean=false) {
        if (!this.publicKey || !this.aesAlgoKey) return url;
        let parts = url.split('?');
        let nurl = parts.shift()||'';
        let rest = parts.join('&') || '';
        let iv = window.crypto.getRandomValues(new Uint8Array(12));
        let ui8 = new Uint8Array(await window.crypto.subtle.encrypt({name: "AES-GCM", iv: iv}, this.aesAlgoKey, new TextEncoder().encode(rest)));
        let ret = new Uint8Array(iv.length + ui8.length);
        ret.set(iv, 0);
        ret.set(ui8, iv.length);
        nurl += '?e=' + [...new Uint8Array(ret.buffer)].map(x => x.toString(16).padStart(2, '0')).join('');
        if (withkey) nurl += '&ek=' + encodeURIComponent(this.aesKeyEncrypted);
        return nurl;
    }

    async __encrypt_pdata(data: any, options: any) {
	
        if (!this.publicKey || !this.aesAlgoKey) return data;
	    if (typeof data !== 'string') data = JSON.stringify(data);

        options.headers['in-body-token'] = this.aesKeyEncrypted;
        options.headers['Content-Type'] = 'application/x-in-encoded';
        options.headers['content-type'] = 'application/x-in-encoded';
        options.responseType = 'arraybuffer';

        let iv = window.crypto.getRandomValues(new Uint8Array(12));
        let ui8 = new Uint8Array(await window.crypto.subtle.encrypt({name: "AES-GCM", iv: iv}, this.aesAlgoKey, new TextEncoder().encode(data)));

        // we need to send IV as first 12 bytes as insillion icrypto expects it that way
        // the auth tag (16 bytes) gets automatically appended by subtle at the end of ui8
        // just like the way insillion icrypto expects
        //
        let ret = new Uint8Array(iv.length + ui8.length);
        ret.set(iv, 0);
        ret.set(ui8, iv.length);
        return ret.buffer;
        // return _base64(ret);
    }

    async __decrypt_response(ret: any, options: any, url: string) {
		
        if (!options.headers['in-body-token']) return; // we did not encrypt the input
        let ctypes = ret.headers?.headers ? ret.headers?.headers.get('content-type') : [];
        let ctype = ctypes.filter((x: string) => x.indexOf('application/x-in-encoded')>=0);
        if (ctype.length <= 0) return;
        
        // console.log('resp:', ret.body.byteLength, ret.body.constructor.name, url, new Uint8Array(ret.body).slice(0, 30));

        // let adata = new Uint8Array(str2ab(window.atob(ret.body)));
        let adata = new Uint8Array(ret.body);
        let dec = await window.crypto.subtle.decrypt({name: 'AES-GCM', 
            iv: new Uint8Array(adata.slice(0, 12)),
            tagLength: 128
            }, this.aesAlgoKey, new Uint8Array(adata.slice(12)));

        ret.body = new TextDecoder('utf-8').decode(new Uint8Array(dec));
        if (ret.body[0] == '{' || ret.body[0] == '[')
            ret.body = JSON.parse(ret.body);
        return;
    }

    async xreq(url: string, method?: string, data?: any, params?: any, headers?: any, useProxy: boolean = false): Promise<any> {
        let start = performance.now();
        method = (method || 'get').toLowerCase();
        
        let token = useProxy ? tm._prx_token : tm.token;
		if (this.publicKey) await this.__encode_aes_key();
        // if we still don;t have the token, its ok as some APIs may not need the token
        // at all
        
        try {
            const options: {[key: string]: any} = {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma' : 'no-cache',
                    'in-auth-token': token
                },
                observe: 'response'
            }
            if (headers) {
                for (const key in headers) options.headers[key] = headers[key];
            }
            if (method == 'post' || method == 'put') {
                if (url.indexOf('/attach') < 0)
                    data = await this.__encrypt_pdata(data, options);
		    } else if (this.aesKeyEncrypted) {
                options.headers['in-body-token'] = this.aesKeyEncrypted;
                // options.responseType = 'text';
                if (method == 'get' || method == 'delete') url = await this.__encrypt_url(url);
                options.responseType = 'arraybuffer';
            }

            // if (data instanceof FormData) options.headers['Content-Type'] = 'multipart/form-data';
            if (params) options.params = params;

            let ret: any = null;
            if( method === 'post'){
		        ret = await this.http?.post<any>(url, data, options).toPromise();
            } else if( method === 'delete'){
                ret = await this.http?.delete<any>(url, options).toPromise();
            } else if( method === 'get'){
                ret = await this.http?.get<any>(url, options).toPromise();
            } else {
                console.log('unsupported verb ', method);
            }

            if (ret) {
                if (ret.headers.get('set-in-auth-token')) {
                    tm.updateToken(ret.headers.get('set-in-auth-token'), useProxy);
                }

                await this.__decrypt_response(ret, options, url);
                // console.log('xreq:', method, ((performance.now()-start)).toFixed(2), 'ms', url);
                return ret.body;
            }
            throw new Error('Some error.');
        } catch(e: any) {
            console.log('ex', e)
            throw new Error(e.message || e);
        } finally {
        }

    }


};

export const http: HttpWrapper = new HttpWrapper();

/*
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent }  from '@angular/common/http'
import { Observable } from 'rxjs';
@Injectable()
export class InsTokenInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token = tm.token;
        if (token && !req.headers.get('in-auth-token')) {
            console.log('intercepted: ++++++++++++++++++ ' + token);
            const nreq = req.clone({headers: req.headers.set('in-auth-token', token)});
            return next.handle(nreq);
        } else {
            return next.handle(req);
        }
    }
}
*/