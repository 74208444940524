import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, ViewChild, QueryList, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl} from '@angular/forms';
import { IField } from '../../../form.interface';
import { Subscription } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { insapi, luid, Policy, __fix_getter_setter } from 'insapi';
import {Observable} from 'rxjs';
import { PreferencesService } from '../../../../lib/services/preferences.service';


export interface AddonNameValue {
    name: string;
    value: string;
}


@Component({
    selector: 'addon-grid',
    templateUrl: './addon-grid.component.html',
    styleUrls: ['./addon-grid.component.scss']
})
export class AddonGridComponent implements OnInit {
    @ViewChildren('fgs') fgs!: QueryList<any>;
    @Output() onChange = new EventEmitter();
    @Input() field: IField = {field_name: '', type: '', addon_groups: []};
    @Input() parent: any = null;
    @Input() pkeys: any = null;
    @Input() policy!: Policy;
    @Input() data!: any;
    @Input() readonly: boolean = false;

    searchForm = this._formBuilder.group({aoName: '',});
    mode: number = 0;
    alignleft: boolean = true;

    // grp: any = {fields: [], layout: {cls: 'addon-formgroup'}};
    params: any = {};
    opted: any = {};
    namemap: {[key: string]: any} = {};

    addonName: string = '';
    addonNames: {name: string, value: string}[] = [];

    stateCtrl = new FormControl('');
    addonNamesFiltered!: Observable<AddonNameValue[]>;
    containerCls: string = 'addon-container';

    psubscription: Subscription | undefined = undefined;
    constructor(private _formBuilder: FormBuilder, private preferences: PreferencesService) {
        this.addonNamesFiltered = this.stateCtrl.valueChanges.pipe(startWith(''), map(x => this._filterNames(x)),);
        if (this.preferences.vendor?.widgets?.grid?.addon?.mode !== undefined) this.mode = this.preferences.vendor?.widgets?.grid?.addon?.mode || 0;
        if (this.preferences.vendor?.widgets?.grid?.addon?.alignleft !== undefined) this.alignleft = this.preferences.vendor?.widgets?.grid?.addon?.alignleft;
        if (this.mode == 1) this.containerCls = 'addon-container-plain'
    }
    _filterNames(value: string | null) {
        if (!value) return this.addonNames.slice();
        let val = value.toLowerCase();
        return this.addonNames.filter(x => x.name.toLowerCase().includes(val));
    }

    ngOnInit(): void {
        this.psubscription = this.policy?.changeSubject?.subscribe(() => this._policy_changed());
        // this._policy_changed();
    }

    ngOnDestroy(): void {
        if (this.psubscription) this.psubscription.unsubscribe();
        this.psubscription = undefined;
    }

    trackAg(index: number, item: any) {
        return 'ag-' + item.group_name;
    }
    trackAo(index: number, item: any) {
        return item.ukey || item.name;
    }
    trackParam(index: number, item: any) {
        return item.field_name;
    }

    __fix_conditionals(nm: any) {
        if (!nm.ao.iffunc) {
            if (nm.ao.if) nm.ao.iffunc = new Function('data', 'with(data){return  '+nm.ao.if+'}');
            else nm.ao.iffunc = () => true;
        }

        if (!nm.ao.mfunc) {
            if (nm.ao.mandatory) nm.ao.mfunc = new Function('data', 'with(data){return  '+nm.ao.mandatory+'}');
            else nm.ao.mfunc = () => false;
        }

    }

    // __detect_field_change_at(idx: number, field: any) {
    //     if (this.grp.fields.length <= idx) return true;
    //     if (this.grp.fields[idx].field_name != field.field_name) return true;
    //     return false;
    // }

    __fix_param_names(nm: any, index: number, fields: any[]) {
        let changed = false;
        nm.params = [];
        nm.ao.pfields = [];
        for (let p of nm.ao.params) {
            let fldname = this.field.field_name + '.' + index + '.' + p.orig_name;
            
            if (!this.field.fldmap[fldname]) {
                let np = JSON.parse(JSON.stringify(p));
                if (!np.span) np.span = 4;
                if (np.visible === undefined) np.visible = true;
                np.field_name = this.field.field_name + '.' + index + '.' + p.orig_name;
                /*if(!p.getdata)*/ __fix_getter_setter(np);
                // else console.log('setter exists:', p.field_name,p.getdata)
                nm.params.push(np);
                nm.ao.pfields.push(np);
                // fields.push(p);
                changed = true;
                this.field.fldlist.push(np);
                this.field.fldmap[fldname] = np;
            } else {
                nm.params.push(this.field.fldmap[fldname]);
                nm.ao.pfields.push(this.field.fldmap[fldname]);
            }

        }
        let opted: any = { field_name: this.field.field_name + '.' + index + '.' + this.field.addon_def.opted_name,
            type: 'lookup', subtype: 'slider', label: '', visible: true, options: [{name: 'Yes', value: 'Yes'},{name: "No", value: 'No'}], span: 4};
        if (this.alignleft) opted.label = nm.ao.desc;

        let valids = this.policy.addons?.[this.field.field_name] || null;
        if (valids && valids.indexOf(nm.ao.name) < 0) opted.readonly = true;

        __fix_getter_setter(opted);
        
        if (!this.field.fldmap[opted.field_name]) {
            this.field.fldlist.push(opted);
            this.field.fldmap[opted.field_name] = opted;
            changed = true;
        }
        nm.opted = this.field.fldmap[opted.field_name];
        nm.ao.fopted = this.field.fldmap[opted.field_name];
        return changed;
    }

    __prefill_ao_array() {
        let aarr = this.data[this.field.field_name];
        let namefld = this.field.addon_def.addon_name;
        let optdfld = this.field.addon_def.opted_name;

        let nv: {name: string, value: string}[] = [];
        for (let nm in this.field.addon_names) {
            if (!this.namemap[nm]) this.namemap[nm] = {row: null, ao: this.field.addon_names[nm], rfixed: false}
            this.__fix_conditionals(this.namemap[nm]);
            if (nm && this.field.addon_names[nm].desc) nv.push({name: this.field.addon_names[nm].desc, value: nm});
        }
        this.addonNames = nv;
        
        for (let i=0; i<aarr.length; i++) {
            let row = aarr[i];
            if (row.puid === undefined) row.puid = '';
            if (row.added === undefined) row.added = false;
            if (this.parent && row.puid != this.parent.uid) continue;
           
            if (!row[namefld]) continue;

            let nm = this.namemap[row[namefld]];

            // if ((!row.puid && !this.parent) || (row.puid == this.parent.uid)) continue;
            if (!nm) {
                row.is_addon_valid = false;
                console.log('invalid addon:', row);
            } else {
                nm.row = row;
                nm.rfixed = true;
                nm.ao.row = row;
                nm.ao.rfixed = true;
                if (row[optdfld] === 'Yes') row.added = true;
                else row.added = false;
            }            
        }

        //-rr 2023-11-29 addon-names that are not applicable for this user should not be populated here
        //
        // if an entry in namemap is not found in data array, let add it (prefilling)
        /*
        for (let nm in this.namemap) {
            if (this.namemap[nm].rfixed) continue;
            
            let row: any = {[namefld]: this.namemap[nm].ao.name, [optdfld]: 'No', uid: luid(6), puid: this.parent?.uid || ''};
            for (let c of this.field.names) row[c] = row[c] || this.policy.defaults[c] || this.policy.defaults[c+'_tmpl'] || '';
            if (row.puid && this.pkeys) {
                for (let key in this.pkeys) row[this.pkeys[key]] = this.parent[key];
            }
            this.namemap[nm].row = row;
            this.namemap[nm].ao.row = row;
            this.namemap[nm].rfixed = true;
            this.namemap[nm].ao.rfixed = true;
            aarr.push(row);
        }
        */
        let changed = false;
        let fields: any[] = [];
        for (let i=0; i<aarr.length; i++) {
            let row = aarr[i];
            let nm = this.namemap[row[namefld]];
            if (!nm?.ao) continue;
            let tdata = {...this.data, ...(this.parent||{}), ...row};

            // mandatory field, force to Yes
            if (nm.ao.mfunc(tdata)) row[optdfld] = 'Yes';

            // invisible fields gets set to No automatically
            nm.visible = nm.ao.iffunc(tdata);
            if (!nm.visible) row[optdfld] = 'No';
            nm.ao.visible = nm.ao.iffunc(tdata);

            // this is a linked addon and this entry belongs to another parent row
            if (this.parent && row.puid != this.parent.uid) continue;

            row.index = i;
            changed = this.__fix_param_names(this.namemap[row[namefld]], i, fields) || changed; // we need the function called even if change detected in prevs
        }

        // force re-render
        this.field.fldgrp = {...this.field.fldgrp};
        this.field.fldgrp.fields = [...this.field.fldlist];

        // if (changed) this.grp.fields = fields;
        // this.grp.name = this.field.field_name;

        
        // else console.log('no change detected ...', this.field.field_name, this.parent?.location_name)
        // this.grp.name = this.field.field_name;
    }


    async _policy_changed() {
        if (this.field.link_parent && !this.parent) {
            console.log('--- skipping linked addons', this.field.field_name)
            return;
        }
        
        if (!this.policy) return;
        
        if (!this.parent) this.parent = '';
        if (this.parent && this.field?.fldgrp?.layout) this.field.fldgrp.layout.cls = "addon-formgroup-linked";

        this.__prefill_ao_array();
        if (this.fgs) this.fgs.forEach(x => x._changed({}));
    }

    __mark_parents_added(pid: string) {
        if (!pid) return;
        let namefld = this.field.addon_def.addon_name;
        for (let row of this.data[this.field.field_name]) {
            if (this.parent && row.puid != this.parent.uid) continue;
            let raname = row[namefld];
            if (this.namemap[raname].ao.ukey === pid) {
                this.namemap[raname].opted?.setdata(this.data, 'Yes');
                row.added = true;
                console.log('marking parent opted', raname, row);
                this.__mark_parents_added(this.namemap[raname].ao.parent);
                break;
            }
        }
    }

    __mark_children_removed(ao: any) {
        let children = (ao.children||[]).map((x: any) => x.name);
        let namefld = this.field.addon_def.addon_name;
        for (let row of this.data[this.field.field_name]) {
            if (this.parent && row.puid != this.parent.uid) continue;
            let raname = row[namefld];
            if (children.indexOf(raname) >= 0) {
                this.namemap[raname].opted?.setdata(this.data, 'No');
                row.added = false;
            }
        }
    }


    addAddon() {
        let name = this.stateCtrl.value;
        if (!name) return;
        let namefld = this.field.addon_def.addon_name;
        for (let row of this.data[this.field.field_name]) {
            if (this.parent && row.puid != this.parent.uid) continue;
            let raname = row[namefld];
            if (raname == name) {
                this.namemap[raname].opted?.setdata(this.data, 'Yes');
                row.added = true;
                console.log('row:', row.added, this.namemap[raname].opted?.setdata, row);
                this.__mark_parents_added(this.namemap[raname].ao.parent);
                this.onChange.emit(this.field);
                break;
            }
        }
        this.stateCtrl.setValue('');
    }

    optChanged(ev: any, ao: any) {
        let row = ao?.row;
        if (!row) return;
        if (row[this.field.addon_def.opted_name] !== 'Yes') {
            row.added = false;
            this.__mark_children_removed(ao);
        }
        
        this.onChange.emit(ev);
    }

}
