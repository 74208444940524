import { Component, OnInit, HostListener, Input, Output, ViewChild, ElementRef, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';
// import { environment } from './../../../environments/environment';
import { PreferencesService } from '../../lib/services/preferences.service';
import { Directionality } from '@angular/cdk/bidi';
import { animScaleX } from './../../form/form.interface';

@Component({
    selector: 'istepper',
    templateUrl: './istepper.component.compact.html',
    styleUrls: ['./istepper.component.compact.scss'],
    providers: [{ provide: CdkStepper, useExisting: IstepperComponent }],
    animations: [animScaleX]
})
export class IstepperComponent extends CdkStepper implements OnInit {
    @ViewChild('istepper') elem? : ElementRef
    @Input() layout: any = {show: true, type: 1, count: 5, cls: "stepper-nav stepper-nav-5", show_state: false};
    @Output() onNext = new EventEmitter<any>();

    footernav: any = {"prev": "label", "next": "label"};
    constructor(dir: Directionality,
        changeDetectorRef: ChangeDetectorRef,
        _elementRef: ElementRef,
        protected preferences: PreferencesService
        ) {
        super(dir, changeDetectorRef, _elementRef);
    }

    ngOnInit(): void {
        this.responsive();
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
        this.responsive();
    }

    label(idx: number) {
        if (idx >=0 && idx < this.steps.length) {
            let step = null;
            this.steps.forEach((element, index) => {if (idx == index)step = element;});
            let obj = this.steps.find((_, i: number) => i==idx);
            return obj?.label || '';
        }
        return '';
    }

    moveTo(idx: number) {
        this.onNext.emit({current: this.selectedIndex, next: idx, steps: this.steps});
        // if (idx < 0 || idx >= this.steps.length) return;
        // if (!this.hasErrors(this.selectedIndex)) {
        //     if (this.isReady(idx)) this.selectedIndex = idx;
        // }
    }

    hasErrors(idx: number) {
        return this.steps.find((_, i) => idx == i)?.hasError;
    }

    isReady(idx: number) {
        return this.steps.find((_, i) => idx == i)?.optional;
    }

    next() {
        this.onNext.emit({current: this.selectedIndex, next: this.selectedIndex+1, steps: this.steps});
    }

    responsive() {
        let width = window.innerWidth;
        if (this.elem) width = this.elem.nativeElement.getBoundingClientRect().width;
    
        this.layout.type = this.preferences.vendor?.layout?.workflow?.stepper?.type || 3;
        if (this.preferences.vendor?.layout?.workflow?.stepper?.footernav) this.footernav = this.preferences.vendor?.layout?.workflow?.stepper?.footernav;
        let buttonWidth = this.layout.type == 4 ? 145 : 200;

        if (this.layout.type == 1 || this.layout.type == 3 || this.layout.type == 4) {
            this.layout.count = Math.floor(width / buttonWidth);
            this.layout.cls = "";

            // touch scroll gets enabled (set as 20)
            if (width < 780)
                this.layout.count = (this.layout.type == 1 || this.layout.type == 3) ? 20 : 2;

            // console.log('page-width: ', width, 'button count:', this.layout.count);
        } else {
            if (window.innerWidth >= 880 ) this.layout.count = 5;
            else if (window.innerWidth >= 780 ) this.layout.count = 4;
            else this.layout.count = 3;
            this.layout.cls = "stepper-nav stepper-nav-" + this.layout.count;
        }
        console.log('stepper: type', this.layout.type)
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.responsive();
    }
}
