<section class="istepper-container" *ngIf="layout.type === 5">
  <div class="istepper-5" [class]="layout.cls" #istepper>
    <div class="istepper-nav-arrow" matRipple (click)="previous()"><mat-icon>chevron_left</mat-icon></div>
    <div class="istepper-buttons">
      <ng-container *ngFor="let step of steps; let i = index;">
        <ng-template [ngIf]="(selectedIndex<layout.count && i<layout.count) || ((i-selectedIndex) > -layout.count && (i-selectedIndex)<1)">
          <div class="istepper-item" (click)="moveTo(i)" [class.istepper-selected]="selectedIndex==i">
            <div class="istepper-item-top" [class]="'istepper-' + (step.completed ? 'completed': step.optional ? 'inprogress' : 'not-started')"><div>0{{i+1}}</div></div>
            <div class="istepper-item-bottom" [class]="'istepper-' + (step.completed ? 'completed': step.optional ? 'inprogress' : 'not-started')"><div>{{step.label}}</div></div>
          </div>
        </ng-template>
      </ng-container>
    </div>
    <div class="istepper-nav-arrow" matRipple (click)="next()" style="padding-left: 1em;"><mat-icon>chevron_right</mat-icon></div>
  </div>
  <div class="stepper-body" *ngIf="selected">
    <ng-container [ngTemplateOutlet]="selected.content" *ngIf="steps.length>0"></ng-container>
  </div>
  <ng-container *ngTemplateOutlet="stepfooter; context: {steps, selectedIndex}" ></ng-container>
</section>

<!-- type 4-->
<section class="istepper-container" *ngIf="layout.type === 4">
  <div class="istepper-4" [class]="layout.cls" #istepper>
    <div class="istepper-nav-arrow" matRipple (click)="previous()"><mat-icon>chevron_left</mat-icon></div>
    <div class="istepper-buttons">
      <ng-container *ngFor="let step of steps; let i = index;">
        <ng-template [ngIf]="(selectedIndex<layout.count && i<layout.count) || ((i-selectedIndex) > -layout.count && (i-selectedIndex)<1)">
          <div class="istepper-item" (click)="moveTo(i)" [class.istepper-selected]="selectedIndex==i"
            [class]="'istepper-' + (step.completed ? 'completed': step.optional ? 'inprogress' : 'not-started')">
            <div><mat-icon fontSet="material-icons-outlined" *ngIf="step.completed" >check</mat-icon> {{step.label}}</div>
          </div>
        </ng-template>
      </ng-container>
    </div>
    <div class="istepper-nav-arrow" matRipple (click)="next()" style="padding-left: 1em;"><mat-icon>chevron_right</mat-icon></div>
  </div>
  <div class="stepper-body" *ngIf="selected">
    <ng-container [ngTemplateOutlet]="selected.content" *ngIf="steps.length>0"></ng-container>
  </div>
  <ng-container *ngTemplateOutlet="stepfooter; context: {steps, selectedIndex}" ></ng-container>
  <!-- <div class="stepper-footer" >
    <ng-container *ngFor="let step of steps; let i = index;">
      <ng-template [ngIf]="i == selectedIndex-1 || ( i==0 && selectedIndex==0) ">
        <div style="cursor: pointer;"><div *ngIf="selectedIndex!=0" (click)="selectedIndex=i"><mat-icon>navigate_before</mat-icon> 
          <ng-template [ngIf]="footernav.prev==='label'" [ngIfElse]="stepback"> {{step.label}}</ng-template>
          <ng-template #stepback>{{footernav.prev}}</ng-template>
        </div></div>
      </ng-template>

      <ng-template [ngIf]="i == selectedIndex">
        <div></div>
      </ng-template>
      <ng-template [ngIf]="(i == (selectedIndex+1)) || (i==(steps.length-1) && selectedIndex==(steps.length-1))">
        <div style="cursor: pointer;">
          <div *ngIf="selectedIndex != steps.length-1" (click)="selectedIndex=i">
            <ng-template [ngIf]="footernav.next==='label'" [ngIfElse]="stepnext">{{step.label}} </ng-template>
            <ng-template #stepnext>{{footernav.next}}</ng-template>
            <mat-icon>navigate_next</mat-icon> </div>
        </div>
      </ng-template>
    </ng-container>
  </div> -->


</section>

<!-- type 3 -->
<section class="istepper-container" *ngIf="layout.type === 3">
  <div class="istepper-3" [class]="layout.cls" #istepper>
    <div class="istepper-nav-arrow" matRipple (click)="previous()"><mat-icon>chevron_left</mat-icon></div>
    <div class="istepper-buttons">
      <ng-container *ngFor="let step of steps; let i = index;">
        <ng-template [ngIf]="(selectedIndex<layout.count && i<layout.count) || ((i-selectedIndex) > -layout.count && (i-selectedIndex)<1)">
          <div class="istepper-item" (click)="moveTo(i)" [class]="'istepper-item-' + (step.completed ? 'completed': step.optional ? 'inprogress' : 'not-started')">
            <div style="font-size: 0.8em; color: #888;">STEP {{(i+1)}} <mat-icon fontSet="material-icons-outlined" *ngIf="step.completed" >check</mat-icon>
            </div>
            <div class="istepper-desc" [class]="'istepper-label-' + (step.completed ? 'completed': step.optional ? 'inprogress' : 'not-started')" >{{step.label}}</div>
            <div class="istepper-selected" [@animScaleX]="selectedIndex == i" [class.istepper-selected-bg]="selectedIndex==i"></div>
          </div>
          
        </ng-template>
      </ng-container>
    </div>
    <div class="istepper-nav-arrow" matRipple (click)="next()"><mat-icon>chevron_right</mat-icon></div>
  </div>
  <div class="stepper-body" *ngIf="selected">
    <ng-container [ngTemplateOutlet]="selected.content" *ngIf="steps.length>0"></ng-container>
  </div>

  <ng-container *ngTemplateOutlet="stepfooter; context: {steps, selectedIndex}" ></ng-container>
</section>
<!-- type 3 end-->



<!-- type 1 -->
<section class="istepper-container" *ngIf="layout.type === 1">
  <div class="istepper-1" [class]="layout.cls" #istepper>
    <div class="istepper-nav-arrow" (click)="previous()"><mat-icon>chevron_left</mat-icon></div>
    <div class="istepper-buttons">
      <ng-container *ngFor="let step of steps; let i = index;">
        <ng-template [ngIf]="(selectedIndex<layout.count && i<layout.count) || ((i-selectedIndex) > -layout.count && (i-selectedIndex)<1)">
          <div class="istepper-item" (click)="moveTo(i)">
            <div style="display: grid; grid-template-columns: auto 1fr; align-items: center;">
              <div class="istepper-circle" [class.istepper-circle-curr]="selectedIndex === i" matRipple>
                <div class="istepper-circle-inner" [class]="'istepper-circle-' + (step.completed ? 'completed': step.optional ? 'inprogress' : 'not-started')">
                  <span *ngIf="selectedIndex !== i && !step.completed">{{i+1}}</span>
                  <span *ngIf="selectedIndex === i && !step.completed"><mat-icon>engineering</mat-icon></span>
                  <span *ngIf="step.completed"><mat-icon>check</mat-icon></span>
                </div>
              </div>
              <div class="istepper-connector" [class]="'istepper-connector-' + (step.completed ? 'completed': step.optional ? 'inprogress' : 'not-started')"
                *ngIf="i<steps.length-1"></div>
            </div>
            <div class="istepper-desc">
              <div style="font-size: 0.8em; color: #888;">STEP {{(i+1)}}</div>
              <div [class]="'istepper-label-' + (step.completed ? 'completed': step.optional ? 'inprogress' : 'not-started')" >{{step.label}}</div>
              <div *ngIf="layout.show_state" class="istepper-status" [class]="'istepper-' + (step.completed ? 'completed': step.optional ? 'inprogress' : 'not-started')"></div>
            </div>
          </div>
        </ng-template>
      </ng-container>  
    </div>
    <div class="istepper-nav-arrow" (click)="next()"><mat-icon>chevron_right</mat-icon></div>
  </div>

  <div class="stepper-body" *ngIf="selected">
    <ng-container [ngTemplateOutlet]="selected.content" *ngIf="steps.length>0"></ng-container>
  </div>

  <ng-container *ngTemplateOutlet="stepfooter; context: {steps, selectedIndex}" ></ng-container>
  <!-- <div class="stepper-footer" >
    <ng-container *ngFor="let step of steps; let i = index;">
      <ng-template [ngIf]="i == selectedIndex-1 || ( i==0 && selectedIndex==0) ">
        <div style="cursor: pointer;"><div *ngIf="selectedIndex!=0" (click)="selectedIndex=i"><mat-icon>navigate_before</mat-icon> 
          <ng-template [ngIf]="footernav.prev==='label'" [ngIfElse]="stepback"> {{step.label}}</ng-template>
          <ng-template #stepback>{{footernav.prev}}</ng-template>
        </div></div>
      </ng-template>

      <ng-template [ngIf]="i == selectedIndex">
        <div></div>
      </ng-template>
      <ng-template [ngIf]="(i == (selectedIndex+1)) || (i==(steps.length-1) && selectedIndex==(steps.length-1))">
        <div style="cursor: pointer;" [class.no-next]="hasErrors(selectedIndex)">
          <div *ngIf="selectedIndex != steps.length-1" (click)="next()">
            <ng-template [ngIf]="footernav.next==='label'" [ngIfElse]="stepnext">{{step.label}} </ng-template>
            <ng-template #stepnext>{{footernav.next}}</ng-template>
            <mat-icon>navigate_next</mat-icon> </div>
        </div>
      </ng-template>
    </ng-container>
  </div> -->
</section>
<!-- type 1 end-->

<!-- type 2 -->
<section class="istepper-container" *ngIf="layout.type === 2">
  <div [class]="layout.cls" *ngIf="layout.show">
    <div class="stepper-nav-arrow" (click)="previous()"><mat-icon>chevron_left</mat-icon></div>
    <ng-container *ngFor="let step of steps; let i = index;">
      <!-- visible window of [layout.count] tabs -->
      <ng-template [ngIf]="(selectedIndex<layout.count && i<layout.count) || ((i-selectedIndex) > -layout.count && (i-selectedIndex)<1)">
        <div class="stepper-item"  (click)="moveTo(i)">
          <div style="display: grid; grid-template-columns: 1fr 12px 1fr; gap: 3px; height: 12px; align-items: center; padding-top: 6px;">
            <div class="stepper-connector"></div>
            <div class="stepper-connector-bulb" [class.stepper-active-bulb]="selectedIndex == i" 
              [class.stepper-completed-bulb]="step.completed"></div>
            <div class="stepper-connector"></div>
          </div>
          <div class="stepper-item-text" [class.stepper-active]="selectedIndex == i" 
            
            [class.stepper-completed]="step.completed" >{{step.label}}
            <div class="stepper-selected" [@animScaleX]="selectedIndex == i"></div>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <div class="stepper-nav-arrow" (click)="next()"><mat-icon>chevron_right</mat-icon></div>
  </div>

  <div class="stepper-body" *ngIf="selected">
    <ng-container [ngTemplateOutlet]="selected.content" *ngIf="steps.length>0"></ng-container>
  </div>
  <ng-container *ngTemplateOutlet="stepfooter; context: {steps, selectedIndex}" ></ng-container>
  <!-- <div class="stepper-footer" >
    <ng-container *ngFor="let step of steps; let i = index;">
      <ng-template [ngIf]="i == selectedIndex-1 || ( i==0 && selectedIndex==0) ">
        <div style="cursor: pointer;">
            <div *ngIf="selectedIndex!=0" (click)="selectedIndex=i"><mat-icon>navigate_before</mat-icon>
            <ng-template [ngIf]="footernav.prev==='label'" [ngIfElse]="stepback"> {{step.label}}</ng-template>
            <ng-template #stepback>{{footernav.prev}}</ng-template>
            </div>
        </div>
      </ng-template>

      <ng-template [ngIf]="i == selectedIndex">
        <div></div>
      </ng-template>
      <ng-template [ngIf]="(i == (selectedIndex+1)) || (i==(steps.length-1) && selectedIndex==(steps.length-1))">
        <div style="cursor: pointer;">
          <div *ngIf="selectedIndex != steps.length-1" (click)="selectedIndex=i">
            <ng-template [ngIf]="footernav.next==='label'" [ngIfElse]="stepnext">{{step.label}} </ng-template>
            <ng-template #stepnext>{{footernav.next}}</ng-template>
            <mat-icon>navigate_next</mat-icon> </div>
        </div>
      </ng-template>
    </ng-container>
  </div> -->
</section>
<!-- type 2 end-->



<ng-template #stepfooter let-steps="steps" let-selectedIndex="selectedIndex">
  <div class="stepper-footer" >
    <ng-container *ngFor="let step of steps; let i = index;">
      <ng-template [ngIf]="i == selectedIndex-1 || ( i==0 && selectedIndex==0) ">
        <div style="cursor: pointer;"><div *ngIf="selectedIndex!=0" (click)="moveTo(i)"><mat-icon>navigate_before</mat-icon> 
          <ng-template [ngIf]="footernav.prev==='label'" [ngIfElse]="stepback"> {{step.label}}</ng-template>
          <ng-template #stepback>{{footernav.prev}}</ng-template>
        </div></div>
      </ng-template>

      <ng-template [ngIf]="i == selectedIndex">
        <div></div>
      </ng-template>
      <ng-template [ngIf]="(i == (selectedIndex+1)) || (i==(steps.length-1) && selectedIndex==(steps.length-1))">
        <div style="cursor: pointer;" [class.no-next]="hasErrors(selectedIndex)">
          <div *ngIf="selectedIndex != steps.length-1" (click)="next()">
            <ng-template [ngIf]="footernav.next==='label'" [ngIfElse]="stepnext">{{step.label}} </ng-template>
            <ng-template #stepnext>{{footernav.next}}</ng-template>
            <mat-icon>navigate_next</mat-icon> </div>
        </div>
      </ng-template>
    </ng-container>
  </div>
</ng-template>
