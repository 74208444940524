import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { IEndorsement, insapi, Policy } from 'insapi';
import { IField } from '../../form.interface';
import { PreferencesService } from '../../../lib/services/preferences.service';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import moment from 'moment';

@Component({
    selector: 'lib-endorsements',
    templateUrl: './endorsements.component.html',
    styleUrls: ['./endorsements.component.scss']
})
export class EndorsementsComponent implements OnInit {

    field: IField = {field_name: '', type: ''};
    policy!: Policy;
    endorsements: any[] = [];
    pending: any[] = [];
    endorsementId: string = '';
    columns: any[] = [];
    constructor(private router: Router, private preferences: PreferencesService) {
        this.columns = this.preferences.vendor?.widgets.endorsement.columns || [];
    }

    ngOnInit(): void {
        this.__load_endorsements();
    }

    _is_endorsement_allowed(prdEndId: string): boolean {
        let eprods = insapi._end_prds?.[this.policy?.policy?.product_id||''] || [];
        for (let pend of this.pending) {
            let eprd = eprods[pend.prd_endorsement_id];
            if (!eprd) return true;
            let conf = eprd.data.conflict?.pending || {all: true, prevent: []};
            if (conf.all === undefined) return true;
            if (conf.all && conf.prevent.indexOf(prdEndId) >= 0) return false;
            if (conf.allowed?.indexOf(prdEndId) < 0) return false;
        }
        return true;
    }

    async __fix_pending_endorsements() {
        let eprods = insapi._end_prds?.[this.policy?.policy?.product_id||''] || [];
        for (let pend of this.pending) {
            let eprd = eprods[pend.prd_endorsement_id];
            if (eprd) pend.endorsement_name = eprd.endorsement_name;
            if (pend.assigned_to?.startsWith('G0')) {
                pend.assigned_to = insapi.groupName(pend.assigned_to);
            }
            console.log('eprd', eprd);
            pend.c_ts_local = moment.utc(pend.c_ts_local).local().format('YYYY-MM-DD HH:mm:ss.SS');
        }
    }

    async __load_endorsements() {
        for (let col of this.columns) {
            col.pipe_args = null;
            if (!col.pipe && col.type == 'currency') col.pipe = CurrencyPipe;
            if (!col.pipe && col.type == 'decimal') {col.pipe = DecimalPipe; col.pipe_args = '1.0-2'};
        }
        
        if (!this.policy?.product?.product_id || !this.policy?.policy?.policy_id) return;
        
        this.pending = await insapi.__xget('/api/v2/endorsement/pending/' + encodeURIComponent(this.policy.policy.policy_id)) || [];
        let eprods = await insapi.endProducts(this.policy?.product?.product_id);
        let allowed = [];
        for (let ep of eprods) {
            if (!ep.iffunc) {
                if (!ep.data.if) ep.iffunc = () => true;
                else if (ep.data.if[0] == '{') ep.iffunc = new Function('data', 'profile', ep.data.if.substring(1, ep.data.if.length-1));
                else ep.iffunc = new Function('data', 'profile', 'return ' + ep.data.if);
            }
            let pdata = this.policy?.policy?.proposal?.data || this.policy?.policy?.quote?.data || {};
            if (ep.iffunc(pdata, insapi.profile)) {
                if (this._is_endorsement_allowed(ep.prd_endorsement_id)) allowed.push(ep);
                else console.log('skipping ', ep.prd_endorsement_id, ' not allowed');
            } else {
                console.log('skipping ', ep.prd_endorsement_id, ' conditional');
            }
        }
        this.__fix_pending_endorsements();
        this.endorsements = allowed; // await insapi.endProducts(this.policy?.product?.product_id);
    }
    async gotoEndorsement(end: any) {
        let params = {endorsement_id: end.endorsement_id, policy_id: this.policy.policy?.policy_id};
        this.router.navigate(['/endorsement'], {queryParams: params, queryParamsHandling: '', replaceUrl: true});
    }


    async addNewEndorsement() {
        if (!this.endorsementId || !this.policy.policy?.policy_id) return;
        let params = {product_id: this.endorsementId, policy_id: this.policy.policy?.policy_id};
        this.router.navigate(['/endorsement'], {queryParams: params, queryParamsHandling: '', replaceUrl: true});
    }

    async downloadPDF(end: any) {
        if (end.endorsement_cert_id) {
            console.log(end.endorsement_cert_id);
            this.policy.downloadPDF('endorsement', undefined, end.endorsement_cert_id, end.endorsement_id);
        }
    }

    async cancelEndorsement(end: any) {
        if(confirm("Canceling the pending endorsement will mark it as deleted, would you like to proceed?")) {
            await insapi.__xdel('/api/v2/eproposal/' + encodeURIComponent(end.eproposal_id));
            await this.__load_endorsements();
        }
    }
}
