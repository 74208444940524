<div style="display: flex; flex-direction: column; flex: 1; height: 100%;">
    <div class="mini-bar" [class.mini-bar-hidden]="!visible" [class.mini-bar-visible]="visible" [@slideAnim]>
        <div *ngIf="visible" (click)="visible=false; accordion.closeAll()"><mat-icon>chevron_right</mat-icon></div>
        <div *ngIf="!visible" (click)="visible=true"><mat-icon>chevron_left</mat-icon></div>
        <mat-accordion #accordion="matAccordion">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title><mat-icon (click)="visible=true">app_registration</mat-icon> &nbsp; Sumbmissions</mat-panel-title>
                </mat-expansion-panel-header>
                <submissions [policy]="policy"></submissions>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title><mat-icon (click)="visible=true">format_list_bulleted</mat-icon> &nbsp; Summary</mat-panel-title>
                </mat-expansion-panel-header>
                <div [innerHTML]="summary"></div>
            </mat-expansion-panel>
            <!-- <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title><mat-icon (click)="visible=true">stacked_line_chart</mat-icon> &nbsp; Risk distribution</mat-panel-title>
                </mat-expansion-panel-header>            
            </mat-expansion-panel> -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title><mat-icon (click)="visible=true">calendar_month</mat-icon> &nbsp; Calendar</mat-panel-title>
                </mat-expansion-panel-header>
                Period
                <div style="text-align: center; font-size: 1.1em;">
                    {{data.policy_start_date | idate}} - {{data.policy_end_date | idate}}
                </div>
                <div style="display: grid; grid-template-columns: auto 1fr auto;">
                    <div style="text-align: right; font-size: .8em;" (click)="showTimeline()">Timeline</div>
                    <div style="text-align: right; font-size: .8em;" (click)="showCalendar()">More ...</div>
                </div>
                
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="policy?.product?.data.master_policy_enabled === 'Yes'">
                <mat-expansion-panel-header>
                    <mat-panel-title><mat-icon (click)="visible=true">star_half</mat-icon> &nbsp; Master policy</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="mini-summary">
                    <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 1em;">
                        <div *ngFor="let limit of limits" class="ledger-entry" (click)="showLedger(ledgers[limit.value])">
                            <div class="subtile">{{limit.name}}</div>
                            <div class="subdata">{{ledgers[limit.value]?.balance}}</div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>

        </mat-accordion>
    </div>
    <div style="flex-grow: 1;" (click)="visible=false; accordion.closeAll()"></div>
</div>