<div *ngIf="enabled">
    <div *ngIf="policy.policy?.payment?.schedule" style="padding: 1em 1em;">
        Payment schedule
        <table class="gtable">
            <tr>
                <th>Due date</th>
                <th style="text-align: right;">Amount</th>
                <th style="text-align: right;">Late fee</th>
                <th style="text-align: right;">Total</th>
                <th style="text-align: right;">Received</th>
            </tr>
            <tr *ngFor="let sch of policy.policy?.payment?.schedule">
                <td>{{sch.first_date | idate}}</td>
                <td style="text-align: right;">{{sch.amount | currency}}</td>
                <td style="text-align: right;">{{sch.late_fee | currency}}</td>
                <td style="text-align: right;">{{sch.total_amount | currency}}</td>
                <td style="text-align: right;">{{sch.total_recvd | currency}}</td>
            </tr>
            <tr>
                <td></td>
                <td style="text-align: right;">{{totals.amount | currency}}</td>
                <td style="text-align: right;">{{totals.fee | currency}}</td>
                <td></td>
                <td style="text-align: right;">{{totals.paid | currency}}</td>
            </tr>
        </table>

        <button mat-button (click)="cancelInstallments()">Cancel installments</button>
    </div>
    <div *ngIf="!policy.policy?.payment?.schedule" class="inst">
        <div style="display: grid; grid-template-columns: 100px 1fr 1fr 1fr; align-items: center;">
            <div class="form-field">
                <mat-form-field style="width: 100%;">
                    <mat-select placeholder="No Of Installments" [(ngModel)]="emiCount" (selectionChange)="periodChange()">
                        <mat-option *ngFor="let opt of emiOptions" [value]="opt">{{opt}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-field">
                <div *ngIf="instdef.custom_down_payment">
                    <mat-form-field style="width: 100%;">
                        <input matInput [(ngModel)]="downPayment" placeholder="Down payment amount">
                    </mat-form-field>
                </div>
                <div *ngIf="!instdef.custom_down_payment">
                    Down payment: {{(instdef.down_payment||0) * (amount||0) / 100 | currency}}
                </div>
            </div>
            <div>
                <div *ngIf="instdef.custom_schedule">
                    <table>
                        <tr *ngFor="let c of schedule; let i=index; trackBy: schTrackByFn">
                            <td>{{i+1}}</td>
                            <td><input type="text" [(ngModel)]="schedule[i]" (input)="schChanged()"></td>
                        </tr>
                        <tr>
                            <td>Total</td>
                            <td>{{schTotal}}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="form-field">
                <button mat-button (click)="addInstallments()">Enable installments</button>
            </div>
        </div>
    </div>
</div>
