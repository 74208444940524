import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from "@angular/forms";
import { IField } from '../../form.interface';
import { excelDateToDate } from 'insapi';
import moment from 'moment';

@Component({
    selector: 'i-date',
    template: `
    <div *ngIf="field && field.inline && control" style="padding: 1em;">
        <div style="text-align: center;">{{field.label}}</div>
        <calendar-selector [formControl]="control"></calendar-selector>
    </div>
    <mat-form-field class="margin-top" [formGroup]="group" *ngIf="field && !field.inline">
        <mat-label [ngClass]="field.labelclass">{{field.label}}</mat-label>
        <input matInput [matDatepicker]="dpicker" [formControlName]="field.field_name" [placeholder]="field.label||''"
            [min]="min(data, {}, ctx)" [max]="max(data, {}, ctx)"
            (dateChange)="onChange.emit(field)" [attr.data-iid]="field.field_name" [title]="field.title ?? '' | generic2: field.title_format">
        <span matSuffix *ngIf="showTime" style="font-size: .95em; padding-right: .1em; padding-top: 2px;">{{data.policy_start_time}}</span>
        <mat-datepicker-toggle matSuffix [for]="dpicker"></mat-datepicker-toggle>
        <mat-datepicker #dpicker></mat-datepicker>
        <mat-hint></mat-hint>
        <ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
            <mat-error *ngIf="control && validation.validator && control.hasError(validation.validator.name)">{{field.errors || (field.field_name | capitalize) + validation.message}}</mat-error>
        </ng-container>
        <mat-error *ngIf="control && control.hasError('ssValidator')">{{control.errors?.ssValidator?.msg}}</mat-error>
        <div class="input-sub-text" *ngIf="field.subtext && field.subtext.varname">{{field.subtext.desc}} {{data[field.subtext.varname] | generic2: field.subtext.pipe}}</div>
    </mat-form-field>
    `,
    styles: [
    ]
})
export class DateComponent implements OnInit {
    field: IField = {field_name: '', type: ''};
    group!: FormGroup;
    data!: any;
    control!: FormControl;
    min: Function = () => undefined;
    max: Function = () => undefined;
    showTime: boolean = false;
    ctx = {moment, excelDateToDate};
    @Output() onChange = new EventEmitter();
    constructor() { 
    }

    __period_to_date(period: string) {
        if (typeof period !== 'string') period = '' + period;
        let allowed = ['y', 'Q', 'M', 'w', 'd', 'h', 'm', 's', 'ms'];
        let unit = period[period.length-1];
        if (allowed.indexOf(unit) < 0) unit = 'd';
        else period = period.substring(0, period.length-1);
        console.log('period:', period, unit)
        if (unit == 'h') return moment().add(+period, unit).toDate();
        if (unit === 'y' || unit === 'Q' || unit === 'M' || unit === 'w' || unit === 'd' || unit == 'h')
            return moment().startOf('day').add(+period, unit).toDate();
        return undefined;
    }

    __period_function(stmt: string) {
        let expr = "{\n";
        if (!isNaN(+stmt)) stmt = stmt + 'd';    // default to days (if just a number is given)
        if (stmt[0] === '{') {
            expr += "let func = (data, profile, ctx) => " + stmt + "\n";
        } else {
            expr += "let func = (data, profile, ctx) => '" + stmt + "';\n";
        }
        expr += `
        let allowed = ['y', 'Q', 'M', 'w', 'd', 'h', 'm', 's', 'ms'];
        let period = func(data, profile, ctx);
        if (period instanceof Date) return period;
        if (period instanceof ctx.moment) return period.toDate();
        //console.log('dpf:', '`+this.field.field_name+`', period);
        let unit = period[period.length-1];
        if (allowed.indexOf(unit) < 0) unit = 'd';
        else period = period.substring(0, period.length-1);
        if (unit != 'h') return ctx.moment().startOf('day').add(+period, unit);
        return ctx.moment().add(+period, unit).toDate();
        }`;
        try {
            return new Function('data', 'profile', 'ctx', expr);
        } catch (e) {
            console.log('date-expr', stmt, expr);
            console.log('date func', e);
            return () => null;
        }

    }


    ngOnInit(): void {
        let min = this.field.min ?? undefined;
        let max = this.field.max ?? undefined;
        if (min !== '' && min !== undefined) this.min = this.__period_function('' + min);
        if (max !== '' && min !== undefined) this.max = this.__period_function('' + max);
        if (this.field.field_name === 'policy_start_date') this.showTime = true;
    }
}
