import { Directive, ComponentFactoryResolver, SimpleChange, Input, OnInit, ViewContainerRef, Output, EventEmitter, DoCheck } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { Subscription } from 'rxjs';
import { IField } from '../form.interface';
import { components } from './components';

@Directive({
    selector: '[ifield]'
})
export class IFieldDirective implements OnInit {

    @Input() field!: IField;
    @Input() group!: FormGroup;
    @Input() data: any;
    @Input() policy: any;
    @Input() readonly: boolean = false;
    @Output() onAction = new EventEmitter<any>();
    @Output() onChange = new EventEmitter<any>();
    componentRef: any;
    csubscription: Subscription | null = null;
    asubscription: Subscription | null = null;
    origMarkAsTouched:  any = null;
    constructor(private resolver: ComponentFactoryResolver, private container: ViewContainerRef) { }

    ngOnInit() {
        if (!components[this.field.type]) return;
        // const factory = this.resolver.resolveComponentFactory(components[this.field.type]);
        // this.componentRef = this.container.createComponent(factory);
        this.componentRef = this.container.createComponent(components[this.field.type]);
        this.componentRef.instance.field = this.field;
        this.componentRef.instance.group = this.group;
        this.componentRef.instance.policy = this.policy;
        this.componentRef.instance.readonly = this.readonly;
        this.componentRef.instance.data = this.data;
        if (!this.field.title_format) this.field.title = this.field.title || '';
        // bubble up child change notification to the parent host
        if (this.componentRef.instance.onChange) {
            if (this.csubscription) this.csubscription.unsubscribe();
            this.csubscription = this.componentRef.instance.onChange.subscribe((ev: any) => this.onChange.emit(ev));
        }

        // bubble up the onAction event to the parent host
        if (this.componentRef.instance.onAction) {
            if (this.asubscription) this.asubscription.unsubscribe();
            this.asubscription = this.componentRef.instance.onAction.subscribe((ev: any) => this.onAction.emit(ev));
        }

        if (this.group)
            this.componentRef.instance.control = this.group.get([this.field.field_name]);
        if (!this.componentRef.instance.control) console.log('no control:', this.field.field_name, this.componentRef.instance.control, this.group)
        else {
            if (!this.origMarkAsTouched) this.origMarkAsTouched = this.componentRef.instance.control.markAsTouched;
            this.componentRef.instance.control.markAsTouched = (opts: any) => {
                this.origMarkAsTouched.call(this.componentRef.instance.control, opts);
                this.policy?.__ui_set_field_touched(this.field.field_name)
            }
        }
    }

    ngOnDestroy(): void {
        if (this.csubscription) this.csubscription.unsubscribe();
        this.csubscription = null;
        if (this.asubscription) this.asubscription.unsubscribe();
        this.asubscription = null;
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        // console.log('field-change: ', Object.keys(changes))
        if (this.componentRef) {
            if (changes['data']) this.componentRef.instance.data = this.data;
            if (changes['field']) this.componentRef.instance.field = this.field;
            if (changes['policy']) this.componentRef.instance.policy = this.policy;
            if (changes['readonly']) this.componentRef.instance.readonly = this.readonly;
            if (changes['group']) this.componentRef.instance.group = this.group;
            
            if (this.group) this.componentRef.instance.control = this.group.get([this.field.field_name]);
        }

        if (this.componentRef && typeof this.componentRef._reload === 'function')
            this.componentRef._reload(changes);
    }
    // ngDoCheck() {
    //     console.log('do-check:', this.field?.field_name, this.componentRef?.instance.control?.touched)
    // }
}
