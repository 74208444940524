import { Component, OnInit, EventEmitter, Input, SimpleChange, Output, ViewChildren, QueryList, 
    TemplateRef, ViewContainerRef, ElementRef, ContentChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { IField, IFieldGroup, ILayout, __get_data, __set_data } from '../../form/form.interface';
import { insapi, IProfile, excelDateToDate, is_num, deepMerge, NameValue, __eval_func, __fix_getter_setter } from 'insapi';
import { ssValidator, numberValidator } from '../ss-validator.directive';
import { IFieldDirective } from '../i-field/i-field.directive';
import { OverlayConfig, OverlayRef, Overlay, ConnectionPositionPair } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';


const validators: any = {
    mandatory: {validator: Validators.required, message: ' value must be provided'},
    number: {validator: numberValidator(), message: 'Number expected'},
};

import { trigger, state, style, transition, animate } from '@angular/animations';
import { Subscription } from 'rxjs';
export const animHeight = trigger('grow', [
    transition('void => *', [style({ height: '{{realHeight}}px', opacity: 0 }), animate('.5s ease')], {params: { realHeight: 0 }}),
    transition('* => void', [style({transform: 'scaleY(1)', opacity: 0}),animate('300ms', style({transform: 'scaleY(0)', opacity: 1}))])
]);
const layout = {cls: 'page-container-m', grids: 12, style: {}, caption: {show: true, style: {}, description: {show: true,style: {},}}};

@Component({
    selector: 'field-group',
    templateUrl: './field-group.component.html',
    styleUrls: ['./field-group.component.scss'],
    animations: [animHeight]
})
export class FieldGroupComponent implements OnInit {
    @ViewChildren('fcs',  {read: IFieldDirective}) fcs!: QueryList<IFieldDirective>;
    @ContentChild('fgcaption') fgcaption!: TemplateRef<any>;
    @ContentChild('fgcontent') fgcontent!: TemplateRef<any>;
    
    @Output() onAction = new EventEmitter<any>();
    @Output() onChange = new EventEmitter<any>();
    @Output() onStatusChange = new EventEmitter<any>();
    @Input() data: any = {};
    @Input() policy: any = {};
    @Input() fieldGroup!: IFieldGroup;
    @Input() readonly: boolean = false;
    @Input() layout: ILayout = {grids: 8, cls: 'page-container-m'};
    @Input() disableAnim: boolean = false;
    profile: IProfile | null = null;

    form!: FormGroup;
    fieldCount: number = 0;
    fieldDone: number = 0;
    progress: number = 0;

    fgChangeSubscription: any = null;
    fgStatusChangeSubscription: any = null;
    prevFields: any = null;
    dirty: boolean = false;
    hasDependants = false;
    visible = false;
    subscription: Subscription | null = null;
    histOverlayRef: OverlayRef | undefined;
    histField: any = null;
    fmap: {[key: string]: IField} = {};


    conditionals: IField[] = [];
    unconditionals: IField[] = [];
    defconditionals: IField[] = [];
    rdoconditionals: IField[] = [];
    vldconditionals: IField[] = [];
    deepFields: {[key: string]: any} = {};
    dateFields: {[key: string]: any} = {};

    trackField = (index: number, item: any) => item.uid || item.field_name;

    constructor(private fb: FormBuilder, private overlay: Overlay, private viewContainerRef: ViewContainerRef) {
        this.subscription = insapi.profileSubject.subscribe((profile: IProfile|null) => {this.profile = profile;});
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        if (changes['data']) {
            // console.log('fg: data changed ...');
        }
        if (changes['fieldGroup']) {
            if (this.prevFields !== this.fieldGroup.fields) {
                // console.log('fg: changed, fields changed ...', this.fieldGroup.name, this.fieldGroup.fields.length);
                this._build();
            }
        }
        if (changes['readonly'] && this.form && this.readonly) {
            for (let field of this.fieldGroup.fields) {
                let control = this.form.get([field.field_name]);
                if (control) control.disable();
            }
        }
    }
    
    ngOnDestroy(): void {
        if (this.fgChangeSubscription) this.fgChangeSubscription.unsubscribe();
        if (this.fgStatusChangeSubscription) this.fgStatusChangeSubscription.unsubscribe();
        this.fgStatusChangeSubscription = null;
        if (this.subscription) this.subscription.unsubscribe();
        this.subscription = null;
    }

    _build() {
        if (!this.fieldGroup.fields) console.log('invalid-fg:', this.fieldGroup)
        let form = this.fb.group({});
        if (this.fgStatusChangeSubscription) this.fgStatusChangeSubscription.unsubscribe();
        this.fgStatusChangeSubscription = form.statusChanges.subscribe((status) => this.onStatusChange.emit({name: this.fieldGroup.name, status}));

        this.fieldCount = 0;
        this.layout = deepMerge({}, layout, this.layout);
        this.hasDependants = false;
        if (this.layout?.caption) {
            this.layout.caption.show = +this.layout?.caption.show;
        }

        if (this.fieldGroup.if) {
            this.fieldGroup.ifFunc = __eval_func(this.fieldGroup.if); //this._evalFunc(this.fieldGroup.if, null);
        }

        this.conditionals = [];
        this.unconditionals = [];
        this.defconditionals = [];
        this.rdoconditionals = [];
        this.vldconditionals = [];

        for (let field of this.fieldGroup.fields) {
            this.fmap[field.field_name] = field;
            field.ifFunc = () => true;
            field.uid = this.fieldGroup.name + '.' + field.field_name;
            // if (field.type == 'button') console.log('field:', field);
            if (!field.span) field.span = 4;
            if (!field.props) field.props = {};
            
            if (field.type == 'buttons') {
                for (let btn of field.buttons||[]) {
                    if (btn.if) {
                        btn.ifFunc = this._evalFunc(btn.if, btn);
                        this.conditionals.push(btn);
                    } else {
                        btn.visible = true;
                    }
                    if (btn.url) btn.urlFunc = this._urlFunc(btn.url);
                    if (btn.source && btn.source.url) btn.urlFunc = this._urlFunc(btn.source.url);
                    // console.log('btn:', btn.urlFunc)
                }
            }

            field.urlFunc = null;
            if (field.url) field.urlFunc = this._urlFunc(field.url);
            if (field.source && field.source.url) field.urlFunc = this._urlFunc(field.source.url);
            if (field.urlFunc) this.hasDependants = true;

            if (field.type == 'plan') this.hasDependants = true;

            if (field.if) {
                field.ifFunc = this._evalFunc(field.if, field);
                this.conditionals.push(field);
            } else {
                field.visible = true;
                this.unconditionals.push(field);
            }

            if (field?.min?.[0] == '{' || field?.max?.[0] == '{') {
                this.vldconditionals.push(field);
            } 

            if (field.defaultif) {
                field.defifFunc = this._evalFunc(field.defaultif, field);
                this.defconditionals.push(field);
                // console.log('defaultif', field.field_name, field.defaultif, field.defifFunc);
            }

            if (typeof field.readonly === 'boolean') {
                field.rdofunc = () => field.readonly;
            } else if (typeof field.readonly === 'string') {
                
                let ro = field.readonly.toLowerCase();
                if (ro === 'true') field.rdofunc = () => true;
                else if (ro === 'false') field.rdofunc = () => false;
                else {
                    field.rdofunc = this._evalFunc(field.readonly, field);
                    this.rdoconditionals.push(field);
                }
            } else {
                field.rdofunc = () => false;
            }

            if (field.type == 'button' || field.type == 'file') continue;
            if (field.field_name.indexOf('.')>0) this.deepFields[field.field_name] = field;

            if (field.type === 'date') this.dateFields[field.field_name] = field;

            if (!field.getdata || !field.setdata) __fix_getter_setter(field);

            //-rr 2022-10-30 let value = this.data[field.field_name] || '';
            // let value = __get_data(this.data, field) || '';
            let value = field.getdata(this.data) || '';
            if (field.type === 'date'/* && is_num(value)*/) {
                let dt = excelDateToDate(value);
                value = dt ? dt.toDate() : '';
            }
            let disabled = this.readonly || field.rdofunc(this.data, this.profile, this.policy);
            const control = this.fb.control({value, disabled}, this._validations(field));
            form.addControl(field.field_name, control);
            if (field.type != 'hidden') this.fieldCount ++;
        }
        if (this.fgChangeSubscription) this.fgChangeSubscription.unsubscribe();
        this.fgChangeSubscription = form.valueChanges.subscribe((data) => {
            this._update_data_change(data);
        });
        
        this.form = form;
        this.prevFields = this.fieldGroup.fields;
        this._update_data_change(this.data);
    }

    __apply_visibility(fields: IField[], changed: any) {
        let visible = this.unconditionals.length !== 0;
        for (let fld of this.conditionals) {
            fld.visible = fld.ifFunc.call(this, this.data, this.profile, this.policy);
            if (!visible && fld.visible && fld.type != 'button') visible = true;
        }
        for (let fld of this.defconditionals) {
            if (!this.data[fld.field_name] && fld.defifFunc.call(this, this.data, this.profile, this.policy) && !this.form.controls[fld.field_name].dirty) {
                if (!fld.defvalfunc) {
                    if (fld.default?.[0] == '"' || fld.default?.[1] == "'" || fld.default?.indexOf('data.') >= 0)
                        fld.defvalfunc = this._evalFunc(fld.default, fld);
                    else
                        fld.defvalfunc = () => fld.default;
                }

                this.data[fld.field_name] = fld.defvalfunc(this.data, this.profile, this.policy);
                changed[fld.field_name] = this.data[fld.field_name];
            }
        }
        return visible;
    }

    _eval_conditionals() {
        let changed: any = {};
        let visible = this.__apply_visibility(this.fieldGroup.fields, changed);
        if (this.fieldGroup.if && this.fieldGroup.ifFunc) {
            this.visible = this.fieldGroup.ifFunc.call(this, this.data, this.profile, this.policy);
        } else {
            this.visible = visible;
        }
        
        if (Object.keys(changed).length > 0) {
            this.form.patchValue(changed, {emitEvent: false, onlySelf: true});
        }

        this._updateDependants();
    }

    _update_data_change(data: any) {
        this.fieldDone = 0;
        for( let field of this.fieldGroup.fields) {
            if (field.field_name.indexOf('_tmpl')<0) field.has_history = this.policy?.ehistory?.[field.field_name] ? true : false;
            field.history_sorted = false;

            if (field.type == 'grid') {
                let ctl = this.form.get(field.field_name);
                if (ctl?.dirty) {
                    this.dirty = true;
                    this.policy.dirty = true;
                }
            } else if (field.type != 'button' && field.type != 'file'/* && field.type != 'grid'*/) {
                if (!data.hasOwnProperty(field.field_name)) continue;
                if (field.type != 'hidden' && data[field.field_name]) this.fieldDone ++;

                //-rr 2022-10-30
                let __v = __get_data(this.data, field);

                let dv = data[field.field_name];
                if ( dv != __v) {
                    // multi-select arrays
                    if (dv instanceof Array && __v instanceof Array) {
                        if (dv.length === __v.length) {
                            let changed = false;
                            for (let i=0; i<__v.length; i++) if (dv[i] != __v[i]) {changed=true; break;}
                            if (!changed) continue;
                        }
                    }

                    // date controls
                    if (field.type === 'date') {
                        let nv = excelDateToDate(__v);
                        let ev = excelDateToDate(dv);
                        if (ev && nv?.isSame(ev)) continue;
                    }

                    if (dv || __v) {
                        this.dirty = true;
                        this.policy.dirty = true;
                    }
                    // this.data[field.field_name] = data[field.field_name];
                    __set_data(this.data, field, data[field.field_name]);
                }
            }
        }
        if (this.fieldCount > 0) this.progress = Math.round(this.fieldDone / this.fieldCount);
        this._eval_conditionals();
    }

    _validations(field: IField) {
        field.validations = (field.validate||'').split('|').map((x: string) => validators[x]).filter(Boolean);
        let v = field.validations.map(x => x.validator);
        v.push(ssValidator(field.field_name, this.policy));
        return v.length>0 ? Validators.compose(v) : null;
    }

    onSubmit(ev: any) {
        console.log('... submit', ev);
    }

    __reset_dirty() {
        this.dirty = false;
        this.form.markAsPristine();
    }

    _urlFunc(expr: string) {
        try{
            if (expr.startsWith("data.")) {
                let parts = expr.split('.');
                if (parts.length == 2) {
                    expr = "(" + expr + ")";
                    return new Function('data', 'policy', "return typeof "+expr+" === 'string' ? ("+expr+" ? "+expr+".split(','):[]) : "+expr);
                } else {
                    // console.log("url: data.:", "return (("+parts[0]+"?."+parts[1]+")||[]).map(x => x['"+parts[2]+"'])");
                    return new Function('data', 'policy', "return (("+parts[0]+"?."+parts[1]+")||[]).map(x => x['"+parts[2]+"'])");

                }
            } else if (expr.startsWith("'")) {
                return new Function('data', 'policy', "with(data){return ("+expr+");}");
            } else if (expr.indexOf('{{') < 0) {
                if (expr.indexOf('\'') >= 0 || expr.indexOf('\"') >= 0)
                    return new Function('data', 'policy', "return "+expr+"");
                return new Function('data', 'policy', "return '"+expr+"'");
            } else {
                return new Function('data', 'policy', `
                    let re = new RegExp(/{{(.*?)}}/g);
                    let match = re.exec(`+expr+`);
                    let ret = `+expr+`;
                    while (match != null) {
                        ret = ret.replace(new RegExp( match[0], 'g'), data[match[1]] || '');
                        match = re.exec(`+expr+`);
                    }
                    return ret;
                `);

                // // pat replace {{}} with values before evaluating
                // let re = new RegExp(/{{(.*?)}}/g);
                // let match = re.exec(expr);
                // let ret = expr;
                // while (match != null) {
                //     ret = ret.replace(new RegExp( match[0], 'g'), this.data[match[1]] || '');
                //     match = re.exec(expr);
                // }
                // return ret;
            }
        } catch (e) {
            console.log('_expr: ', expr, e);
            return '';
        }

    }

    _expr(expr: string) {
        try{
            if (expr.startsWith("'")) {
                let func = new Function('data', "with(data){return ("+expr+");}");
                return func.call(this, this.data);
            } else {
                // pat replace {{}} with values before evaluating
                let re = new RegExp(/{{(.*?)}}/g);
                let match = re.exec(expr);
                let ret = expr;
                while (match != null) {
                    ret = ret.replace(new RegExp( match[0], 'g'), this.data[match[1]] || '');
                    match = re.exec(expr);
                }
                return ret;
            }
        } catch (e) {
            console.log('_expr: ', expr, e);
            return '';
        }
    }

    _evalFunc(expr: string, field: IField | null) {
        if (expr[0] === '{') {
            return new Function('data', 'profile', 'policy', expr.substring(1, expr.length-1) );
        } else {
            expr = expr.replace(/this\.mod\.data/g, 'data');
            expr = expr.replace(/this\.mod/g, 'policy');
            expr = expr.replace(/this\.data/g, 'data');
            let _expr = "try{return ("+expr+");}catch(e){/*console.log('if func: " + (field?field.field_name:'') + "', e.message);*/ return false;}";
            // console.log('expr: ', _expr);
            return new Function('data', 'profile', 'policy', "with(data){" + _expr +"}");
        }
    }

    _revalidate(name: string = '') {
        if (!this.form.controls[name]) return;
        // console.log('_revalidate:', name, this.form.controls[name].touched ? 'touched':'not touched');
        if (this.form.controls[name].touched)
            this.form.controls[name].updateValueAndValidity();
    }

    __enable_disable_field(key: string) {
        let control = this.form.get([key]);
        if (!control) return;

        let disabled = typeof this.policy?.overrides?.[key]?.values === 'string' ||
                       this.policy?.overrides?.[key]?.values instanceof Array ||
                       this.fmap[key]?.rdofunc(this.data, this.profile, this.policy)
        if (disabled) {
            control.disable({onlySelf: true, emitEvent: false});
        } else {
            control.enable({onlySelf: true, emitEvent: false});
        }
    }

    _changed(values?: NameValue|undefined) {
        if (this.readonly) return; //-rr 2023-09-17 no need to apply changes to already completed stages

        // ** do not mutate values
        values = values ? structuredClone(values) : {};

        // changes in one form group does not trigger iffunc eval on others
        // comment this if causes performance issue
        this._eval_conditionals();

        for (let fld of this.rdoconditionals) this.__enable_disable_field(fld.field_name);

        if (!values) values = {};

        // deep fields may not get the changes into the control through above
        // skip the date fields as they are taken care of next
        //
        for (let key in this.deepFields) {
            if (!this.dateFields[key])
                values[key] = __get_data(this.data, this.deepFields[key]);
        }

        // date fields in string/number format should be converted to 
        // Date object
        for (let fname in this.dateFields) {
            let v = __get_data(this.data, this.dateFields[fname]);
            if (typeof v === 'string' || typeof v === 'number') {
                let dt = excelDateToDate(String(v));
                if (dt) values[fname] = dt.toDate();
            }
        }

        let keys = Object.keys(values);
        //-rr 2023-Aug-21 added emitEvent: false, causes all fields to be updated
        if (keys.length > 0) this.form.patchValue(values, {emitEvent: false, onlySelf: false});
        
        for (let key of keys) {
            this.__enable_disable_field(key);
        }
    }

    _markCondMandatory(name: string) {
        if (!this.form.controls[name]) return -1;
        if (!this.form.controls[name].hasError('required')) {
            this.form.controls[name].setErrors({required: true});
        }
        return 0;
    }

    _markError(name: string, error?: string, force?: boolean): number {
        if (!this.form.controls[name]) return -1;
        if (!force && !this.form.controls[name].touched) {
            // console.log(name, this.form.controls[name].touched ? 'touched':'not touched');
            return 1;
        }
        if (!this.form.controls[name].hasError('ssValidator')) {
            console.log('marking error', name, error, 'policy:', this.policy?.errMap?.[name]);
            this.form.controls[name].setErrors({'ssValidator': {msg: error || ""}});
            if (force) this.form.controls[name].markAsTouched();
        }
        return 0;
    }
    _resetError(names: {[key: string]: string}) {
        for (let name in names) {
            if (this.form.controls[name]) {
                this.form.controls[name].setErrors(null);
            }
        }
        for (let vc of this.vldconditionals) {
            if (this.form.controls[vc.field_name]) this.form.controls[vc.field_name].setErrors(null);
        }
    }
    _updateDependants() {
        if (!this.hasDependants || !this.fcs) return; // no field has source.url filled in

        // we may have to implement a counter here to prevent indirect recursive calls
        // to the same function
        //
        this.fcs.forEach((x: any) => {
            if (typeof x.componentRef?.instance?._update_dependents == 'function') x.componentRef.instance._update_dependents();
        });
    }

    showHistory(ovlay: TemplateRef<any>, elemRef: ElementRef<any>, field: IField) {
        this.histField = field;
        // const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();
        const positionStrategy = this.overlay
            .position().flexibleConnectedTo(elemRef).withPositions([
                new ConnectionPositionPair({ originX: 'start', originY: 'bottom' },{ overlayX: 'start', overlayY: 'top' },),
                new ConnectionPositionPair({ originX: 'start', originY: 'bottom' },{ overlayX: 'end', overlayY: 'top' },),
                new ConnectionPositionPair({ originX: 'start', originY: 'top' },{ overlayX: 'start', overlayY: 'bottom' },),
                new ConnectionPositionPair({ originX: 'start', originY: 'top' },{ overlayX: 'end', overlayY: 'bottom' },),
             ]).withPush(false);

        if (!this.histOverlayRef) {
            const scrollStrategy = this.overlay.scrollStrategies.reposition();
            this.histOverlayRef = this.overlay.create({
                hasBackdrop: true,
                scrollStrategy, positionStrategy,
            });
            this.histOverlayRef.backdropClick().subscribe(() => {
                this.histOverlayRef?.detach()
            });
        }

        if (this.histOverlayRef.hasAttached()) this.histOverlayRef.detach();
        this.histOverlayRef.updatePositionStrategy(positionStrategy);
        this.histOverlayRef.attach(new TemplatePortal(ovlay, this.viewContainerRef));
    }

    gotoEndorsement(end: any) {
        if (!end?.endorsement_id) return;
        this.onAction.emit({field_name: 'load_endorsement', endorsement_id: end.endorsement_id, policy_id: this.policy?.policy?.policy_id})
    }
}
