import { Component, OnInit, ViewChild, ViewChildren, QueryList, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { insapi, IPolicy, IWorkflow, Policy, NameValue, InsapiService, deepMerge, _clone, ExcelForm, IProfile, http, _capitalize } from 'insapi';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CdkStepper } from '@angular/cdk/stepper';
import { IField, ILayout, FieldGroupComponent, DatePipe, PreferencesService, CsvImportComponent } from '../../public-api';

import { EmailPDFComponent } from '../email.component';
// import { environment } from './../../../environments/environment';

@Component({
    selector: 'app-flow',
    templateUrl: './flow.component.html',
    styleUrls: ['./flow.component.scss']
})
export class FlowComponent implements OnInit {
    @ViewChild('cdkStepper') cdkStepper!: CdkStepper;
    @ViewChildren('fgs') fgs!: QueryList<FieldGroupComponent>;

    options: {[key: string]: any} = {};
    policy?: Policy;
    workflow?: IWorkflow | null;
    stages: any[]  = [];
    downloads: any[] = [];
    documents: any[] = [];
    docgroups: any = {};
    nstpType: string = '';
    assignedTo: string = '';
    benefit: IField | null = null;
    condMandatory: {[key: string]: string} = {};
    custDocType: string = '';
    catalogs: any[] = [];

    __list_fields: any[] | null = null;
    __pdfs: any = {};
    __payment_pending: string = '';
    profile: IProfile | null = null;
    subscription: Subscription | null = null;

    constructor(protected insapiService: InsapiService, 
        protected preferences: PreferencesService,
        protected router: Router,
        protected activatedRoute: ActivatedRoute,
        public dialog: MatDialog) { 
        this.subscription = insapi.profileSubject.subscribe((profile) => {
            this.profile = profile;
            if (!profile) this.policy = undefined;
        });
        if (preferences.vendor) {
            if (!preferences.vendor.policy?.strings) preferences.vendor.policy.strings = {};
            if (!preferences.vendor.policy?.strings.catalog) preferences.vendor.policy.strings.catalog = 'Catalog';
            this.options.dateAsString = preferences.vendor.dateAsString;
            if (preferences.vendor.policy?.referral?.notesOnPolicy !== undefined) this.options.notesOnPolicy = preferences.vendor.policy?.referral?.notesOnPolicy;
        }
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        if (this.subscription) this.subscription.unsubscribe();
        this.subscription = null;
    }

    @HostListener('window:resize', ['$event']) onResize(ev: any) {
        this._set_layout_width();
    }

    _set_layout_width() {
        let iw = window.innerWidth;
        for (let stage of this.stages) {
            stage.form.layout.width = (stage.form.layout.maxWidth > iw) ? '100%' : stage.form.layout.maxWidth + 'px';
        }
    }

    _fix_wf_layout() {
        if (!this.workflow) return;
        this.workflow.layout = deepMerge({
            grids: 8,
            stepper: {show: true, count: 5, cls: "stepper-nav stepper-nav-5"}
        }, this.workflow.layout);
    }

    _update_document_status() {
        let details = this.policy?.endorsement ? (this.policy?.endorsement?.document ?this.policy?.endorsement?.document.details : []) : (this.policy?.policy?.document? this.policy?.policy?.document.details:[]);
        
        for (let doc of this.documents) {
            doc.document_details_id = null;
            for (let det of details||[]) {
                if (det.status == 1) continue;
                if (det.document_type == doc.document_type) {
                    doc.document_details_id = det.document_details_id;
                    break;
                }
            }
        }

        for (let dt in this.docgroups) this.docgroups[dt].uploaded = [];
        
        for (let det of details||[]) {
            if (det.status == 1) continue;
            if (this.docgroups[det.document_type]) {
                this.docgroups[det.document_type].uploaded.push(det);
            }
        }
    }

    _update_group_list_options(grp: any, lists: any[]) {
        if (!this.policy) return;
        for (let i=0; i<grp.fields.length; i++){
            let fld = grp.fields[i];
            if (this.policy.lists[fld.field_name]) {
                fld.options = this.policy.lists[fld.field_name]; //.map(x => ({name: x, value: x}));
                if (fld.props?.sort_by) {
                    if (fld.props?.sort_desc == true) {
                        fld.options?.sort((a: any, b: any) => a[fld.props?.sort_by] > b[fld.props?.sort_by] ? -1 : +1);
                    } else {
                        fld.options?.sort((a: any, b: any) => a[fld.props?.sort_by] > b[fld.props?.sort_by] ? +1 : -1);
                    }
                }
                
                if (fld.type != 'lookup' && fld.type != 'checkbox') {
                    fld.type = 'lookup';
                    grp.fields[i] = JSON.parse(JSON.stringify(fld));
                    for (let key in fld) if (typeof fld[key] === 'function') grp.fields[i][key] = fld[key];
                    // grp.fields[i].ifFunc = fld.ifFunc;
                }
                lists.push(fld);
            }
            if (fld.type == 'grid' && fld.group) this._update_group_list_options(fld.group, lists);
        }
    }

    /*
    __init_pdfs() {
        let base = this.policy?.endorsement || this.policy?.policy;
        if (!base) return;
        this.__pdfs = {};
        for (let stage of this.stages) {
            if (!stage.module) continue;
            
            this.__pdfs[stage.module.name] = {id: 'cert_id', desc: stage.module.name, modname: stage.module.name};
            for (let pdf of stage.pdf || []) {
                this.__pdfs[pdf.name] = {id: pdf.id_field, desc: pdf.desc || pdf.name, modname: stage.module.name};
            }
        }
    }
    */

    __add_pdf(name: string, pdfdef: any) {
        if (!this.__pdfs[name]) this.__pdfs[name] = [];
        for (let pd of this.__pdfs[name]) if (pd.id == pdfdef.id) return;
        this.__pdfs[name].push(pdfdef);
    }

    __init_list_options() {
        if (this.__list_fields != null && this.__list_fields.length > 0) return;    // already initialized
        this.__list_fields = [];
        this.__pdfs = {};
        for (let stage of this.stages) {
            if (!stage.module) continue;
            
            this.__add_pdf(stage.module.name, {id: 'cert_id', desc: stage.module.pdf_label || stage.module.name, modname: stage.module.name});
            for (let pdf of stage.pdf || []) {
                let pddef: any = {id: pdf.id_field, desc: pdf.desc || pdf.name, modname: stage.module.name, if: pdf.if};
                
                if (pddef.if) pddef.ifFunc = this._evalFunc(pddef.if, null);
                else pddef.ifFunc = () => true;
                this.__add_pdf(pdf.name, pddef);
            }
            for(let grp of stage.form.groups) this._update_group_list_options(grp, this.__list_fields);
        }

    }

    _update_list_options() {
        this.__init_list_options();
        let base = this.policy?.endorsement || this.policy?.policy;
        if (!base) return;
        //-rr Dec-13-2022 optimized repeated recursive calling of list fields into a linear call
        for (let fld of this.__list_fields||[]) {
            fld.options = this.policy?.lists[fld.field_name];
            if (fld.props?.sort_by) {
                if (fld.props?.sort_desc == true) {
                    fld.options?.sort((a: any, b: any) => a[fld.props?.sort_by] > b[fld.props?.sort_by] ? -1 : +1);
                } else {
                    fld.options?.sort((a: any, b: any) => a[fld.props?.sort_by] > b[fld.props?.sort_by] ? +1 : -1);
                }
            }
        }

        let downloads = this.__pdfs;
        this.downloads = [];
        for (let m in downloads) {
            for (let dnld of downloads[m]) {
                // let dnld = downloads[m];
                if (!base[dnld.modname]) continue;

                let idfld = dnld.id;
                let desc = dnld.desc;
                let idval = base[dnld.modname][idfld];
                if (!idval && base[dnld.modname].data) idval = base[dnld.modname].data[idfld];

                if (dnld.ifFunc && !dnld.ifFunc(base[dnld.modname].data||{}, this.profile, this.policy?.policy)) {
                    console.log('download link skipped ', dnld.if);
                    continue;
                }
                
                if (idval) this.downloads.push({mod: dnld.modname, id: idval, name: m, desc: desc});

                // multi quote
                if (m=='quote' && base.quote?.data.choices?.length > 0) {
                    for (let i=0; i<base.quote.data.choices.length; i++) {
                        idval = base.quote?.data?.[idfld+'-'+i];
                        if (idval) this.downloads.push({mod: dnld.modname, id: idval, name: m+'-'+(i+1), desc: m+'-'+(i+1)});
                    }
                }
            }
        }

        if (!this.preferences.vendor?.widgets?.catalog?.showInMinibar) {
            this.catalogs = Object.values(this.policy?.policy?.quote?.data.catalog || this.policy?.product?.catalog || {});
        } else {
            this.catalogs = [];
        }
        
        // hide only if explicitly asked to
        if (this.preferences.vendor?.widgets?.catalog?.show === false) this.catalogs = [];

        this._update_policy_details();
    }

    _update_policy_details() {
        let base = this.policy?.endorsement || this.policy?.policy;
        if (!base) return;

        if (base.assigned_to == insapi.profile?.email) this.assignedTo = 'Assigned to You';
        else if (base.assigned_to && base.assigned_to.startsWith('G0')) this.assignedTo = 'Group: ' + insapi.groupName(base.assigned_to);
        // this.profile?.all_groups[base.assigned_to]?.group_name || base.assigned_to;
        else this.assignedTo = base.assigned_to || '';
    }

    async _update_stage_status() {
        let base = this.policy?.endorsement || this.policy?.policy;
        let basemod = this.policy?.endorsement ? 'endorsement' : 'policy';
        if (!base) return;

        let options: any[] = [];
        if (this.preferences?.vendor?.cart?.deposits) {
            await insapi.loadDeposits();
            for (let dep of this.profile?.deposits||[]) {
                options.push({name: dep.entity_id + ' [' + dep.amount+ ']', value: dep.entity_id});
            }
        }
        // for (let stage of this.stages) {
        for (let s=0; s<this.stages.length; s++) {
            let stage = this.stages[s];
            let mod = stage.module?.name;

            if (this.workflow?.wf_script.grouped) {
                if (s == 0 || base[basemod+'_no']) stage.ready = true;
                if (mod && (base[mod+'_id'] || mod == 'quote'  || mod == 'eproposal')) stage.state = "allowed";
            } else {
                // first module (quote|eproposal) is always ready
                if (mod && (base[mod+'_id'] || mod == 'quote'  || mod == 'eproposal')) stage.ready = true;
                if (mod == basemod) stage.ready = base[basemod+'_no'] ? true : false;
            }


            stage.readonly = false;
            if (base[basemod+'_no']) stage.readonly = true;
            else if (mod && base[mod+'_no']) stage.readonly = true;
            else if (base[mod] && base[mod].status == 'completed') stage.readonly = true;
            else if (basemod == 'policy' && this.policy?.statuses[mod] == 'completed') stage.readonly = true; 
            else if (['qnstp','pnstp','nstp'].includes(mod) && this.policy?.statuses[mod] == 'completed') stage.readonly = true; //qnstp stage marked as readonly after nstp auto approval

            if (mod == 'policy' /*|| mod == 'endorsement'*/) stage.readonly = false;
            // if the stage is readonly, mark all the lookups as strings 
            if (stage.readonly) {
                for (let grp of stage.form.groups) {
                    for (let fld of grp.fields) if (fld.type == 'lookup') fld.type = 'string';
                }
            }

            stage.completed =  (stage.readonly && stage.ready);

            for (let acts of stage.actions?.fields||[]) {
                if (acts.field_name == 'actions') {
                    for (let fld of acts.buttons) {

                        // if the user has more than one deposit, provide a drop down to select deposit
                        //
                        if (fld.field_name == 'collect_cash' && options.length > 0) {
                            fld.options = options;
                        }
                        if (fld.field_name == 'collect_cash' || fld.field_name == 'add_to_cart') {
                            if (base.cart_id && base.payment?.total_recvd >= base.payment?.total_amount) fld.readonly = true;
                        }
                        if (fld.field_name == 'finalize_payment') {
                            if (!base.payment?.total_amount || base.payment?.total_recvd < base.payment?.total_amount) fld.readonly = true;
                        }

                        if (fld.field_name == 'collect_cash') {
                            fld.no_disable = false;
                            if (base.payment?.total_recvd < base.payment?.total_amount) fld.no_disable = true;
                        }
                    }
                }
            }
        }
    }

    async _prepare_excel_forms(policy: Policy) {
        if (!this.workflow) return;

        let pipes = {date: DatePipe};
        let ef = new ExcelForm(pipes);
        if (this.preferences.vendor?.label) {
            ef.label = this.preferences.vendor?.label;
        }

        let stages: any[] = await ef._prepare_excel_forms(policy, this.workflow, {uw: this.profile?.is_underwriter?true:false, vendor: this.preferences.vendor});
        this.benefit = ef.benefit;
        this.nstpType = ef.nstpType;
        this.condMandatory = ef.condMandatory;
        
        this.documents = JSON.parse(JSON.stringify(await policy.documents()));
        this.docgroups = this.documents.reduce((a: any, x: any) => {a[x.document_type]={def: x, uploaded: []}; return a;}, {});
        
		for (let doc of this.documents) {
            doc.ifFunc = () => true;
            if (doc.visibility_condition) {
                doc.ifFunc = this._evalFunc(doc.visibility_condition, null);
            }
			if (!this.docgroups[doc.document_type])
                this.docgroups[doc.document_type] = {def: doc, uploaded: []};
        }

        this.policy = policy;
        this._update_document_status();
        this.stages = stages;
        this._set_layout_width();
        this._update_stage_status();
        this._move_to_inprogress();
        this._update_list_options();
        //this.__init_pdfs();
        

        // console.log('stages: ', stages)
        if (this.policy.policy?.['qnstp']?.details) {
            this.policy.policy['qnstp'].details = this.policy.policy['qnstp'].details.sort((a: any, b: any) => a.u_ts > b.u_ts ? -1 : 1);
        }
        if (this.policy.policy?.['pnstp']?.details) {
            this.policy.policy['pnstp'].details = this.policy.policy['pnstp'].details.sort((a: any, b: any) => a.u_ts > b.u_ts ? -1 : 1);
        }
        if (this.policy.endorsement?.['enstp']?.details) {
            this.policy.endorsement['enstp'].details = this.policy.endorsement['enstp'].details.sort((a: any, b: any) => a.u_ts > b.u_ts ? -1 : 1);
        }

        // console.log('stages:', JSON.parse(JSON.stringify(stages)));
    }

    
    _evalFunc(expr: string, field: IField | null) {
        expr = expr.replace(/this\.mod\.data/g, 'data');
        expr = expr.replace(/this\.mod/g, 'policy');
        expr = expr.replace(/this\.data/g, 'data');
        let _expr = "try{return ("+expr+");}catch(e){/*console.log('if func: " + (field?field.field_name:'') + "', e.message);*/ return false;}";
        // console.log('expr: ', _expr);
        return new Function('data', 'profile', 'policy', "with(data){" + _expr +"}");
    }

    __move_to_inprogress() {
        if (!this.cdkStepper || !this.policy) return;
      
        let base = this.policy?.endorsement || this.policy?.policy;
        let basemod = this.policy?.endorsement ? 'endorsement' : 'policy';
        if (!base) return;
        
        let ip;
        if (this.policy?.endorsement)
            ip = this.policy.endorsement.stage_status.filter(x => x.status == 'inprogress').map(x => x.stage);
        else
            ip = this.policy?.policy?.stage_status.filter(x => x.status == 'inprogress').map(x => x.stage);
         

        let cur = this.stages[this.cdkStepper.selectedIndex].module?.name;
        if (ip?.includes(cur)) return;

        let idx = 0;
        if (base[basemod+'_no']) {
            idx = this.stages.length - 1;
            this.cdkStepper.steps.last.completed=true;
        } else {
            let start = this.preferences?.vendor?.layout?.workflow?.stepper?.start_at === 'first';
            for (let i=0; i<this.stages.length; i++) {
                if (ip?.includes(this.stages[i].module?.name)) {idx = i; if (start) break;}
            }
        }
        if (this.cdkStepper.steps.length > idx) this.cdkStepper.selectedIndex = idx;
    }

    _move_to_inprogress() {
        // if (!this.policy?.policy?.stage_status) return;
        setTimeout(() => this.__move_to_inprogress(), this.cdkStepper ? 0 : 500);
    }

    async uploadDocument(ev: any, docdef: any) {
        let files = ev?.target?.files || [];
        if (files.length == 0) return;
        
        let filesize = files[0].size/1024/1024;
        if (docdef.document_size > 0 && filesize > docdef.document_size)
            return insapi.showMessage("File should be smaller than "+docdef.document_size+" MB",1) 
        else if (filesize > 20)
            return insapi.showMessage("File should be smaller than 20 MB",1);

        let desc = docdef.document_type == 'others' ? this.custDocType : docdef.document_desc;
        await this.policy?.__uploadDocument(docdef.document_type, desc, files[0]);
        this._update_document_status();
        this.custDocType = '';
        if (ev && ev.target && ev.target.value) ev.target.value = null;
        if (ev.target) ev.target.files = null;
    }

    async downloadDocument(docdef: any, doc: any) {
        await this.policy?.downloadDocument(doc?.document_details_id || docdef.document_details_id);
    }

    async removeDocument(docdef: any) {
        await this.policy?.__deleteDocument(docdef.document_details_id);
        this._update_document_status();
    }

    async downloadPDF(dnld: any) {
        await this.policy?.downloadPDF(dnld.mod, undefined, dnld.id);
    }

    async emailPDF(dnld: any) {
        const dialogRef = this.dialog.open(EmailPDFComponent, {data: {policy: this.policy, mod: dnld.mod}, panelClass: 'login-panel'});
        dialogRef.afterClosed().subscribe(result => {console.log('result: ', result)});
    }

    async _redirectTo(ev: any, data: any) {
        if (!ev.urlFunc) return;
        let url: string = '';
        try {
            url = ev.urlFunc.call(this, data||{}, this.policy?.endorsement || this.policy?.policy);
        } catch (e: any) {
            console.log('could not redirect, failed to evaluate URL using', ev.url);
            console.log(e.message);
        }
        
        console.log('btn-url:', url);
        if (url) {
            
            let method = ev.source?.method;
            if (method == 'GET' || method == 'POST')
            {
                let params:any = {};
                if (method == 'POST' && ev.source.params) {
                    params = ev.source.params;
                    // if (ev.source.params.policy) //post policy data only when param key added in workflow.
                    //     params["policy"] = this.policy?.endorsement || this.policy?.policy;

                    for (let param of Object.keys(params)) {
                        if (param == 'policy') {
                            params[param] = this.policy?.endorsement || this.policy?.policy;
                        } else {
                            let regExp = /(?:{{(.*)}})/g;
                            let regExVal: any = regExp.exec(String(params[param]));
                            if (regExVal && regExVal.length > 1) {
                                let evalFun = this._evalFunc(regExVal[1], null);
                                params[param] = evalFun({}, this.profile, this.policy?.endorsement || this.policy?.policy);
                            }
                        }
                    }

                }
                let ret = await insapi.__api_wrapper(async() => await insapi.xreq(url, method, params, undefined, 1));
                if (ev.source?.reload == 2 && ret?.status == 0)
                    location.reload();
                else if (ev.source?.reload && ret?.status == 0) {
                    if (this.policy?.endorsement?.data?.endorsement_id)
                        await this.policy?.__load_endorsement(this.policy?.endorsement?.data?.endorsement_id);
                    else if (this.policy?.policy?.policy_id) 
                        await this.policy?.__load(this.policy?.policy?.policy_id, false);
                }
                this.__move_to_inprogress();
                this._update_stage_status();
                if (ret?.txt) insapi.showMessage(ret?.txt,0);
            }
            else {
                url = url + (url.indexOf('?')>=0 ? '&' : '?') + 'token=' + encodeURIComponent(insapi.getToken());
                window.location.href = await http.__encrypt_url(url, true);
            }
        }
    }

    __reset_dirty() {
        this.fgs?.forEach(x => x.__reset_dirty());
        // if (this.fgs) this.fgs.find(x => x.dirty = false);
    }

    __check_form() {
        if (!this.preferences.vendor?.clientSideValidation) return true;
        if (!this.fgs) return true;
        let inv = this.fgs.find(x => !x.form.valid);
        if (!inv) return true;

        for (let name in inv.form.controls) {
            if (inv.form.controls[name].invalid) inv.form.controls[name].updateValueAndValidity();

            if (inv.form.controls[name].invalid) {
                let msg = _capitalize(name) + ' value must be provided';
                for (let err in inv.form.controls[name].errors||{}) {
                    if (err == 'ssValidator') msg = inv.form.controls[name].errors?.[err].msg;
                }
                if (insapi.messageFunc) insapi.messageFunc(msg,1);
                inv.form.controls[name].markAllAsTouched();
                return false;
            }
        }
        return true;
    }

    _get_page_error_fields(errMap: any, stageIdx : number) {
        let pf: any = [];
        if (stageIdx < 0 || stageIdx >= this.stages.length) return [];
        for (let grp of this.stages[stageIdx].form.groups) {
            let flds:any = [];
            for (let fld of grp?.fields) {
                if (fld.type == 'grid') flds = [fld.field_name, ...flds, ...fld.group?.fields?.map((x:any) => x.field_name?.substring(0,x.field_name?.length-5))];
                else flds.push(fld.field_name);
            }
            pf = [...pf, ...flds];
        }
        return pf.filter((element:any) => errMap[element]);
    }


    __page_fields(moveTo: boolean) {
        if (!this.preferences.vendor?.pageWiseError || !this.cdkStepper) return null;
        let errMap: any = {};
        for (let err of this.policy?.errors||[]) {
            let e: any = err;
            errMap[e.name] = true;
            if (e.arr) errMap[e.arr] = true;
        }
        // let errArr = this.policy?.errors.map((e:any) => e.name) || [];

        let pageFields = this._get_page_error_fields(errMap, this.cdkStepper.selectedIndex); //check for current selected page
        if (pageFields.length > 0) return pageFields;

        // check if errors are found in other pages 
        for (let idx=0; idx < this.stages.length; idx++) {
            if (!this.stages[idx]?.ready || this.stages[idx]?.readonly || idx == this.cdkStepper.selectedIndex) continue;
            pageFields = this._get_page_error_fields(errMap, idx);
            if (pageFields?.length > 0) {
                if (moveTo) this.cdkStepper.selectedIndex = idx;
                return pageFields;
            }
        }
        return null;
    }

    // __mark_stages_with_errors() {
    //     for (let stage of this.stages) stage.hasError = false;
    //     if (!this.policy?.errors || this.policy?.errors.length <= 0) return;

    //     for (let stage of this.stages) {
    //         for (let err of this.policy?.errors||[]) {
    //             let e: any = err;
    //             if (stage.fldnames[e.name] || stage.fldnames[e.arr]) {
    //                 stage.hasError = true;
    //                 break;
    //             }
    //         }
    //     }
    // }

    //
    // zero or more errors found due to an action performed
    // errors in current page will be given higher priority over errors in other pages
    //
    // name: the field-name that triggered an action (save, premium-calc etc)
    // popup: always show the first error (unhandled) message as pop-up
    // 
    async _handle_errors(name?: string, popup?: boolean) {
        if (name) this.fgs.forEach(x => x._revalidate(name));           // force re-apply the field validations here
        for (let fg of this.fgs) fg._resetError(this.condMandatory);    // reset all conditional fields error stage (they will get reset if error still found)
        if (!this.policy?.errors || this.policy?.errors.length <= 0) return;    // no errors

        let pageFields = this.__page_fields(popup ?? false);

        // errors generated by excel are of type string
        let toshow = null;
        let xlmsg: string[] = this.policy.errors.filter((x: any) => x && typeof x === 'string') as string[];
        let errors: {[key: string]: any}[] = this.policy.errors.filter((x: any) => x && typeof x !== 'string') as {[key: string]: any}[];

        // error messages should be forced for output fields if the current field is the source of this output field
        if (!popup && name) {
            console.log('error-force:', name)
            for (let err of errors) {
                if (err.srcfields?.split(',').map((x: string) => x.trim()).includes(name)) {
                    toshow = err.msg;
                    break;
                }
            }
            console.log('error-force-show:', toshow)
        }

        console.log('handle-errors:', errors);
        console.log('pageFields:', pageFields);

        let others: any = {};
        for (let err of errors) {
            let e: any = err;
            if (e?.devErr) {insapi.showMessage(e?.msg, 0); continue;}

            others[e.name] = err;
            for (let fg of this.fgs) {
                // reset conditional mandatory whether it is in current page or any other page
                if (this.condMandatory[e.name] == e.name) fg._markCondMandatory(e.arr || e.name);
                let ret = fg._markError(e.arr || e.name, e.msg, popup);

                // show the error only if the field is in the current page
                if (ret === 0 && popup && (pageFields === null || pageFields?.includes(e.name) || pageFields?.includes(e.arr))) {
                    toshow = toshow || e.msg;
                    break;
                }

                // field is part of this fg, mark it as handled
                if (ret == 0 || ret == 1) {delete others[e.name]; break;}

                // field is not part of this fg, other fgs may handle it
            }
        }

        // we have found a message in the current page to show, 
        if (toshow) return insapi.showMessage(toshow, 0);

        // show excel generated errors if doing an action
        if (popup) {
            if (xlmsg.length > 0) return insapi.showMessage(xlmsg[0], 0);
            
            // output errors and other kind of errors
            let keys = Object.keys(others);
            if (keys.length > 0 && others[keys[0]].msg) return insapi.showMessage(others[keys[0]].msg, 0);
        }

    }

    /*
    async _handle_errors_orig(name?: string, force?: boolean) {
        if (name) this.fgs.forEach(x => x._revalidate(name));    // force re-apply the field validations here

        // reset all conditional mandatory fields so that they will get cleared before set again
        // 
        for (let fg of this.fgs) {
            fg._resetError(this.condMandatory);
        }


        if (!this.policy?.errors || this.policy?.errors.length <= 0) return;

        
        // pick and choose the top most error to be displayed as toast here
        let show = true;
        let xlmsg: string | null = null;
        let errors: any = {};
        
        let curPageErrFields: any = [];
        if (this.preferences.vendor?.pageWiseError && this.cdkStepper) {
            let errArr = this.policy?.errors.map((e:any) => e.name) || [];
            curPageErrFields = this._get_page_error_fields(errArr, this.cdkStepper.selectedIndex); //check for current selected page
            
            if (curPageErrFields?.length <= 0) { // checks for error in next pages
                for (let idx=0; idx < this.stages.length; idx++) {
                    if (!this.stages[idx]?.ready || this.stages[idx]?.readonly || idx == this.cdkStepper.selectedIndex) continue;
                    curPageErrFields = this._get_page_error_fields(errArr, idx);
                    if (curPageErrFields?.length > 0) {
                        if (force) this.cdkStepper.selectedIndex = idx;
                        break;
                    }
                }
            }
            console.log("curPageErrFields", this.cdkStepper.selectedIndex, curPageErrFields)
        }
        
        for (let err of this.policy?.errors||[]) {
            let e: any = err;

            if (!err) continue;

            if (e?.devErr) {
                insapi.showMessage(e?.msg, 0);
                console.log('DEV-ERROR:', e?.devErr);
                continue;
            }

            if (typeof err === 'string') {
                if (!xlmsg) xlmsg = err;
                continue;
            }
            
            errors[e.name] = err;
            
            for (let fg of this.fgs) {
                if (curPageErrFields.length>0 && !curPageErrFields.includes(e.name)) {
                    delete errors[e.name]; continue;   // not in current viewing page
                }
                if (this.condMandatory[e.name] == e.name) fg._markCondMandatory(e.arr || e.name);

                //-rr 2023-03-21 added e.arr to show grid validation errors
                let ret = fg._markError(e.arr || e.name, e.msg, force);
                if (ret === 0) {
                    delete errors[e.name]; // valid field
                    if (show && force) {insapi.showMessage(e.msg, 0); show = false;}
                    break;
                }
                if (ret === 1) { // not touched
                    delete errors[e.name]; // valid field
                }
            }
        }

        // non-field based errors
        if (!force) return; // do not show if we are not doing an action (like save)

        if (xlmsg && show) return insapi.showMessage(xlmsg, 0); // Excel messages have higher priority
        
        // workflow messages
        let names = Object.keys(errors);
        if (show && names.length > 0) return insapi.showMessage((errors[names[0]] as any).msg, 0);
    }
    */
    __update_router() {
        if (this.policy?.endorsement?.endorsement_id) {
            if (this.router.url.indexOf(this.policy?.endorsement?.endorsement_id) >= 0) return;
            this.router.navigate([], {relativeTo: this.activatedRoute,
                queryParams: { endorsement_id: this.policy.endorsement.endorsement_id, product_id: null},
                queryParamsHandling: 'merge', replaceUrl: true});
        } else if (this.policy?.policy?.policy_id) {
            if (this.router.url.indexOf(this.policy?.policy?.policy_id) >= 0) return;
            console.log('changing router: ', this.router.url)
            this.router.navigate([], {relativeTo: this.activatedRoute,
                queryParams: { policy_id: this.policy.policy.policy_id, product_id: null},
                queryParamsHandling: 'merge', replaceUrl: true});
        }
    }

    async saveQuote(finalize: boolean, subProdId: string='') {
        if (!this.policy?.policy) return;
        let changeRoute = this.policy.policy.policy_id ? false : true;
        
        if (finalize) {
            if (!this.__check_form()) return;
            let dirty = this.fgs.find(x => x.dirty);

            // unmodified quote with QNSTP module enabled (and approved) must not save when finalizing
            // otherwise it will be considered as change post approval and QNSTP approval will be reversed to
            // unapproved state
            //
            let save = dirty ? true : false;
            if (this.policy.policy?.qnstp?.nstp_status !== 'approved') save = true;
            if (this.policy.policy?.quote.data._ready != 1) save = true;

            if (!save) {
                await this.policy.__finalize('quote');
            } else {
                await this.policy.__saveAndFinalizeQuote(this.policy.policy.quote.data);
            }
            this._move_to_inprogress();
            this._update_stage_status();
        }
        else await this.policy.__saveQuote(this.policy.policy.quote.data);
        if (subProdId) {
            await this.policy.__addSubProduct(subProdId);
        }

        if (changeRoute) this.__update_router();
        // if (changeRoute && this.policy.policy.policy_id) {
        //     this.router.navigate([], {relativeTo: this.activatedRoute,
        //         queryParams: { policy_id: this.policy.policy.policy_id, product_id: null},
        //         queryParamsHandling: 'merge', replaceUrl: true});
        // }
        this.__reset_dirty();
        this._handle_errors(undefined, true);
    }
    
    async saveProposal(finalize: boolean) {
        if (!this.policy?.policy) return;

        if (finalize) {
            if (!this.__check_form()) return;
            let dirty = this.fgs.find(x => x.dirty);

            // unmodified proposal with PNSTP module enabled (and approved) must not save when finalizing
            // otherwise it will be considered as change post approval and PNSTP approval will be reversed to
            // unapproved state
            //
            let save = dirty ? true : false;
            if (this.policy.policy?.pnstp?.nstp_status !== 'approved') save = true;
            if (this.policy.policy?.proposal?.data?._ready != 1) save = true;
            
            // if (!save) {
            //     await this.policy.__finalize('proposal');
            // } else {
                await this.policy.__saveAndFinalizeProposal(this.policy.policy.proposal.data);
            // }
            this._move_to_inprogress();
            this._update_stage_status();
        }
        else {
            await this.policy.__saveProposal(this.policy.policy.proposal.data);
        }
        this.__reset_dirty();
        this._handle_errors(undefined, true);
    }

    async saveEProposal(finalize: boolean) {
        if (!this.policy?.endorsement) return;
        let changeRoute = this.policy.endorsement.endorsement_id ? false : true;
        console.log('save: eproposal', this.policy.endorsement?.eproposal?.data.prd_endorsement_id)
        if (finalize) {
            if (!this.__check_form()) return;
            let dirty = this.fgs.find(x => x.dirty);

            // unmodified quote with PNSTP module enabled (and approved) must not save when finalizing
            // otherwise it will be considered as change post approval and PNSTP approval will be reversed to
            // unapproved state
            //
            if (!dirty && this.policy?.endorsement?.pnstp?.nstp_status == 'approved') {
                await this.policy.__finalize('eproposal');
            } else {
                await this.policy.__saveAndFinalizeEndorsementProposal(this.policy.endorsement.eproposal.data);
            }
            this._move_to_inprogress();
            this._update_stage_status();
        } else {
            await this.policy.__saveEndorsementProposal(this.policy.endorsement.eproposal.data);
        }
        if (changeRoute) this.__update_router();
        // if (changeRoute && this.policy.endorsement.endorsement_id) {
        //     this.router.navigate([], {relativeTo: this.activatedRoute,
        //         queryParams: { endorsement_id: this.policy.endorsement.endorsement_id, product_id: null},
        //         queryParamsHandling: 'merge', replaceUrl: true});
        // }
        this.__reset_dirty();
        this._handle_errors(undefined, true);
    }

    async paymentAction(opt: any) {
        let paymentId = this.policy?.endorsement ? this.policy?.endorsement.epayment_id : this.policy?.policy?.payment_id;
        if (!opt?.register?.url || !paymentId) return;
        let route = this.preferences.vendor?.payment?.processor;
        console.log('payment:', opt);
        let ret = null;
        if (opt.register.method == 'post') {
            ret = await insapi.__xpost(opt.register.url, {key: paymentId, payment_id: paymentId, src: route});
        } else {
            let url = opt.register.url + ((opt.register.url.indexOf('?') > 0) ? '&' : '?') + 'key=' + encodeURIComponent(paymentId) + '&src=' + encodeURIComponent(route);
            ret = await insapi.__xget(url);
        }

        if (!ret) return;

        if ((opt.method||'').toLowerCase() == 'post') {
            const pform = document.createElement('form');
            pform.method = 'POST';
            pform.action = ret.url;
            pform.style.display = 'none';
            pform.append('Content-Type', 'application/x-www-form-urlencoded');
            pform.append('Accept', 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8');
            for (let param of opt.params||[]) {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = param;
                input.value = ret[param];
                pform.appendChild(input);
            }
            document.body.appendChild(pform);
            pform.submit();
        } else {
            console.log('get-ret:', ret);
            if (ret && ret.url) window.location.href = ret.url;
        }

    }

    async send_to_customer() {
        if (!this.policy?.policy?.policy_id) return;
        try {
            let data: any = {policy_id: this.policy?.policy?.policy_id};
            if (this.policy?.policy?.proposal_id ) data.email = this.policy?.policy?.proposal.data.email || this.policy?.policy?.proposal.data.email_id;
            else  data.email = this.policy?.policy?.quote.data.email || this.policy?.policy?.quote.data.email_id;
            data.link = '/b2c/policy?policy_id=' + encodeURIComponent(data.policy_id);
            let ret = await insapi.xpost('/api/v1/policy/reassign', data);
            if (ret) insapi.showMessage(ret.txt, 1);
        } catch (e: any) {
            insapi.showMessage(e.toString(), 1);
        }
    }

    async send_payment_link() {
        try {
            let data: any = {payment_id: this.policy?.policy?.payment_id};
            if (this.policy?.policy?.proposal_id ) data.email = this.policy?.policy?.proposal.data.email || this.policy?.policy?.proposal.data.email_id;
            else  data.email = this.policy?.policy?.quote.data.email || this.policy?.policy?.quote.data.email_id;
            data.link = '/pub/payment';
            let ret = await insapi.xpost('/api/v1/payment/mail', data);
            if (ret) insapi.showMessage(ret.txt, 1);
        } catch (e: any) {
            console.log("send_payment_link",e);
            insapi.showMessage(e.toString(), 1);
        }
    }

    async onDownloadCatalog(catalog: any) {
        await this.policy?.downloadCatalog(catalog);
    }

    async goal_seek(goal: any) {
        if (!goal || !goal.targets || !this.policy) return;
        let data = null;
        if (this.policy.policy?.proposal_id) data = this.policy.policy.proposal.data;
        else data = this.policy.policy?.quote.data;
        let ret = await this.policy?.seek(data, !this.policy.policy?.proposal_id, goal);
        if (ret == null) {
            insapi.showMessage("Could not match the value provided", 0);
        } else {
            let changed = await this.policy?.__premium(data, true);
            if (changed === true) changed = {};
            changed = {...changed, ...ret?.found};
            this.fgs.forEach(x => x._changed(ret?.found));
        }
    }

    async onAction(ev: any) {
        if (ev.field_name === 'save_continue' ) {
            if (this.policy?.endorsement) {
                await this.saveEProposal(false);
                this._redirectTo(ev, this.policy?.endorsement?.eproposal.data);
            } else {
                await this.saveQuote(false);
            }
        } else if (ev.field_name === 'save_finalize') {
            if (this.policy?.endorsement) {
                await this.saveEProposal(true);
                this._redirectTo(ev, this.policy?.endorsement?.eproposal.data);
            } else {
                await this.saveQuote(true);
                this._redirectTo(ev, this.policy?.policy?.quote.data);
            }
        } else if (ev.field_name === 'save' ) {
            if (this.policy?.endorsement) await this.saveEProposal(false);
            else await this.saveProposal(false);
        } else if (ev.field_name === 'finalize' ) {
            if (this.policy?.endorsement) {
                await this.saveEProposal(true);
                this._redirectTo(ev, this.policy?.endorsement?.eproposal.data);
            } else {
                await this.saveProposal(true);
                this._redirectTo(ev, this.policy?.policy?.proposal.data);
            }
        } else if (ev.field_name === 'finalize_policy' ) {
            await this.policy?.__finalize('policy');
        } else if (ev.field_name === 'add_to_package') {
            await this.saveQuote(false, ev.product_id);
        } else if (ev.field_name === 'collect_cash' || ev.field_name === 'cash_payment' || 
                   ev.field_name === 'finalize_payment' || ev.field_name === 'payment_finalize') {
            if (ev.field_name === 'finalize_payment' || ev.field_name === 'payment_finalize') await this.policy?.__finalize('payment');
            else await this.policy?.__payCash(undefined, ev.sub_action);   // use sub_action incase a deposit has been chosend
            this._move_to_inprogress();
            this._update_stage_status();
            this._redirectTo(ev, this.policy?.policy?.proposal ? this.policy?.policy?.proposal.data : this.policy?.policy?.quote.data);
        } else if (ev.field_name == 'meta_data') {
            await this.policy?.addMetaData(ev.meta);
        } else if (ev.field_name == 'clone' || ev.field_name == 'clonedoc' || ev.field_name == 'version') {
            if (this.policy?.policy?.quote_id) {
                let withDoc = ev.field_name == 'clonedoc';
                let ret = await insapi.cloneQuote(this.policy?.policy?.quote_id, withDoc, withDoc, (ev.field_name == 'version')?'':'1', this.policy?.policy?.master_policie_no);
                if (ret.length>0)
                this.router.navigate(['/policy'], { state: {asd:1}, queryParams: {policy_id: ret[0].policy_id}});
            }
        } else if (ev.field_name == 'save_inspect') {
            if (this.policy?.endorsement)
                await this.policy?.saveInspect(this.policy?.endorsement?.inspect.data);
            else 
                await this.policy?.saveInspect(this.policy?.policy?.inspect.data);
        } else if (ev.field_name == 'load_policy') {
            this.router.navigate(['/policy'], { state: {asd:1}, queryParams: {policy_id: ev.policy_id}});
        } else if (ev.field_name == 'load_endorsement') {
            this.router.navigate(['/endorsement'], { state: {asd:1}, queryParams: {policy_id: ev.policy_id, endorsement_id: ev.endorsement_id}});
        } else if (ev.field_name == 'back_to_combo') {
            this.cdkStepper.reset();
            this.router.navigate(['/policy'], { state: {asd:1}, queryParams: {policy_id: this.policy?.policy?.combo_policy_id}});
        } else if (ev.type == 'installments') {
            await this.__reload();
        } else if (ev.field_name == 'send_payment_link') {
            this.send_payment_link();
        } else if (ev.field_name == 'send_to_customer') {
            this.send_to_customer();
        } else if (ev.field_name == 'payment_gateway') {
            let key = await this.policy?.viewKey('payment');
            let url = (this.preferences.vendor?.pubbase || '') + "/payment/" + encodeURIComponent(key);
            if (this.policy?.endorsement) {
                this.__payment_pending = this.policy?.endorsement?.payment_id || '';
                url += '?end=1';
            } else {
                this.__payment_pending = this.policy?.policy?.payment_id || '';
            }
            console.log('pay:', url);
            window.open(url, '_blank');
            // window.location.href = (this.preferences.vendor?.pubbase || '') + "/payment/" + encodeURIComponent(key);
        } else if (this.preferences.vendor?.payment?.gateway?.[ev.field_name]) {
            await this.paymentAction(this.preferences.vendor?.payment?.gateway?.[ev.field_name]);
        } else if (ev.type == 'file') {
            await this.uploadDocument(ev.ev, ev);
            delete ev.ev;   // free the files
        } else if (ev.type == 'goal') {
            await this.goal_seek(ev.goal);
        } else if (ev.field_name == 'fillin') {
            await this.form_fill(ev.ev);
        } else {
            console.log(ev.field_name, this.preferences.vendor);
            this._redirectTo(ev, this.policy?.policy?.proposal ? this.policy?.policy?.proposal.data : this.policy?.policy?.quote.data);
            console.log('action not implemented', ev);
        }
    }

    async __reload() {
        console.log('reload policy ....')
        if (this.policy?.endorsement?.data?.endorsement_id) {
            await this.policy?.__load_endorsement(this.policy?.endorsement?.data?.endorsement_id);
            if (this.__payment_pending && this.policy.endorsement.endorsement_no) this.__payment_pending = '';
        } else if (this.policy?.policy?.policy_id) {
            await this.policy?.__load(this.policy?.policy?.policy_id, false);
            if (this.__payment_pending && this.policy.policy.policy_no) this.__payment_pending = '';
        }
        this._update_stage_status();
        this._move_to_inprogress();
    }

    async form_fill(ev: any) {
        let data: any = {
            caption: 'Import from Xlsx',
            accept: '.xlsx',
            policy: this.policy,
            actions: ['update'],
            selcaption: "Select Xlsx file",
            selmessage: " "
        };
        const dialogRef = this.dialog.open(CsvImportComponent, {data, panelClass: 'mat-dialog-override'});
        dialogRef.afterClosed().subscribe(res => {
            if (res?.mode === 'update') {
                this.policy?.__merge_imported(res.jdata);
            }
        });



    }

    // called whenever the form-group status (valid/invalid) is changed
    //
    async onStageStatusChange(ev: any) {
        if (!ev.name || !this.workflow?.wf_script.linear) return;
        for (let stage of this.stages) {
            if (stage.name === ev.name) {
                stage.hasError = ev.status == 'INVALID';
                stage.completed = false;
                break;
            }
        }
    }

    onStepperNext(ev: any) {
        if (!this.cdkStepper || ev.next < 0 || ev.next >= this.cdkStepper.steps.length) return;

        console.log('stepper:', ev.current, '=>', ev.next)
        // moving to previous stages is always allowed (editable or not)
        if (ev.next < ev.current || !this.workflow?.wf_script.linear) {
            this.cdkStepper.selectedIndex = ev.next;
            return;
        }

        // cannot move to next until curren stage errors are completed
        if (this.stages[ev.current].hasError) return;

        // this is move to (jump to) step (not next step)
        if (ev.next != (ev.current+1)) {
            if (!this.stages[ev.next].ready) return;        // cannot move if target stage is not ready
            this.cdkStepper.selectedIndex = ev.next;

            if (this.workflow?.wf_script.grouped) this.stages[ev.current].completed = true;
            return;
        }

        // the targe stage should be ready (like xxx_id present) or it should be an allowed (in the same module)
        if (this.stages[ev.next].state === 'allowed' || 
            this.stages[ev.next].ready) {

            this.cdkStepper.selectedIndex = ev.next;
            this.stages[ev.next].ready = true;
            this.stages[ev.current].completed =  (this.stages[ev.current].readonly && this.stages[ev.current].ready);

            if (this.workflow?.wf_script.grouped) this.stages[ev.current].completed = true;
        }
    }

    @HostListener('window:focus') onFocus() {
        if (this.__payment_pending && 
            (this.policy?.policy?.payment_id === this.__payment_pending || this.policy?.endorsement?.payment_id == this.__payment_pending)) {
            this.__reload();
        }
    }
}
