import { Component, Input, OnInit, Output, EventEmitter, SimpleChange } from '@angular/core';
import { animShowStaggered } from '../../../theme/animations';
import { pastels_bright, pastels_gradient, prodIcons } from '../../../theme/colors';
import { insapi, insreport, IProfile } from 'insapi';
import { Router, Route } from "@angular/router";
import { environment } from './../../../../environments/environment';
// import { animScaleX } from 'ins-form';

import { trigger, state, style, transition, animate } from '@angular/animations';
import { Subscription } from 'rxjs';



const slideOut = trigger('slideOut', [
    transition(':enter', [
        style({transform: 'translateX(-50%)', opacity: 1}),
        animate('300ms', style({transform: 'translateX(0%)', opacity: 0}))
        ]),
    // transition(':leave', [
    //     style({transform: 'translateX(0%)', opacity: 1}),
    //     animate('300ms', style({transform: 'translateX(-50%)', opacity: 0}))
    //     ])
    ]
);

const animScaleX = trigger('animScaleX', [
    transition(':enter', [style({transform: 'scaleX(0)', opacity: 1}),animate('300ms', style({transform: 'scaleX(1)', opacity: 0}))]),
    ]
);

@Component({
    selector: 'product-selector',
    templateUrl: './product-selector.component.html',
    styleUrls: ['./product-selector.component.scss'],
    animations: [animShowStaggered, animScaleX]
})
export class ProductSelectorComponent implements OnInit {

    @Input() products: any[] = [];
    @Output() newpolicy = new EventEmitter();

    profile?: IProfile | null;
    more: boolean = false;
    pastels: any[] = pastels_bright;
    prefix: string = '';
    filtered: any[] = [];
    type: number = environment.vendor.home?.products?.type || 1;
    subscription: Subscription | null = null;
    excluded: any = {};
    prd_columns: any = environment.vendor.home?.topproduct?.columns || 3;
    constructor(private router: Router) { }

    ngOnInit(): void {
        if (this.type == 2 || this.type == 3 || this.type == 4) this.more = true;
        this.subscription = insapi.profileSubject.subscribe((profile: IProfile|null) => {
            this.profile = profile;
            if (profile && profile.excluded) {
                for (let pid of profile.excluded) this.excluded[pid] = true;
            }
        });
    }
    
    ngOnDestroy(): void {
        if (this.subscription) this.subscription.unsubscribe();
        this.subscription = null;
    }

    _select_product(name: string, pid: string) {
        if (this.excluded[pid]) return insapi.showMessage('You are not allowed to create new policy', 0);
        this.newpolicy.emit(name);
        return false;
    }

    _update_products() {
        if (this.prefix) {
            let prefix = this.prefix.toLowerCase();
            this.filtered = this.products.filter((x: any) => x.product_name.toLowerCase().indexOf(prefix) >= 0);
        }
        else this.filtered = this.more ? this.products.slice(0) : this.products.slice(0, this.prd_columns);
		// profile based prathi
		   if(this.profile?.is_admin==1){
			   this.filtered=this.filtered;
		   }else  if(this.profile?.is_admin==0){
			     this.filtered = this.filtered.filter((obj:any) => obj.product_name !='Marine Open Policy' && obj.product_name !='STOP')
		   }
		
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        if (changes['products']) {
            this._update_products();
            for (let prod of this.products) prod.show = false;
        }
    }

    toggleMoreProducts() {
        this.more = !this.more;
        this.prefix = '';
        this._update_products();
        // this.filtered = this.more ? this.products.slice(0) : this.products.slice(0, 3);
    }

    async toggleFavorite(ev: any, prod: any) {
        if (ev) ev.stopPropagation();
        let fav = this.profile?.data?.preferences?.fav_prods || {};
        if (prod.favorite) delete fav[prod.product_name];
        else fav[prod.product_name] = true;
        prod.favorite = !prod.favorite;
        await insapi.setPreferences('fav_prods', fav);
    }

    showDesc(idx: number) {
        if (this.filtered[idx].show) {this.filtered[idx].show = false; return;}
        for (let prod of this.products) prod.show = false;
        this.filtered[idx].show = true;
    }

    quoteList(idx: number) {
        this.router.navigate(['/policy-quote', this.filtered[idx].product_id]);
    }

    filter() {
        this._update_products();
    }
}
