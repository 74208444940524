<div class="messages" *ngIf="profile">
    <div *ngIf="flags.canAdd" class="msg-add">
        <div>
            <div *ngIf="rejectMode" style="width: 180px;">
                <mat-form-field style="width: 100%;" >
                    <mat-label >Rejection code</mat-label>
                    <input #acinput type="text" matInput [(ngModel)]="rejectCode" placeholder="Code"
                        [matAutocomplete]="auto" (input)="onOptionInput($event)">
                    <mat-icon matSuffix>search</mat-icon>
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="codeSelected($event)">
                        <mat-option *ngFor="let option of codesFiltered" [value]="option.status_code">{{option.status_code}}</mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>
        <div>
            <input type="text" placeholder="Add note/reason" [(ngModel)]="reason">
        </div>
        <div style="padding: 0em 0em 0em 1em;" *ngIf="!rejectMode">
            <button mat-button (click)="sendNote()" *ngIf="flags.canAdd"><mat-icon>send</mat-icon></button>
        </div>
        <div style="padding: 0em 0em 0em 1em;" *ngIf="rejectMode">
            <button mat-button ibutton (click)="reject()" *ngIf="rejectCode && reason">Mark Rejected</button>
        </div>
    </div>

    <div class="referral-transfer-user" *ngIf="!rejectMode">
        <ng-template ngFor let-s [ngForOf]="statuses">
            <button mat-button ibutton (click)="onStatusAction(s)">{{s.label}}</button>
        </ng-template>

        <!-- <button mat-button ibutton (click)="approve()" *ngIf="flags.canApprove">Approve</button>
        <button mat-button ibutton (click)="rejectMode=true" *ngIf="flags.canReject">Reject</button> -->
        <button mat-button ibutton (click)="assignTo()" *ngIf="sendTo">{{sendTo}}</button>
        <button mat-button ibutton (click)="returnToGroup()" *ngIf="flags.canReturn">Return to group</button>
        <div class="doctype-selector" *ngIf="doctypes.length>0 && !useZulip">
            <div style="display: grid; grid-template-columns: 1fr auto;">
                <mat-form-field style="width: 100%;">
                    <mat-label>Select/Type Document type</mat-label>
                    <input #acinput type="text" matInput [(ngModel)]="docType" placeholder="Document type"
                        [matAutocomplete]="auto" (input)="onOptionInput($event)">
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                        <mat-option *ngFor="let doc of doctypes" [value]="doc.document_type">{{doc.desc}}</mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <div *ngIf="docType" class="file-upload">
                    <mat-icon>attach_file</mat-icon>
                    <input type="file" (change)="onAttach($event)"/>
                </div>
            </div>
        </div>
        <div class="referral-transfer" *ngIf="flags.canTransfer" >
                <user-selector *ngIf="transferLists?.length<=0" class="uw-selector" [(ngModel)]="uw" label="Transfer to"></user-selector>
            
                <mat-form-field appearance="fill" class="grouptransfer" *ngIf="transferLists?.length>0">
                    <mat-label>Transfer To Group</mat-label>
                    <mat-select [(ngModel)]="uw">
                        <mat-option *ngFor="let item of transferLists" [value]="item.value">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            
            <button mat-button ibutton (click)="transferTo()" *ngIf="flags.canTransfer && uw">Transfer</button>
        </div>
        
    </div>

    <div *ngIf="useZulip">
        <activity [mode]="2" [streamName]="policy.policy?.policy_id" [doctypes]="doctypes"></activity>
    </div>
    <div class="msg-wrapper" *ngIf="!useZulip && notes?.length>0">
        <div *ngFor="let msg of notes" class="msg-item" [class]="'msg-item-' + (msg.self ? 'self':'other')">
            <div style="grid-area: column-1; text-align: center; padding-top: 1em;">
                <img class="msg-avatar" [src]="'/res/img/avatar/'+msg.avatar" alt="">
                <span *ngIf="msg.message=='approved'" style="color: #65FF00;"><mat-icon fontSet="material-icons-outlined">done</mat-icon></span>
                <span *ngIf="msg.message=='rejected'" style="color: #FF0075;"><mat-icon fontSet="material-icons-outlined">close</mat-icon></span>
                <div class="msg-user">{{msg.author}}</div>
            </div>
            <div style="grid-area: column-2;" [class]="'msg-item-note-' + (msg.self ? 'self':'other')">
                <div class="msg-ts">{{msg.u_ts | timeelapsed}}</div>
                <div [class]="'msg-reason-' + (msg.self ? 'self':'other')">{{msg.message || msg.subject}}</div>
            </div>
        </div>
    </div>
</div>
