import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router, Route, NavigationStart } from "@angular/router";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { insapi } from 'insapi';
import { environment } from './../../../environments/environment';
import { MatTableDataSource} from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import moment from 'moment';
import * as XLSX from 'xlsx';  



@Component({
    selector: 'app-bulk',
    templateUrl: './bulk.component.html',
    styleUrls: ['./bulk.component.scss']
})
export class BulkComponent implements OnInit {
    @ViewChild(MatPaginator) recpage!: MatPaginator;
    @ViewChild(MatPaginator) sespage!: MatPaginator;

    @ViewChild(MatSort) set recsort(value: MatSort)  {this.recSource.sort = value;}
    // @ViewChild(MatSort) recsort!: MatSort;
    @ViewChild(MatSort) sessort!: MatSort;

    params: any = {};
    sessions: any[] = [];	

    session: any = null;
    records: any[] = [];
    session_status: any = {0: 'Not started', 1: 'Running', 2: 'Paused', 3: 'Cancelled', 4: "Failed", 5: 'Completed'};
    record_status: any = {0: 'Not started', 1: 'Running', 2: 'Completed', 3: "Errors", 4: 'Failed'};
    declarations: boolean = false;
    progress: any = {};
    timer: any = null;
    refId: string = "";
	allowedExtensions = ['csv'];
    // display related
    conf: any = environment.vendor['bulk']['all-groups'];
    recSource: MatTableDataSource<any[]> = new MatTableDataSource<any[]>([]);
    sesSource: MatTableDataSource<any[]> = new MatTableDataSource<any[]>([]);
    columns: string[] = [];
    sescolumns: string[] = ['session_id', 'started_at', 'ended_at', 'status_disp', 'declaration'];
    productName: string = '';
    counts: any = {total: 0, errors: 0};
    rsubscription: Subscription | null = null;

    // new code 
    baseURL: string = "";
	//newly added
	voyage_type:any[]=[];
	period:string="";
	decl_period:any[]=[];
	dperiod:any="";
	year:any="";
	dtype:string="";
	declare_marine:any =environment.vendor['declare_marine'];
	selected: string = 'basic';
	uploadData:any;
	voyage:any;
	sumins:any;
    prodName:any;
	plicyForm: FormGroup;
    constructor(private activatedRoute: ActivatedRoute,private fb: FormBuilder) {
        this.rsubscription = this.activatedRoute.queryParams.subscribe(params => this._load(params));
    }

    ngOnInit(): void {
        
		this.plicyForm = this.fb.group({
		voyage: ['', [Validators.required]]
    });
		this._load_policy_details();
		
		
    }

    ngAfterViewInit() {
        this.recSource.paginator = this.recpage;
        this.recSource.sort = this.recsort;

        this.sesSource.paginator = this.sespage;
        this.sesSource.sort = this.sessort;
    }

    ngOnDestroy(): void {
        if (this.timer) clearTimeout(this.timer);
        this.timer = null;
        if (this.rsubscription) this.rsubscription.unsubscribe();
        this.rsubscription = null;
    }   


    async _load(params: any) {
        this.params = {...params};
		console.log("params",params)
        if (this.params.decl) this.declarations = true;
        if (this.params.product_id) {
            let prod = await insapi.productFromId(this.params.product_id);
            if (prod) this.productName = prod.product_name;
        }

        delete this.params['decl'];
        await this._reload_sessions();
    }

    // get the list of all bulk upoload sessions (authored by me and my team) ...
    //
    async _reload_sessions() {
        let prod = this.activatedRoute.snapshot.paramMap.get("product");
        if (!prod) return;
        this.conf = environment.vendor['bulk'][prod] || environment.vendor['bulk']['all-groups'];

        let url = '/api/v1/bulker?product=' + encodeURIComponent(prod);
        if (this.params.master_policy_no) url += '&master_policy_no=' + encodeURIComponent(this.params.master_policy_no);
        this.sessions = await insapi.__xget(url) || [];
		console.log(" this.sessions", this.sessions)
        for (let ses of this.sessions) {
            ses.status_disp = this.session_status[ses.status] || 'unk';
            let constants = JSON.parse(ses.constants||'{}');
            ses.declaration = constants.declaration;
        }
        if (this.sesSource) {
            this.sesSource.paginator = this.sespage;
            this.sesSource.sort = this.sessort;
            this.sesSource.data = this.sessions;
        }
    }

    // new change srimathi 

    async download_template() {

        var url = document.getElementsByTagName('base')[0].href;

        var arr = url.split("/");

        this.baseURL = arr[0] + "//" + arr[2] + "/";

        window.location.href = this.baseURL + "bulk_upload_certificate.csv";

    }
 
    async onUpload(ev: any, truncate: boolean = false) {
        let prod = this.activatedRoute.snapshot.paramMap.get("product");
        if (!prod) return;
		
		const file = ev.target.files[0];
		const fileExtension = file.name.split('.').pop().toLowerCase();
        if (!this.allowedExtensions.includes(fileExtension)) {
            insapi.showMessage("Invalid file type. Please upload a CSV", 0);
            return;
        }
        // ev.target.files
        const formData = new FormData();
        formData.append('file', ev.target.files[0], ev.target.files[0].file_name);
        formData.append('ref_id', this.refId);
        if (truncate) formData.append('truncate', '1');
        for (let key in this.params) formData.append(key, this.params[key]);
			console.log("formData",formData);
        let url = '/api/v1/bulker/attach/' + encodeURIComponent(prod);
        if (this.params.master_policy_no) {
            url = '/api/v1/mop/attach/certificates/';
            if (this.declarations) url = '/api/v1/mop/attach/declarations/';
            url += this.params.product_id;
        }

        // new change srimathi
        // let ret = await insapi.__xpost(url, formData);
        // await this._reload_sessions();
        // if (ret) this.selectSession(ret.session_id);
        // console.log('ret:', ret);

        let arraylist: any = [];
        const reader = new FileReader();
        reader.readAsText(ev.target.files[0]);
        reader.onload = async (ev) => {
            const text = reader.result;
            arraylist = this.csvJSON(text);
            //console.log(arraylist.length);
            if (arraylist.length != 0) {
                let masterDataValidation = await insapi.__xpost('/api/v1/mop/bulkUploadValidation?master_policy_no=' + encodeURIComponent(this.params.master_policy_no), { arraylist });
                if (masterDataValidation.status == 0) {
					
					try{
					  // Create a timeout promise
						const timeoutPromise = new Promise((_, reject) =>
							setTimeout(() => reject(new Error('Request timed out')), 30000) // 30 seconds
						);
			 
						// Race the API call against the timeout
						let ret = await Promise.race([insapi.__xpost(url, formData), timeoutPromise]);
							await this._reload_sessions();
						if (ret) this.selectSession(ret.session_id);
						
					}catch(error){
						 insapi.showMessage("Upload in progress. Please check after sometime to start the process.", 0);
						 await this._reload_sessions();
						 insapi.showSpinner(false);
						 
					}
				    //let ret = await insapi.__xpost(url, formData);
					//await this._reload_sessions();
                    //if (ret) this.selectSession(ret.session_id);
                    //console.log('ret:', ret);
                } else {
                    insapi.showMessage(masterDataValidation.txt, 0);
                }
            }
        };
    }
		 
    csvJSON(csvText: any) {
        let lines: any = [];
        const linesArray = csvText.split('\n');
        // for trimming and deleting extra space
        linesArray.forEach((e: any) => {
            const row = e.replace(/[\s]+[,]+|[,]+[\s]+/g, ',').trim();
            lines.push(row);
        });
        // for removing empty record
        lines.splice(lines.length - 1, 1);
        const result = [];
        const headers = lines[0].split(",");

        for (let i = 1; i < lines.length; i++) {

            const obj:any = {};
            const currentline = lines[i].split(",");

            for (let j = 0; j < headers.length; j++) {
                obj[headers[j]] = currentline[j];
            }
            result.push(obj);
        }
        //return result; //JavaScript object
        // return JSON.stringify(result); //JSON
        return result;
    }
    // end 

    async _reload_records() {
        // load session records
        let records = await insapi.__xget('/api/v1/bulker/records/' + encodeURIComponent(this.session.session_id));
        this.records = [];
        this.counts = {total: 0, errors: 0, nstpCount: 0};
        for (let i=0; i<records.length; i++) {
            this.records.push({i, ...records[i].data, ...records[i], status_disp: this.record_status[''+records[i].status] || 'Unk'});
            
            if (records[i]['err_msg'] && records[i]['err_msg'].startsWith('NSTP Case:')) {
                if (records[i].status >= 3) this.counts.errors++;
                this.counts.nstpCount++;  
            }

            if (records[i].status >= 3) this.counts.errors ++;
            this.counts.total ++;
        }
        if (this.recSource) this.recSource.data = this.records;
        this.columns = this.conf.columns.map((x: any) => x.disp);
        this.recSource.paginator = this.recpage;
        this.recSource.sort = this.recsort;
    }

    async selectSession(sessionId: string) {
        this.session = null;
        this.refId = '';
        for (let ses of this.sessions) 
            if (sessionId == ses.session_id) {
                this.session = ses;
                this.refId = ses.ref_id;
            }

        if (!this.session) return;
        await this._reload_records();
        this._start_status_check();
        console.log('records:', this.records);
    }

    async start() {
		console.log(this.session);
        if (!this.session) return;
        this.session.status = 1;
        await insapi.__xpost('/api/v1/bulker/start/' + encodeURIComponent(this.session.session_id), {});
		this._start_status_check();
    }

    async pause() {
        if (!this.session) return;
        await insapi.__xpost('/api/v1/bulker/pause/' + encodeURIComponent(this.session.session_id), {});
        this._start_status_check();
    }
    async cancel() {
        if (!this.session) return;
        await insapi.__xpost('/api/v1/bulker/cancel/' + encodeURIComponent(this.session.session_id), {});
        this._start_status_check();
    }


    async applyFilter(ev: any) {
        if (ev.target?.value)
            this.recSource.filter = ev.target.value.trim().toLowerCase();
        else
            this.recSource.filter = "";
    }

    _start_status_check() {
        if (!this.session || this.timer) return;
        this._update_status();
    }

    async _update_status() {
        if (this.session) {
            try {
                this.progress = await insapi.xget('/api/v1/bulker/progress/' + encodeURIComponent(this.session.session_id));
                if (this.session) this.session.status = this.progress.status;                
                if (this.progress.status == 5 || this.progress.status == 2) {
                    await this._reload_records();
                    return; // end, no more update ...
                }
            } catch (e) {
                console.log(e);
            }    
        }

        this.timer = null;
        if (+this.session.status < 3 )
            this.timer = setTimeout(() => this._update_status(), 2000);
    }
    async switchToSessions() {
        this.session = null;
        this.refId = '';
        if (this.timer) clearTimeout(this.timer);
        this.timer = null;
        await this._reload_sessions();
    }
	//my changes
	async _load_policy_details() {
		 let voyages = (await insapi._xget_cache("/api/v1/mop/voyage?master_policy_no=" + encodeURIComponent(this.params.master_policy_no))) || [];
		this.voyage_type =voyages[0]["Voyage Type"];
		this.dtype =voyages[0]["Declaration Type"];
		let arr=[];
		for(var i=1;i<=12;i++){
			if(this.dtype == 'Monthly' && i<=12)arr.push(i);
			if(this.dtype == 'Quarterly' && i<=4)arr.push(i);
			if(this.dtype == 'Half yearly ' && i<=2)arr.push(i);
			if(this.dtype == 'Yearly' && i==1)arr.push(i);
			
		}
		this.decl_period=arr;
		console.log("arr",voyages)
		
        
    }
	 valuechange(evt: any) {

    if (evt.which === 8 || evt.which === 0) {
        return true;
    }

    const regex = new RegExp("^[0-9\~]*$");
    var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
    // console.log(regex.test(key))
    if (!regex.test(key)) {
        evt.preventDefault();
        return false;
    }
    return true;
  }
   async onUpload_decl(ev: any, truncate: boolean = false) {
	    let prod = this.activatedRoute.snapshot.paramMap.get("product");
        if (!prod) return;
		this.uploadData =ev;
		
		
  }


  async submit_decl(truncate: boolean = false){
	  //insapi.showMessage("text", 0);
	 console.log(this.uploadData,this.params.master_policy_no); 
	 const formData = new FormData();
	 console.log(this.uploadData.target.files[0]);
        formData.append('file', this.uploadData.target.files[0]);
		formData.append('file_name',this.uploadData.target.files[0].name);
        if (truncate) formData.append('truncate', '1');
		formData.append('decl_basis', this.selected); 
		formData.append('decl_voyage', this.voyage);
		formData.append('decl_type', this.dtype);
		formData.append('decl_period', this.dperiod);
		formData.append('decl_year', this.year);
		formData.append('decl_sum_insured',this.sumins );
        formData.append('bulk_declaration', 'true');
        for (let key in this.params) formData.append(key, this.params[key]);
	 let url:any ="";
	  if (this.params.master_policy_no) {
            if (this.declarations){ 
			url = '/api/v1/mop/attach/declarations/';
			url += this.params.product_id;
			}
        }
	 let ret =await insapi.__xpost(url, formData);
	  await this._reload_sessions();
      if (ret) this.selectSession(ret.session_id);
	  //await this.start();
        //console.log('ret:', ret);
  }


    // Excel download using SheetJS
    async downloadExcel() {
    
        let records = await insapi.__xget('/api/v1/bulker/records/' + encodeURIComponent(this.session.session_id));
        // if (records && records.length > 0) {
            var data = records.map((record: any) => ({         
                MasterPolicyNo: record.data.master_policy_no, 
                CertificateNo: record.policy_no,
                VoyageType : record.data.voyage_type,
                InoviceNo : record.data.invoice_no,
                InvoiceValue : record.data.invoice_value,
                Status : record.status == '2' ? 'Completed' : 'Failed',
                ErrorMessage : record.err_msg     
            }));
            var ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
            var wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Status Report');
            XLSX.writeFile(wb, 'updated_status.xlsx');          
        // } 
    }

}
