import { Component, ChangeDetectorRef, ViewChild, TemplateRef, ElementRef, ViewContainerRef } from '@angular/core';
import { Router, NavigationStart } from "@angular/router";
import { insapi, IProfile, InsapiService } from 'insapi';
import { MediaMatcher } from '@angular/cdk/layout';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatSidenav } from '@angular/material/sidenav';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { environment } from './../environments/environment';
import { SettingsService } from './services/settings.service';
import { MatMenu } from '@angular/material/menu';
import { Subscription } from 'rxjs';
import { PreferencesService, CartComponent } from 'ins-form';
import { TemplatePortal } from '@angular/cdk/portal';
import { OverlayConfig, OverlayRef, Overlay, ConnectionPositionPair } from '@angular/cdk/overlay';

interface MenuOpt {
    name: string;
    route?: string;
    children?: MenuOpt[];
    icon?: string;
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component' + environment.brand + '.html',
    styleUrls: ['./app.component' + environment.brand + '.scss']
})
export class AppComponent {
    company = 'Insillion';
    subTitle = 'Home';
    profile: IProfile | null = null;
    products: any[] = [];
    mobileQuery: MediaQueryList;
    menu: MenuOpt[] = [];
    menuRef: any = {};
    searchValue: string = '';
    sel_products: any[] = [];

    nestedMenu: any[] = [];

    showCart: boolean = false;
    cartCount: number = 0;
    saveCount: number = 0;

    @ViewChild('quotesmenu') set quotesmenu(value: MatMenu)  {this.menuRef['quotesmenu'] = value};
    @ViewChild('profilemenu') set profilemenu(value: MatMenu)  {this.menuRef['profilemenu'] = value};
	@ViewChild('reportsubmenu') set reportsubmenu(value: MatMenu)  {this.menuRef['reportsubmenu'] = value};
    priceOverlayRef: OverlayRef | undefined;

    version: string = environment.appVersion;
    vendor: any = environment.vendor;
    theme: boolean = false;

    private _mobileQueryListener: () => void;

    @ViewChild('snav') public sidenav!: MatSidenav;
    @ViewChild('menuTree') menuTree!: any;

    treeControl = new NestedTreeControl<MenuOpt>(node => node.children);
    dataSource = new MatTreeNestedDataSource<MenuOpt>();

    premiumDisp: any = {field_name: environment.vendor.policy?.premium?.showTotalAmount ? 'total_amount' : 'premium_value', 
        pipe: (environment.vendor.policy?.premium?.showTotalAmount ? this.vendor.policy?.premium?.totalFormat : this.vendor.policy?.premium?.premiumFormat) || ['currency'], 
        style: {'font-size': '1.2em', "padding-left": "1em", color: 'var(--header-fg-hilite)'}};
    premiumData: any = {premium_value: 0, message: ''};
    pastels: any[] = [
        {bg: '#EBE2FD', light: '#FBF2FD', dark: '#8B829D', deep: '#473E99'},
        {bg: '#E4FFE9', light: '#F8FFFA', dark: '#849F89', deep: '#40BF49'},
        {bg: '#FCF5D1', light: '#FFFFE3', dark: '#9C9571', deep: '#BCB031'},
        {bg: '#FDDFDF', light: '#FFEFEF', dark: '#9D7F7F', deep: '#CD4F4F'},
        {bg: '#D4F9F7', light: '#E4FFF9', dark: '#749997', deep: '#44C9C7'},
        {bg: '#F4D9F6', light: '#F8E9F9', dark: '#947996', deep: '#C449C6'}
    ]

    rsubsription: Subscription | null = null;
    cartsubsription: Subscription | null = null;
    subscription: Subscription | null = null;
    psubscription: Subscription | null = null;


        // new change 
        planCreation:boolean=false;
		baseURL: string = "";
		showQuoteStatusreport:boolean=false;
		showNonraterreport:boolean=false;
		showFirereport:boolean=false;
		showEcreport:boolean=false;
		showsoareport:boolean=false;
    constructor(changeDetectorRef: ChangeDetectorRef, 
        media: MediaMatcher,
        private router: Router,
        private preferences: PreferencesService,
        private settings: SettingsService,
        private insapiService: InsapiService,
        private overlay: Overlay,
        private viewContainerRef: ViewContainerRef) {
            
        this.mobileQuery = media.matchMedia('(max-width: 600px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);
        this.company = settings.company;
        this.subscription = insapi.profileSubject.subscribe((profile: IProfile|null) => {
            this.profile = profile;
            // this.dataSource.data = [{name: 'test', children: [{name: 'ch1'}, {name: 'ch2'}]}];
            this.init();
            if (profile) {
                insapiService.updateCart(null);
                insapiService.wsConnect();
            }
        });
        this.cartsubsription = insapi.cartSubject.subscribe((val) => this._update_cart(val));
        this.rsubsription = router.events.subscribe((val) => {
            if (!(val instanceof NavigationStart)) return;
            this.subTitle = '';
            if (this.profile)
                this.subTitle = this._menu_disp_name(this.profile.menu, val.url.replace(/\//g, ''));
            if (!this.subTitle) {
                if (val.url.startsWith('/policy') ) this.subTitle = 'Policy';
                else if (val.url.startsWith('/group') ) this.subTitle = 'Group';
                else if (val.url.startsWith('/role') ) this.subTitle = 'Role';
                else if (val.url.startsWith('/org') ) this.subTitle = 'Users';
                else if (val.url.startsWith('/home') || !val.url) this.subTitle = 'Home';
            }
        });
        this.psubscription = this.preferences.premiumSubject.subscribe((val: number|string) => {
            this.premiumData = val;
        });
        if (!this.vendor.premiumAtNavBar) this.premiumDisp = null;
    }
    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this._mobileQueryListener);
        if (this.subscription) this.subscription.unsubscribe();
        this.subscription = null;
        if (this.cartsubsription) this.cartsubsription.unsubscribe();
        this.cartsubsription = null;
        if (this.rsubsription) this.rsubsription.unsubscribe();
        this.rsubsription = null;
        if (this.psubscription) this.psubscription.unsubscribe();
        this.psubscription = null;
    }
	report(value :any){
		console.log(value);
		   var url = document.getElementsByTagName('base')[0].href;
		   var arr = url.split("/");
			this.baseURL = arr[0] + "//" + arr[2] + "/";
			console.log(this.baseURL);
			window.open(this.baseURL+value, "_blank");

	}


    hasChild = (_: number, node: MenuOpt) => !!node.children && node.children.length > 0;

    logout = async () => {
        this.sidenav.close();
        insapi.logout();
        await insapi.loadUser();
        this.router.navigate(['/home']);
    }

    menuClicked(node: any) {
        console.log('route: ', node.route)
        if (node.route) 
            if (node.route instanceof Array) this.router.navigate(node.route);
            else this.router.navigate([node.route]);
        this.sidenav.close();
    }

    gotoReport(name: string) {
        this.router.navigate([name]);
        this.sidenav.close()
    }

    _icon_map(name: string, faicon: string) {
        let iconmap: any = {'fa-user': 'manage_accounts_outline', 
            'fa-user-circle': 'settings_input_antenna_outline',
            'fa-users': 'group_work_outline',
            'fa-pie-chart': 'dashboard_outline'};
        if (iconmap[faicon]) return iconmap[faicon];

        let namemap: any = {
            'Admin': 'supervisor_account_outline',
            'Ops': 'settings_outline',
            'Products': 'table_view_outline',
            'Policies': 'token_outline'
        };
        if (namemap[name]) return namemap[name];
        // console.log('name: ', name, faicon);
        return undefined;
    }

    _add_to_menu(item: MenuOpt[], imenu: any[], paths: string[]) {
        if (!imenu || imenu.length==0) return [];
        for (let m of imenu) {
            let rid = (m.url||'').split('#')[1];
            if ((!rid || !paths.includes(rid)) && ( !m.items || m.items.length==0) )continue;
            item.push({name: m.disp_name, route: rid, children: this._add_to_menu([], m.items, paths), icon: this._icon_map(m.disp_name, m.fa_icon)});
        }
        return item.filter((x: any) => x.route || x.children.length>0);
    }

    _menu_disp_name(items: any[], id: string): string {
        for (let m of items) {
            if (!m.url) continue;
            let rid = m.url.split('#')[1];
            if (rid == id) return m.disp_name || '';
            if (m.items && m.items.length>0) {
                let name = this._menu_disp_name(m.items, id);
                if (name) return name;
            }
        }
        return '';
    }

    makeMenu() {
        if (!this.profile) return;
        let paths = this.router.config.map((x: any) => x.path.replace(/[\/\:].*/, ''));
        let prods = this.products.map((x: any) => ({name: x.name, route: ['/policy-quote', x.id]}));
        let endorsement = this.products.map((x: any) => ({name: x.name, route: ['/policy-end', x.id]}));
          // new change 
          this.planCreation = Object.keys(this.profile.group_map).indexOf('BGR Plans')>=1?true:false;
          // new end 
		
		   if (this.profile.privileges.includes('Quote Status Report')) this.showQuoteStatusreport = true;
		   if (this.profile.privileges.includes('Non Rater dumb Report')) this.showNonraterreport = true;
		    if (this.profile.privileges.includes('Fire dumb Report')) this.showFirereport = true;
			 if (this.profile.privileges.includes('EC dumb Report')) this.showEcreport = true;
			  if (this.profile.privileges.includes('SOA Report')) this.showsoareport = true;
		 
        let menuData: any = this.vendor.home.sidemenu;
        menuData.map((item: any) =>((item.children=="quotesmenu")?item.children = prods:''));
		menuData.map((item: any) =>((item.name=="Endorsements")?item.children = endorsement:''));
       
        // [
        //     {name: 'Home', route: 'home', children: [], icon: 'home_outline'},
        //     {name: 'Quotes', children: prods, icon: 'dynamic_form'},
        // ]
        
        let tree = this._add_to_menu(menuData, this.profile.menu, paths);
        
        // tree.push({name: 'Quotes', children: prods, icon: 'home_outline'});
		//Added by prathi
		let tree_witq=[];
		let tree_withe=[];
		if(this.profile?.is_admin == 0){
		 for(var i=0;i< tree.length;i++){
		console.log("this.dataSource.data",tree[i]['name']);	
			if(tree[i]['name']=='Quotes'){
				for(var j=0;j<tree[i]['children'].length;j++){
					if(tree[i]['children'][j].name !=='Marine Open Policy' || tree[i]['children'][j].name !=='STOP'){
                        console.log("this.dataSource>>>>>>>>>>>.data",tree[i]['children'][j].name);
						tree_witq.push(tree[i]['children'][j]);                        
					}
                    
				}

				tree[i]['children']=tree_witq;
			
			}
			if(tree[i]['name']=='Endorsements'){
				for(var j=0;j<tree[i]['children'].length;j++){
					if(tree[i]['children'][j].name !=='Marine Open Policy' || tree[i]['children'][j].name !=='STOP'){
						tree_withe.push(tree[i]['children'][j]);
						
					}
				}
				tree[i]['children']=tree_withe;
			
			}
		} 
		}
		console.log(tree_withe);
        this.dataSource.data = tree;
        this.menu = [...menuData];
        
        this.menu.push({name: 'Dashbords', route: 'dashboard', children: [
            {name: 'Snapshot', route: '/ddview/snapshot'},
            {name: 'Usage', route: '/ddview/usage', icon: 'timeline'}
        ], icon: 'dashboard_outline'});
        
        if (this.menuTree) {
            this.menuTree.treeControl.dataNodes = this.dataSource.data;
            // setTimeout(() => this.menuTree.treeControl.expandAll(), 40);
        }
    }

    toggleCart() {
        if (!this.profile?.cart) {this.showCart = false; return;}
        this.showCart = !this.showCart;
        if (this.cartCount+this.saveCount == 0) this.showCart = false;
    }

    async _update_cart(cart: any) {
        this.cartCount = insapi.cartItemCount;
        this.saveCount = insapi.cartSavedCount;
        // if (!cart) return;
        // if (cart.items) this.cartCount = cart.items.length;
        //if (cart?.saved) this.saveCount = cart.saved.length;
        if (this.cartCount+this.saveCount == 0) this.showCart = false;
    }

    async init() {
        if (this.profile) {
            this.products = this.profile.products
                .filter((x:any) => x.status==0)
                .map((x: any) => ({name: x.product_name, id: x.product_id, product_group_name: x.product_group_name}));
        } else {
            this.products = [];
        }
        let pg: any = {};
        for (let p of this.products) {
            if (!pg[p.product_group_name]) pg[p.product_group_name] = [];
            pg[p.product_group_name].push({name: p.product_name, ...p});
        }
        this.nestedMenu = [];
		//hide marine open policy
		
       for (let key in pg) this.nestedMenu.push({name: key, children: pg[key]});
		if(this.profile?.is_admin==1){
		   this.nestedMenu=this.nestedMenu;
			}else  if(this.profile?.is_admin==0){
		     this.nestedMenu = this.nestedMenu.filter((obj:any) => obj.name!='Marine_MOP' && obj.name!='Marine_STOP');
		}
	  
	    this.sel_products = this.products;
        this.makeMenu();
    }

    onKey(ev: any) { 
        ev?.stopPropagation();
        this.sel_products = this.search(ev?.target?.value);
        
    }
        
    search(value: string) { 
        if (!this.products || this.products.length <= 0 ) return [];
        let filter = value.toLowerCase();
        return this.products.filter((option: any) => option.name.toLowerCase().startsWith(filter));
    }

    clearSearch(){
        this.sel_products=this.products;
        this.searchValue = '';
    }

    showPriceDetails(ovlay: TemplateRef<any>, elemRef: ElementRef<any>) {
        console.log('showPriceDetails: tax:', this.premiumData.tax);
        const positionStrategy = this.overlay
            .position().flexibleConnectedTo(elemRef).withPositions([
                new ConnectionPositionPair({ originX: 'start', originY: 'bottom' },{ overlayX: 'start', overlayY: 'top' },),
                new ConnectionPositionPair({ originX: 'center', originY: 'top' },{ overlayX: 'center', overlayY: 'bottom' },),
                new ConnectionPositionPair({ originX: 'start', originY: 'bottom' },{ overlayX: 'end', overlayY: 'top' },),
                new ConnectionPositionPair({ originX: 'start', originY: 'top' },{ overlayX: 'start', overlayY: 'bottom' },),
                new ConnectionPositionPair({ originX: 'start', originY: 'top' },{ overlayX: 'end', overlayY: 'bottom' },),
             ]).withPush(false);

        if (!this.priceOverlayRef) {
            const scrollStrategy = this.overlay.scrollStrategies.reposition();
            this.priceOverlayRef = this.overlay.create({
                hasBackdrop: true,
                scrollStrategy, positionStrategy,
            });
            this.priceOverlayRef.backdropClick().subscribe(() => {
                this.priceOverlayRef?.detach()
            });
        }

        if (this.priceOverlayRef.hasAttached()) this.priceOverlayRef.detach();
        this.priceOverlayRef.updatePositionStrategy(positionStrategy);
        this.priceOverlayRef.attach(new TemplatePortal(ovlay, this.viewContainerRef));
    }

}
