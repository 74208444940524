import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Policy, insapi } from 'insapi';
import { IField } from '../../form.interface';
import { Subscription } from 'rxjs';

@Component({
    selector: 'installments',
    templateUrl: './installments.component.html',
    styleUrls: ['./installments.component.scss']
})
export class InstallmentsComponent implements OnInit {
    @Output() onAction = new EventEmitter<any>();
    @Output() onChange = new EventEmitter();
    data!: any;
    field!: IField;
    policy!: Policy;
    enabled: boolean = false;
    psubscription: Subscription | undefined = undefined;
    emiCount: number = 0;
    emiOptions: string[] = [];
    downPayment: number = 0;
    instdef: any = {};
    amount: number = 0;
    schedule: number[] = [];
    schTotal: number = 0;
    totals: {amount: number, fee: number, paid: number} = {amount: 0, fee: 0, paid: 0};
    constructor() { }

    ngOnInit(): void {
        this.psubscription = this.policy?.changeSubject.subscribe((policy: any) => {
            console.log('prod-inst:', this.policy?.product?.installments)
            this.enabled = false;
            if (!this.policy?.product) return;
            this.instdef = this.policy.product.installments || {};
            console.log('insdef:', this.instdef);
            if (this.instdef.enable_installments == 1) this.enabled = true;
            if (!this.policy.policy?.payment_id) this.enabled = false;
            if (this.policy.policy?.policy_no && !this.policy.policy?.payment?.schedule) this.enabled = false;
            if (this.instdef.enable_installments == 1) {
                this.amount = this.policy.policy?.payment.total_amount || 0;
                this.emiOptions = [];
                for (let i=2; i<this.instdef.max_emi_count||0; i++) {
                    this.emiOptions.push(''+i);
                }
            }

            this.totals = {amount: 0, fee: 0, paid: 0};
            for (let sch of this.policy.policy?.payment?.schedule || []) {
                this.totals.amount += +(sch.amount || 0);
                this.totals.fee += +(sch.late_fee||0);
                this.totals.paid += +(sch.total_recvd||0);
            }

            console.log('schedule:', this.policy.policy?.payment?.schedule);
        });
    }

    schTrackByFn(index: number, item: any) {return index;}

    ngOnDestroy(): void {
        if (this.psubscription) this.psubscription.unsubscribe();
        this.psubscription = undefined;
    }

    periodChange() {
        do {this.schedule.push(0);} while (this.schedule.length < this.emiCount);
        this.schedule = this.schedule.slice(0, this.emiCount);
    }

    schChanged() {
        this.schTotal = 0;
        for (let i=0; i<this.schedule.length; i++) this.schTotal += +this.schedule[i] | 0;
    }

    async cancelInstallments() {
        if (!this.policy.policy?.payment_id) return;
        await insapi.__xdel('/api/v1/payment/installments?payment_id=' + encodeURIComponent(this.policy.policy?.payment_id));
        this.field.ignore_change = true;
        this.onAction.emit(this.field);
    }

    __validate(): boolean {
        if (!this.policy.policy?.payment_id) return false;
        if (this.emiCount < 2) return false;
        if (this.instdef.custom_schedule) {
            if (this.schTotal > 0 && this.schTotal != 100) {
                insapi.showMessage('Custom schedule should total to 100', 0);
                return false;
            }
            for (let i=0; i<this.schedule.length; i++) {
                if (this.schedule[i] == 0) {
                    insapi.showMessage('Custom schedule cannot have 0 percentage', 0);
                    return false;
                }
            }
        }
        return true;
    }

    async addInstallments() {
        if (!this.__validate()) return;
        let data: any = {
            payment_id: this.policy.policy?.payment_id,
            emi_count: this.emiCount,
            down_payment_amuont: this.downPayment
        };
        if (this.instdef.custom_schedule && this.schTotal == 100) {
            data.custom_schedule = this.schedule;
        }
        console.log('dp:', this.downPayment);
        await insapi.__xpost('/api/v1/payment/installments', data);
        this.field.ignore_change = true;
        this.onAction.emit(this.field);
    }
}
