import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-busy-spinner',
    template: `Processing ... <mat-spinner data-iid="__loading" style="margin: 0 10px; color: #800; display: inline-block;" diameter="25" strokeWidth="3"></mat-spinner>`,
    styles: []
})
export class BusySpinnerComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<BusySpinnerComponent>) { }

    ngOnInit(): void {
    }

}
